import React, { useEffect } from "react";
import KmdInvoice from "./kmdInvoice";
import "./kmdStep2.scss";
import "./kmdStep4.scss";
import {
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dropdown from "../../../components/Input/Dropdown";
import MyText from "../../../components/Input/MyText";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import { KmdKycInfoDto, KmdKycDocumentDto } from "../../../types/DTO/kmdDto";
import { Gender, JavaResponse } from "../../../types";
import FileInput from "../../../components/Input/FileInput";
import { setCkyc, setKyc, setKycType } from "../../../features/kmd/kmdSlice";
import alerts from "../../../util/alerts";
import advService from "../../../util/advService";
import {
    KMD_KYC_DOCUMENT_POST,
    KMD_KYC_INFO_POST,
} from "../../../util/newconfig";
import { AxiosResponse } from "axios";
import { changeDateFormat } from "../../../util/date";
import service from "../../../util/axiosConfig";

const GenderList = [
    { id: "M", name: "Male" },
    { id: "F", name: "Female" },
    { id: "T", name: "Others" },
];

const bannerImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Super_TopUp/superIllustration.png";

interface Props {
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    logId: string;
}

const KmdStep3: React.FC<Props> = ({ setCurrentStep, logId }) => {
    const { members, memberInfo, kyc, kycType } = useSelector(
        (state: RootState) => state.kmd
    );
    const reducerDispatch = useDispatch();

    const [gender, setGender] = React.useState<string>(GenderList[0].id);
    const [kycDocument, setKycDocument] = React.useState<File[] | null>(null);
    const [loader, setLoader] = React.useState<boolean>(false);

    const documentTypesInfo = [
        { id: "AADHAAR", name: "Aadhaar Card" },
        { id: "PASSPORT", name: "Passport" },
        { id: "PAN", name: "PAN Card" },
        { id: "DL", name: "Driving License" },
        { id: "VOTERID", name: "Voter ID" },
    ];

    const doncumentTypesDocument = [
        { id: "AADHAAR", name: "Aadhaar Card" },
        { id: "DL", name: "Driving License" },
    ];

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    useEffect(() => {
        setKycDocument(kycType === "document" && kyc.file ? [kyc.file] : null);
    }, [kyc]);

    /**
     * Clean the KYC Certificate Number based on the type and return the cleaned certificate number with error message if any
     * @param certificateNumber
     * @param certificateType
     * @returns  {
     *  certificateNumber: string;
     *  error: string | null;
     * }
     */
    const cleanKYCCertificateNumber = (
        certificateNumber: string,
        certificateType: "AADHAAR" | "PAN" | "PASSPORT" | "DL" | "VOTERID"
    ): {
        certificateNumber: string;
        error: string | null;
    } => {
        debugger;
        certificateNumber = certificateNumber.toString();
        // remove all spaces
        certificateNumber = certificateNumber.replace(/\s/g, "");
        if (certificateType === "PAN") {
            certificateNumber = certificateNumber.toUpperCase();
            // must be alphanumeric
            if (!/^[A-Z0-9]+$/.test(certificateNumber)) {
                return {
                    certificateNumber: "",
                    error: "Invalid PAN Number, only alphanumeric characters allowed",
                };
            }
            // must be 10 characters long
            if (certificateNumber.length !== 10) {
                return {
                    certificateNumber: "",
                    error: "Invalid PAN Number, must be 10 characters long",
                };
            }
        }

        if (certificateType === "AADHAAR") {
            // must be numeric
            if (!/^[0-9]+$/.test(certificateNumber)) {
                return {
                    certificateNumber: "",
                    error: "Invalid Aadhaar Number, only numeric characters allowed",
                };
            }
            // must be 12 characters long
            if (certificateNumber.length !== 12) {
                return {
                    certificateNumber: "",
                    error: "Invalid Aadhaar Number, must be 12 characters long",
                };
            }
        }

        if (certificateType === "PASSPORT") {
            // remove all spaces
            certificateNumber = certificateNumber.replace(/\s/g, "");
            // must be alphanumeric
            if (!/^[A-Z0-9]+$/.test(certificateNumber)) {
                return {
                    certificateNumber: "",
                    error: "Invalid Passport Number, only alphanumeric characters allowed",
                };
            }
        }

        return {
            certificateNumber,
            error: null,
        };
    };

    const handleSubmit = async (values: {
        certificateType: string;
        certificateNumber: string;
        dob: string;
        name?: string;
        phone?: string;
        email?: string;
    }) => {
        console.log(values);
        setLoader(true);
        if (kycType === "info") {
            const cleanedCertificateNumber = cleanKYCCertificateNumber(
                values.certificateNumber,
                values.certificateType as any
            );
            if (cleanedCertificateNumber.error) {
                setLoader(false);
                alerts(cleanedCertificateNumber.error, "error");
                return;
            }
            advService(
                { ...KMD_KYC_INFO_POST },
                {
                    kyc: {
                        certificateType: values.certificateType,
                        certificateNumber:
                            cleanedCertificateNumber.certificateNumber,
                        dob: values.dob,
                        name: values.name || "",
                        gender: gender || "",
                        logId: logId,
                    } as KmdKycInfoDto,
                },
                (res: AxiosResponse<JavaResponse<{ ckyc_number: string }>>) => {
                    setLoader(false);
                    if (res?.data?.success) {
                        // alerts("KYC Information Submitted", "success");
                        console.log(res.data.response.ckyc_number);
                        reducerDispatch(setCkyc(res.data.response.ckyc_number));
                        setCurrentStep(4);
                    }
                }
            );
            reducerDispatch(
                setKyc({
                    certificateType: values.certificateType,
                    certificateNumber: values.certificateNumber,
                    dob: values.dob,
                    name:
                        values.certificateType === "AADHAAR"
                            ? values.name || ""
                            : "",
                    gender: values.certificateType === "AADHAAR" ? gender : "",
                    logId: logId,
                })
            );
        } else if (kycType === "document") {
            if (kycDocument?.length === 0) {
                alerts("Please Upload atleast one file", "error");
                return;
            }
            if (kycDocument?.length == 2) {
                alerts("We were not able to do your kyc. Try agin", "error");
                return;
            }

            const formData = new FormData();
            formData.append("phone", values.phone || "");
            formData.append("email", values.email || "");
            formData.append("logId", logId);
            formData.append("certificateType", values.certificateType);
            // kycDocument?.forEach((file) => {
            //     formData.append("file", file);
            // });
            formData.append("file", kycDocument![0]);
            let FileUploadLoader = alerts("Uploading KYC Document", "loading");
            let res: AxiosResponse<JavaResponse<{ ckyc_number: string }>> =
                await service.postUpload(KMD_KYC_DOCUMENT_POST.url, formData);
            setLoader(false);
            if (res.data.success) {
                alerts(
                    "KYC Document Submitted",
                    "loadingSuccess",
                    3000,
                    FileUploadLoader
                );
                reducerDispatch(
                    setKyc({
                        certificateType: values.certificateType,
                        certificateNumber: values.certificateNumber,
                        dob: values.dob,
                        name:
                            values.certificateType === "AADHAAR"
                                ? values.name || ""
                                : "",
                        gender:
                            values.certificateType === "AADHAAR" ? gender : "",
                        logId: logId,
                        file: kycDocument![0],
                    })
                );
                reducerDispatch(setCkyc(res.data.response.ckyc_number));
                setCurrentStep(4);
            } else {
                alerts(
                    "KYC Document Submission Failed",
                    "loadingError",
                    3000,
                    FileUploadLoader
                );
            }
        }
    };

    return (
        <div className="kmdStep2 kmdStep3">
            <div className="kmdStep2_header mb40">
                <IconButton
                    className="p0I"
                    onClick={() => {
                        setCurrentStep(2);
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <h1 className="m0I ml20I fontSize_26">KYC</h1>
            </div>
            <div className="kmdStep2_banner">
                <img src={bannerImage} alt="" className="pt25" />
            </div>
            <div className="kmdStep2_dataContainer">
                <Formik
                    initialValues={{
                        dob: kyc?.dob
                            ? changeDateFormat(
                                  "dd-mm-yyyy",
                                  "javascriptDateObject",
                                  kyc?.dob
                              )
                            : "",
                        ...(kycType === "info"
                            ? {
                                  certificateNumber:
                                      kyc?.certificateNumber || "",
                                  ...(kyc?.certificateType === "AADHAAR" && {
                                      name: kyc?.name || "",
                                  }),
                              }
                            : {
                                  phone: memberInfo.phone,
                                  email: "",
                              }),
                    }}
                    validationSchema={Yup.object({
                        ...(kycType === "info"
                            ? {
                                  dob: Yup.string().required("Required"),
                                  ...(kyc?.certificateType === "AADHAAR"
                                      ? {
                                            name: Yup.string().required(
                                                "Required"
                                            ),
                                            certificateNumber: Yup.string()
                                                .required("Required")
                                                .test(
                                                    "len",
                                                    "Invalid Aadhaar Number",
                                                    (val) => val?.length === 12
                                                ),
                                        }
                                      : kyc?.certificateType === "PAN"
                                      ? {
                                            certificateNumber: Yup.string()
                                                .required("Required")
                                                .test(
                                                    "len",
                                                    "Invalid PAN Number",
                                                    (val) => {
                                                        if (!val) {
                                                            return false;
                                                        }
                                                        // convert to uppercase
                                                        val =
                                                            val?.toUpperCase();
                                                        // clean spaces
                                                        val = val?.replace(
                                                            /\s/g,
                                                            ""
                                                        );
                                                        // must be alphanumeric
                                                        if (
                                                            !/^[A-Z0-9]+$/.test(
                                                                val
                                                            )
                                                        ) {
                                                            return false;
                                                        }
                                                        // must be 10 characters long
                                                        if (val.length !== 10) {
                                                            return false;
                                                        }
                                                        return true;
                                                    }
                                                ),
                                        }
                                      : {
                                            certificateNumber:
                                                Yup.string().required(
                                                    "Required"
                                                ),
                                        }),
                              }
                            : {
                                  phone: Yup.string()
                                      .required("Required")
                                      .matches(
                                          /^[0-9]{10}$/,
                                          "Invalid Phone Number"
                                      ),
                                  email: Yup.string()
                                      // .required("Required")
                                      .email("Please enter a valid email"),
                              }),
                    })}
                    onSubmit={(val) => {
                        handleSubmit({
                            certificateType: kyc?.certificateType || "",
                            // @ts-ignore
                            certificateNumber: val.certificateNumber || "",
                            dob: val.dob
                                ? (changeDateFormat(
                                      "javascriptDateObject",
                                      "dd-mm-yyyy",
                                      val.dob
                                  ) as string)
                                : "",
                            // @ts-ignore
                            name: val.name || "",
                            // @ts-ignore
                            phone: val.phone || "",
                            // @ts-ignore
                            email: val.email || "",
                        });
                    }}
                    enableReinitialize
                >
                    <>
                        <div className="kmdStep2_left p40">
                            <Form>
                                <FormControl
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                        Choose Your KYC Verification Method
                                    </FormLabel>

                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={kycType}
                                        name="radio-buttons-group"
                                        row
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            if (kyc) {
                                                reducerDispatch(
                                                    setKyc({
                                                        ...kyc,
                                                        certificateType:
                                                            "AADHAAR",
                                                    })
                                                );
                                            } else {
                                                reducerDispatch(
                                                    setKyc({
                                                        certificateType:
                                                            "AADHAAR",
                                                        certificateNumber: "",
                                                        dob: "",
                                                        name: "",
                                                        gender: "",
                                                        logId: logId,
                                                    })
                                                );
                                            }
                                            reducerDispatch(
                                                setKycType(
                                                    e.target.value as
                                                        | "document"
                                                        | "info"
                                                )
                                            );
                                        }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <FormControlLabel
                                            value="info"
                                            control={<Radio />}
                                            style={{
                                                width: "45%",
                                            }}
                                            label={"Provide Document Details"}
                                        />

                                        <FormControlLabel
                                            value="document"
                                            control={<Radio />}
                                            style={{
                                                width: "45%",
                                            }}
                                            label={"Upload Identity Document"}
                                        />
                                    </RadioGroup>
                                    <div className="kmdStep2_form ">
                                        <div className="custom-form-group">
                                            <Dropdown
                                                title="Document Type"
                                                name="documentType"
                                                className="dropDownSuper colorUpdate"
                                                defaultVal={
                                                    kyc?.certificateType
                                                }
                                                items={
                                                    kycType === "info"
                                                        ? documentTypesInfo
                                                        : doncumentTypesDocument
                                                }
                                                change={(val: any) => {
                                                    if (kyc) {
                                                        reducerDispatch(
                                                            setKyc({
                                                                ...kyc,
                                                                certificateType:
                                                                    val,
                                                            })
                                                        );
                                                    } else {
                                                        reducerDispatch(
                                                            setKyc({
                                                                certificateType:
                                                                    val,
                                                                certificateNumber:
                                                                    "",
                                                                dob: "",
                                                                name: "",
                                                                gender: "",
                                                                logId: logId,
                                                            })
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>

                                        <MyText
                                            label="Date of Birth"
                                            name="dob"
                                            type="date"
                                            placeholder=""
                                            className=""
                                            onChange={() => {}}
                                        />

                                        {kycType === "info" ? (
                                            <>
                                                <MyText
                                                    label={
                                                        kyc.certificateType ===
                                                        "AADHAAR"
                                                            ? "Aadhar card number (without spaces)"
                                                            : kyc.certificateType ===
                                                              "PASSPORT"
                                                            ? "File Number"
                                                            : "Document Number"
                                                    }
                                                    name="certificateNumber"
                                                    type={
                                                        kyc.certificateType ===
                                                        "AADHAAR"
                                                            ? "number"
                                                            : "text"
                                                    }
                                                    placeholder="Document Number"
                                                    className=""
                                                    onChange={() => {}}
                                                />
                                                {kyc?.certificateType ===
                                                    "AADHAAR" && (
                                                    <>
                                                        <MyText
                                                            label="Name as per Aadhaar Card"
                                                            name="name"
                                                            type="text"
                                                            placeholder="Name as per Aadhaar Card"
                                                            className=""
                                                            onChange={() => {}}
                                                        />

                                                        <div className="custom-form-group">
                                                            <Dropdown
                                                                title="Gender"
                                                                name="gender"
                                                                className="dropDownSuper colorUpdate"
                                                                defaultVal={
                                                                    gender
                                                                }
                                                                items={
                                                                    GenderList
                                                                }
                                                                change={(
                                                                    val: any
                                                                ) => {
                                                                    setGender(
                                                                        val
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <MyText
                                                    label="Phone"
                                                    name="phone"
                                                    type="number"
                                                    placeholder="Phone"
                                                    className=""
                                                    onChange={() => {}}
                                                />

                                                <MyText
                                                    label="Email"
                                                    name="email"
                                                    type="text"
                                                    placeholder="email"
                                                    className=""
                                                    onChange={() => {}}
                                                />
                                                <div className="kmdKycFileInput mt15">
                                                    <FileInput
                                                        files={
                                                            kycDocument || []
                                                        }
                                                        setFiles={(
                                                            files: File[]
                                                        ) => {
                                                            setKycDocument(
                                                                files
                                                            );
                                                        }}
                                                        multiple={false}
                                                        types={"image,pdf"}
                                                        wrongInputFunction={() => {}}
                                                        label="Please upload both front and back side of the document"
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <button
                                            className="buttonPrimary mobileOnly mt15 W100"
                                            type="submit"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </FormControl>
                            </Form>
                        </div>
                        <div className="kmdStep2_right">
                            <FormContainer loader={loader} />
                        </div>
                    </>
                </Formik>
            </div>
        </div>
    );
};

export default KmdStep3;

const FormContainer = ({ loader }: { loader: boolean }) => {
    let { submitForm } = useFormikContext();
    return (
        <KmdInvoice
            submitForm={() => {
                console.log("test");
                submitForm();
            }}
            loader={loader}
        />
    );
};
