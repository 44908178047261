import React from "react";
import "./Steps.scss";
import { Box, Typography } from "@mui/material";

function Steps({ data, className = "" }) {
    return (
        <div className={`steps ${className}`}>
            {data?.map((step, i) => (
                // <div className="steps_step mb10" key={`steps_${step}_${i}`}>
                //     <b>{i + 1}</b>: <div className="steps_step_text ml10">
                //     {step}
                //     </div>
                // </div>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                    }}
                    key={i}
                >
                    <Box sx={{ display: "flex", gap: "6px" }}>
                        <Typography variant="body3">{i + 1}</Typography>
                        <Typography variant="body3">{step}</Typography>
                    </Box>
                </Box>
            ))}
        </div>
    );
}

export default Steps;
