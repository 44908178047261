import { AxiosResponse } from "axios";
import advService from "../../util/advService";
import { ALL_CLAIMS_GET, CLAIM_CUSTOMERCARE_GET } from "../../util/newconfig";
import { JavaResponse } from "../../types";
import { CLAIM_OPERATIONTEAM } from "../../util/config";
import { CustomerCareDto } from "../../types/DTO/claimsDTO";

type ClaimContact = {
    email: string;
    name: string;
    phone: string;
    step2: string;
    step3: string;
    step6: string;
    claimFileButton: boolean;
    claimDocumentUpload: boolean;
    domain: string;
    superTopup: boolean;
    claimFilledForm: boolean;
    escalationMatrixDTOS: any[];
};

export const claimsService = {
    employer: {
        getClaimContact: async (uuid?: string): Promise<CustomerCareDto> => {
            return new Promise((resolve, reject) => {
                const finalUuid = uuid || sessionStorage.getItem("uuid");

                advService(
                    {
                        ...CLAIM_CUSTOMERCARE_GET,
                        url: CLAIM_CUSTOMERCARE_GET.url + finalUuid,
                    },
                    {},
                    (
                        response: AxiosResponse<JavaResponse<CustomerCareDto>>
                    ) => {
                        if (response.data.success) {
                            resolve(response.data.response);
                            return;
                        }
                        reject(response.data.message);
                    }
                );
            });
        },
        getOperationContact: async (uuid: string) => {
            return new Promise((resolve, reject) => {
                advService(
                    {
                        url:
                            CLAIM_OPERATIONTEAM +
                            sessionStorage.getItem("uuid"),
                    },
                    {},
                    (response: AxiosResponse<JavaResponse<ClaimContact>>) => {
                        if (response.data.success) {
                            resolve(response.data.response);
                            return;
                        }
                        reject(response.data.message);
                    }
                );
            });
        },
    },
    claim: {
        getAllClaims: async () => {
            return new Promise((resolve, reject) => {
                advService(
                    ALL_CLAIMS_GET,
                    {},
                    (response: AxiosResponse<JavaResponse<any[]>>) => {
                        if (response.data.success) {
                            resolve(response.data.response);
                            return;
                        }
                        reject(response.data.message);
                    }
                );
            });
        },
    },
};
