import React, { useEffect } from "react";
import "./KmdInfo.scss";
import KmdStep1 from "./steps/kmdStep1";
import { useDispatch, useSelector } from "react-redux";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { RootState } from "../../store";
// import { Box, Divider, Modal, Typography, styled } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import GenericModalContainer from "../../components/Modals/GenericModalContainer";

const HERO =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/graphic/hero.svg";
const BASESUM =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/graphic/Base_Sum.svg";

interface KmdInfoProps {
    // Add any props you need here
    submit: () => void;
}

const coverageInfo = [
    {
        description:
            "Sum Insured options of Rs. 3 Lacs, 5 Lacs, 7.5 Lacs and 10 Lacs",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/sumInsured1.svg",
    },
    {
        description:
            "No Medical Checkup till age of 65 Years. Cover available for persons above 65 Years – 80 Years subject to Satisfactory Pre-Insurance Medical Tests",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/wait1.svg",
    },
    {
        description:
            "Room Rent 1.5% of SI limit and ICU Expenses on Actual Basis",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/roomRentLimit1.svg",
    },
    {
        description: "Pre Existing Diseases covered after 12 months",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/prePostHospitalisation12.svg",
    },
    {
        description:
            "Road Ambulance charges upto Rs. 2,500/- and Air Ambulance charges upto Rs. 50,000/-",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/ambulance1.svg",
    },
    {
        description: "No Copay",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/copay1.svg",
    },
];

const gpaCoverageInfo = [
    {
        description: "Sum Insured options of Rs. 10 Lacs, 15 Lacs and 25 Lacs",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/sumInsured1.svg",
    },
    {
        description: "For Students, Flat sum insured of Rs. 10 Lacs",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Student+insurance.svg",
    },
    {
        description:
            "Coverage against Death and Disablement due to any Accidental, External and Violent Means",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Death+cover.svg",
    },
    {
        description: "Coverage for loss of 1 Limb or 1 Eye - 50% of SI",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Coverage.svg",
    },
    {
        description:
            "Coverage for Accidental Death, Loss of 2 Limbs/Eyes, Loss of 1 Limb and 1 Eye - 100% of SI",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Coverage.svg",
    },
    {
        description: "Worldwide coverage",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Worldwide+coverage.svg",
    },
    {
        description:
            "Additional coverages for Accidental Burns and Ambulance charges",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Coverage.svg",
    },
    {
        description: "Terrorism Cover",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Repatirate.svg",
    },
];

const gpiCoverageInfo = [
    {
        description:
            "Legal Liability due to breach of professional duty due to negligent act, error or omission",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Coverage.svg",
    },
    {
        description: "Legal Expenses",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Coverage.svg",
        popup: "Legal costs and expenses reasonably incurred in the investigation, defence and settlement of any Claim.",
    },
    {
        description: "Advancement of Defense Cost ",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Worldwide+coverage.svg",
        popup: "The Company shall, prior to the final disposition of any Claim, advance Defence Costs or Legal Representation Expenses as provided under this policy within thirty (30) days of receipt of an invoice for same from defence counsel.",
    },
    {
        description: "Employee Dishonesty Covered",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Repatirate.svg",
        popup: "Company will indemnify the Insured against civil liability which the Insured incurs as a result of any Claim arising out of any dishonest, fraudulent, criminal or malicious act or omission of any employee of the Insured but only when providing Professional Services.",
    },

    {
        description: "Defamation Cover",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Coverage.svg",
        popup: "Company will indemnify the Insured against civil liability the Insured incurs in respect of a Claim arising from the performance of Professional Services alleging any libel, slander, defamation or injurious falsehood by the Insured. ",
    },
    {
        description: "Servicing Support by KMD Team on 24x7x365 Basis",
        imageUrl:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/GPA+feature+icons/Coverage.svg",
    },
];

const faq = [
    "Cover available for Architect, Spouse and 2 Children",
    "Cover available up to the age of 65 years",
    "Children can be covered up to the age of 25 years",
    "Income Tax Benefit available under Section 80D",
    <>
        Policy Wordings
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/Health/Policy+Wordings+(GMC).pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pl5 pr5"
        >
            <CloudDownloadIcon />
        </a>{" "}
        & Benefits
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/Health/Benefits+(GMC).pdf"
            target="_blank"
            className="pl5"
            rel="noopener noreferrer"
        >
            <CloudDownloadIcon />
        </a>
    </>,
];

const gpaFaq = [
    "Plans available for Architect only",
    "Cover available upto 70yrs",
    <>
        Policy Wordings
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/GPA/Policy+wordings+(GPA).pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pl5 pr5"
        >
            <CloudDownloadIcon />
        </a>{" "}
        & Benefits
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/GPA/Benefits+(GPA).pdf"
            target="_blank"
            className="pl5"
            rel="noopener noreferrer"
        >
            <CloudDownloadIcon />
        </a>
    </>,
];

const gpiFaq = [
    "Provides financial protection from lawsuits filed against the architects",
    "Fulfills Contractual Obligation, wherever required",
    "PI insurance for architects increases credibility as the client does not have to worry about suffering any unforeseen loss and gets coverage for it",
    <>
        Policy Wordings
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/PI/Policy+wordings+(PI)+.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pl5 pr5"
        >
            <CloudDownloadIcon />
        </a>{" "}
        & Benefits
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/PI/Benefits+(PI).pdf"
            target="_blank"
            className="pl5"
            rel="noopener noreferrer"
        >
            <CloudDownloadIcon />
        </a>
    </>,
];

const KmdInfo: React.FC<KmdInfoProps> = ({ submit }: KmdInfoProps) => {
    const { policyType } = useSelector((state: RootState) => state.kmd);
    const [open, setOpen] = React.useState(false);
    const [modalText, setModalText] = React.useState<{
        header: string;
        content: string;
    }>({
        header: "",
        content: "",
    });
    const handleClose = () => setOpen(false);

    // Add your component logic here
    useEffect(() => {
        if (window.location.search.includes("purchase=true")) {
            const kmdFormElement = document.getElementById("kmdForm");
            if (kmdFormElement) {
                kmdFormElement.scrollIntoView();
            }
        }
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    const heroText = (policyType: "GMC" | "GPA" | "GPI" | null) => {
        if (policyType === "GMC") {
            return (
                <div className="kmdInfo_heroText">
                    <h1 className="fontSize_40 fontWeight_700 mb20">
                        Purchase Health Policy upto{" "}
                        <span className="fontColor_primary">
                            <span className="rupee">₹</span>10 Lacs
                        </span>{" "}
                        starting from
                        <span className="fontColor_primary">
                            <span className="rupee"> ₹</span>
                            4685/- year *
                        </span>
                    </h1>
                    <p className="fontSize_16 fontColor_dark">
                        Specialised Insurance Policies at attractive prices and
                        wider coverage designed specifically for Architects
                        registered with COA & their Family Members
                    </p>
                </div>
            );
        } else if (policyType === "GPA") {
            return (
                <div className="kmdInfo_heroText">
                    <h1 className="fontSize_40 fontWeight_700 mb20">
                        Purchase Personal Accident Cover upto{" "}
                        <span className="fontColor_primary">
                            <span className="rupee">₹</span>25 Lacs
                        </span>
                    </h1>
                    <p className="fontSize_16 fontColor_dark">
                        Specialised PA Policy at attractive prices and wider
                        coverage designed specifically for Architects registered
                        with COA and Architecture Students
                    </p>
                </div>
            );
        } else if (policyType === "GPI") {
            return (
                <div className="kmdInfo_heroText">
                    <h1 className="fontSize_40 fontWeight_700 mb20">
                        Purchase Professional Indemnity Coverage upto{" "}
                        <span className="fontColor_primary">
                            <span className="rupee">₹</span>25 Crores
                        </span>
                    </h1>
                    <p className="fontSize_16 fontColor_dark">
                        Specialised Insurance Policies at attractive prices and
                        wider coverage designed specifically for Architects
                        registered with COA
                    </p>
                </div>
            );
        }
        return <div>No policy type selected</div>;
    };

    return (
        <div className="kmdInfo">
            <div className="kmdInfo_heroSection">
                <div className="kmdInfo_heroText">{heroText(policyType)}</div>
                <div className="kmdInfo_heroImage">
                    <img src={HERO} alt="Hero Image" />
                </div>
            </div>
            <div className="kmdInfo_coverageInfo ">
                <h1 className="fontSize_40 fontWeight_700 mb20 mt50">
                    What's <span className="fontColor_primary">Covered ?</span>
                </h1>
                <div className="kmdInfo_cardsContainer">
                    {(policyType === "GMC"
                        ? coverageInfo
                        : policyType === "GPA"
                        ? gpaCoverageInfo
                        : gpiCoverageInfo
                    ).map(
                        (
                            info: {
                                description: string;
                                imageUrl: string;
                                popup?: string;
                            },
                            index
                        ) => (
                            <div
                                className={`kmdInfo_card ${
                                    info.popup && "pointer"
                                }`}
                                key={index}
                                onClick={() => {
                                    if (info.popup) {
                                        setModalText({
                                            header: info.description,
                                            content: info.popup,
                                        });
                                        setOpen(true);
                                    }
                                }}
                            >
                                <div className="kmdInfo_cardImage mr20">
                                    <img src={info.imageUrl} alt="Card Image" />
                                </div>
                                <div className="kmdInfo_cardText">
                                    <p className="fontSize_16 fontWeight_500 fontColor_dark m0I">
                                        {info.description}
                                    </p>
                                </div>
                                <div className="kmdInfo_cardInfo">
                                    {info.popup && <InfoIcon />}
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>

            <div className="kmdInfo_form " id="kmdForm">
                <h1 className="fontSize_40 fontWeight_700 mb20 mt50">
                    {policyType === "GMC"
                        ? "Buy your Health Cover Today"
                        : policyType === "GPA"
                        ? "Buy your Personal Accident Cover Today"
                        : policyType === "GPI"
                        ? "Buy your Professional Indemnity Policy today"
                        : "Buy your cover today"}
                </h1>
                <div className="kmdInfo_formContainer">
                    <KmdStep1 submit={submit} />
                </div>
            </div>
            <div className="kmdInfo_faqSection">
                <h1 className="fontSize_40 fontWeight_700 mb20 mt50">
                    FAQ & <span className="fontColor_primary">Benefits</span>
                </h1>
                <div className="kmdInfo_faqContainer">
                    {(policyType === "GMC"
                        ? faq
                        : policyType === "GPA"
                        ? gpaFaq
                        : gpiFaq
                    ).map((faq, index) => (
                        <div className="kmdInfo_faq" key={index}>
                            <p className="fontSize_16 fontWeight_600 mb0I pr10 pl10">
                                {faq}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <hr />
            <p>
                {policyType === "GMC" && (
                    <i>
                        *Premium (Excluding GST @18%) for a 25-year old person
                        opting for an annual coverage for Rs. 10 lacs.
                    </i>
                )}
            </p>

            {open && (
                <GenericModalContainer
                    close={handleClose}
                    // headingText={member ? "Edit Dependents" : "Add Dependents"}
                    headingText={modalText.header}
                    styles={{ outline: "none", border: "none" }}
                >
                    {modalText.content}
                </GenericModalContainer>
            )}
        </div>
    );
};

export default KmdInfo;
