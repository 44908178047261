import axios, { AxiosResponse } from "axios";
import advService from "../advService";
import { WHITELABEL_GET } from "../newconfig";
import { JavaResponse } from "../../types";

// now create a function which will return the config based on the subdomain
const imtResponse = {
    idealSessionTimeout: 0,
    titleBar: {
        text: "Insure My Team",
        favicon:
            "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/host_logos/Favicon_IMT.png",
    },
    siteLogo:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/host_logos/IMT+Care.png",
    thirdPartyServices: {
        google: true,
        microsoft: true,
        sentry: true,
        yellowMessenger: true,
        hotjar: true,
    },
};

export const getSubDomainInfo = async () => {
    let subdomain = window.location.hostname.replace(/^\.*|\.*$/g, "");
    let config = { ...WHITELABEL_GET };
    config.url = config.url.replace("{baseUrl}", subdomain);

    try {
        const response = await new Promise<AxiosResponse<JavaResponse<any>>>(
            (resolve, reject) => {
                advService(config, {}, (res: AxiosResponse<any>) => {
                    if (res?.data?.success) {
                        resolve(res);
                    } else {
                        reject(new Error("Failed to get subdomain info"));
                    }
                });
            }
        );
        if (response.data.success) {
            return response.data.response.d_white_label;
        }
        return imtResponse;
    } catch (error) {
        console.error(error);
        return imtResponse;
    }
};
