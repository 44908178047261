import { CustomFile } from "../../types/claims";
import ImageIcon from "../../assets/images/icons/note-add.svg";
const docIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/doc.svg";
const ExcelIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/excel.svg";
const pdfIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/pdf.svg";

function isCustomFile(obj: any): obj is CustomFile {
    return "id" in obj; // Assuming 'id' is a property unique to CustomFile
}

function isCustomFilePDF(obj: any): obj is CustomFile {
    // do not check for valid values but just check if they have id, url and status
    return (
        obj &&
        typeof obj === "object" &&
        "id" in obj &&
        "url" in obj &&
        "status" in obj &&
        obj.type === "pdf"
    );
}

const generateIcon = (file?: File | Blob | CustomFile, types?: string) => {
    const typesArray = (types || "image,pdf")
        .split(",")
        .map((item: string) => item.trim());
    if (!file) {
        if (typesArray[0] === "image") {
            return ImageIcon;
        } else if (typesArray[0] === "pdf") {
            return pdfIcon;
        } else if (typesArray[0] === "doc") {
            return docIcon;
        } else {
            return ExcelIcon;
        }
    } else {
        // debugger;
        if (isCustomFilePDF(file) && file.status === "uploading") {
            return docIcon;
        } else {
            //@ts-ignore
            let fileExtension = file?.name.split(".").pop();
            if (file?.type === "pdf" || fileExtension === "pdf") {
                return pdfIcon;
            } else if (
                file?.type.startsWith("image/") ||
                (fileExtension &&
                    [
                        "jpg",
                        "jpeg",
                        "png",
                        "gif",
                        "bmp",
                        "tiff",
                        "tif",
                        "svg",
                    ].includes(fileExtension))
            ) {
                return isCustomFilePDF(file)
                    ? file.url
                    : URL.createObjectURL(file);
            } else return docIcon;
        }
    }

    // if (typesArray.length > 1) {
    //     return docIcon;
    // } else {
    //     if (typesArray[0] === "image") {
    //         return ImageIcon;
    //     } else if (typesArray[0] === "pdf") {
    //         return pdfIcon;
    //     } else if (typesArray[0] === "doc") {
    //         return docIcon;
    //     } else {
    //         return ExcelIcon;
    //     }
    // }
};

export default generateIcon;
