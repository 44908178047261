import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import React from "react";
import { useStateValue } from "../../util/stateProvider";

const Alerts = (props) => {
    return <Alert elevation={6} variant="filled" {...props} />;
};

export const alertStyling = (severity) => {
    let style = {
        width: "100%",
        fontSize: "1rem",
    };
    severity === "success"
        ? (style = {
              ...style,
              backgroundColor: "#29b152",
              color: "#FAFAFA",
          })
        : (style = {
              ...style,
              backgroundColor: "#dd2a44",
              color: "#FAFAFA",
          });
    return style;
};

export const showAlert = (dispatch, isOpen, type, message, duration = 4000) => {
    dispatch({
        type: "setAlert",
        payload: { isOpen, type, message, duration },
    });
};

const CustomAlert = () => {
    const [{ alertStatus }, dispatch] = useStateValue();
    const { isOpen, type, message, duration } = alertStatus;

    const handleAlertClose = () => {
        dispatch({
            type: "setAlert",
            payload: { isOpen: false, type, message, duration },
        });
    };

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={duration}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <div>
                <Alerts
                    style={alertStyling(type)}
                    severity={type}
                    onClose={handleAlertClose}
                >
                    {message}
                </Alerts>
            </div>
        </Snackbar>
    );
};

export default CustomAlert;
