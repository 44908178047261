import axios from "axios";
import alert from "./alerts";
import downloadBlobIphone, {
    downloadBlobIphone2,
} from "./function/downloadBlobIphone";
import generateContentType from "./function/generateContentType";
import { ADMIN_ACTIONS_GET } from "./config";

const checkSession = () => {
    if (
        JSON.parse(sessionStorage.getItem("subDomainInfo"))?.idealSessionTimeout
    ) {
        let loginTime = JSON.parse(localStorage.getItem("loginTime"));
        loginTime = new Date(loginTime);
        let currentTime = new Date();
        let timeDiff = Math.abs(currentTime - loginTime);
        let seconds = Math.floor(timeDiff / 1000);
        if (
            seconds >
            JSON.parse(sessionStorage.getItem("subDomainInfo"))
                .idealSessionTimeout
        ) {
            localStorage.removeItem("Authorization");
            sessionStorage.removeItem("uuid");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("leads");
            sessionStorage.removeItem("lead");
            localStorage.removeItem("loginTime");
            localStorage.removeItem("subDomainInfo");
            // window.location.href = window.location.origin + "/login";
        } else {
            localStorage.setItem("loginTime", JSON.stringify(new Date()));
        }
    }
};

const removeTrailingSlash = (url) => {
    if (url[url.length - 1] === "/") {
        return url.slice(0, -1);
    }
    return url;
};

const getAuthToken = () => {
    if (sessionStorage.getItem("Authorization")) {
        return sessionStorage.getItem("Authorization");
    }
    return localStorage.getItem("Authorization");
};

class Service {
    constructor() {
        let service = axios.create({
            headers: { "Content-Type": "application/json" },
        });
        this.handleError = this.handleError.bind(this); // Binding the context
        this.handleSuccess = this.handleSuccess.bind(this); // Binding the context
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
        this.storeData = {};
        this.navigateObj = {};
    }

    setStoreDataForService(storeDataParam = {}) {
        this.storeData = storeDataParam;
    }

    setnavigateObj(navigateObj) {
        this.navigateObj = navigateObj;
    }

    handleSuccess(response) {
        return response;
    }

    handleError(error) {
        console.log("ERROR");
        if (error.response?.status === 400) {
            console.log(error);
            return error;
        }
        if (error.response?.status === 401) {
            // if unauthorized, redirect to signUp
            console.log("resetting creds config");
            localStorage.removeItem("Authorization");
            sessionStorage.removeItem("uuid");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("leads");
            sessionStorage.removeItem("lead");
            localStorage.removeItem("navBar");
            sessionStorage.removeItem("navBar");
            sessionStorage.removeItem("topBar");
            localStorage.removeItem("topBar");
            sessionStorage.removeItem("Authorization");
            window.location.href = window.location.origin + "/login";
        }
    }

    get(path, headers = {}) {
        checkSession();
        path = removeTrailingSlash(path);
        return this.service.get(path, {
            headers: {
                Authorization: getAuthToken(),
                ...headers,
            },
        });
    }

    patch(path, payload) {
        return this.service.request({
            method: "PATCH",
            url: path,
            responseType: "json",
            data: payload,
            headers: { Authorization: getAuthToken() },
        });
    }
    post(path, payload, headers = {}) {
        path = removeTrailingSlash(path);
        return this.service.request({
            method: "POST",
            url: path,
            responseType: "json",
            data: payload,
            headers: {
                Authorization: getAuthToken(),
                ...headers,
            },
            timeout: 100000,
        });
    }

    postBlob(path, payload) {
        return this.service.request({
            method: "POST",
            url: path,
            responseType: "blob",
            data: payload,
            headers: { Authorization: getAuthToken() },
        });
    }

    // postDownload(path, payload) {
    //   return this.service.request({
    //     method: "POST",
    //     url: path,
    //     responseType: "blob",
    //     data: payload,
    //     headers: { Authorization: localStorage.getItem("Authorization") },
    //   });
    // }

    postDownload(path, fileName, payload, javaresponse = false, setLoader) {
        let loading;
        setLoader && setLoader(true);
        alert("Downloading...", "loading");
        axios({
            url: path,
            method: "POST",
            data: payload,
            headers: { Authorization: getAuthToken() },
            ...(!javaresponse && { responseType: "blob" }),
        })
            .then((response) => {
                let buffer = [];
                if (javaresponse) {
                    buffer = new Uint8Array(response.data.response.data).buffer;
                } else {
                    buffer = response.data;
                }
                // const url = window.URL.createObjectURL(
                //     new Blob([buffer], {
                //         type: "application/pdf",
                //     })
                // );
                // const link = document.createElement("a");
                // link.href = url;
                // link.setAttribute("download", fileName); //or any other extension
                // // document.body.appendChild(link);
                // if (/(iP)/g.test(navigator.userAgent)) {
                //     window.open(url);
                // } else {
                //     link.click();
                // }
                downloadBlobIphone(new Blob([buffer]), fileName);
                alert("", "dismiss", 3000);
                setLoader && setLoader(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                alert("Network error", "loadingError", 3000);
                setLoader && setLoader(false);
            });
    }

    postMfine(path, payload) {
        return this.service.request({
            method: "POST",
            url: path,
            responseType: "json",
            data: payload,
            headers: {
                client_id: "shiftrisk",
                secret_key:
                    "CGNyKZoPa1P0sYbDAx8A7dD4To1kzihjV0WYzCS5WxvDkv3ll2EagXYCdn3pUB0u",
                "Content-Type": "application/json",
            },
        });
    }

    getDownload(path, fileName, callbackFunc, setExtension = false) {
        const loading = alert("downloading", "loading", 3000);
        axios({
            url: path,
            method: "GET",
            headers: { Authorization: getAuthToken() },
            responseType: "blob", // Important
        })
            .then((response) => {
                // console.log(response.headers["content-disposition"].split("filename=")[1], "check here");
                console.log(response.data);
                if (response.data.type === "application/json") {
                    //callbackFunc(false, "Unable to download");
                    alert("Unable to download", "loadingError", 3000, loading);
                } else {
                    // const url = window.URL.createObjectURL(
                    //     new Blob([response.data])
                    // );
                    // const link = document.createElement("a");
                    // link.href = url;
                    // if (setExtension) {
                    //     link.setAttribute(
                    //         "download",
                    //         response.headers["content-disposition"]
                    //             .split("filename=")[1]
                    //             ?.split('"')?.[1]
                    //     );
                    //     //or any other extension
                    // } else {
                    //     link.setAttribute("download", fileName); //or any other extension
                    // }
                    // // document.body.appendChild(link);
                    // link.click();

                    // link.download = `${insurer}.xlsx`;
                    // link.click();
                    //callbackFunc(true, "File Download started");

                    let fileNameFromServer =
                        response?.headers["content-disposition"]?.match(
                            /filename="([^"]+)"/
                        )?.[1];

                    // Use fileNameFromServer if available, otherwise use fileName
                    let finalFileName = fileNameFromServer || fileName;

                    downloadBlobIphone(
                        new Blob([response.data]),
                        finalFileName
                    );
                    alert(
                        "File Download started",
                        "loadingSuccess",
                        3000,
                        loading
                    );
                }
            })
            .catch((e) => {
                console.log(e, "errors");
                //callbackFunc(false, "network error..");
                alert("Network error", "loadingError", 3000, loading);
            });
    }

    getPostDownload(path, body, fileName, callbackFunc, setExtension = false) {
        const loading = alert("downloading", "loading", 3000);
        axios({
            url: path,
            method: "POST",
            data: body,
            headers: { Authorization: getAuthToken() },
            responseType: "blob", // Important
        })
            .then((response) => {
                // console.log(response.headers["content-disposition"].split("filename=")[1], "check here");
                console.log(response.data);
                if (response.data.type === "application/json") {
                    //callbackFunc(false, "Unable to download");
                    alert("Unable to download", "loadingError", 3000, loading);
                } else {
                    // if (path.includes("member/card/download")) {
                    //     downloadBlobIphone2(
                    //         new Blob([response.data]),
                    //         fileName
                    //     );
                    // } else {
                    downloadBlobIphone(
                        new Blob([response.data], {
                            type:
                                fileName.split(".")[1] === "pdf"
                                    ? "application/pdf"
                                    : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        }),
                        fileName
                    );
                    // }
                    // const url = window.URL.createObjectURL(
                    //     new Blob([response.data])
                    // );
                    // const link = document.createElement("a");
                    // link.href = url;
                    // if (setExtension) {
                    //     link.setAttribute(
                    //         "download",
                    //         response.headers["content-disposition"].split(
                    //             "filename="
                    //         )[1]
                    //     ); //or any other extension
                    // } else {
                    //     link.setAttribute("download", fileName); //or any other extension
                    // }
                    // link.click();

                    // // document.body.appendChild(link);

                    // // link.download = `${insurer}.xlsx`;
                    // // link.click();
                    // //callbackFunc(true, "File Download started");
                    alert(
                        "File Download started",
                        "loadingSuccess",
                        3000,
                        loading
                    );
                }
            })
            .catch((e) => {
                console.log(e, "errors");
                //callbackFunc(false, "network error..");
                alert("Network error", "loadingError", 3000, loading);
            });
    }

    getExcelDownload(path, payload, filename) {
        const loading = alert("downloading", "loading", 3000);
        axios({
            url: path,
            method: "POST",
            data: payload,
            headers: { Authorization: getAuthToken() },
        })
            .then((response) => {
                const fileData = response.data.response.data;
                let buf = new Uint8Array(fileData).buffer;

                const blob = new Blob([buf], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                // const downloadLink = document.createElement("a");
                // downloadLink.href = URL.createObjectURL(blob);

                // downloadLink.download = `${filename}.xlsx`;

                // document.body.appendChild(downloadLink);
                // downloadLink.click();

                // URL.revokeObjectURL(downloadLink.href);
                alert("File Download started", "loadingSuccess", 3000, loading);
                downloadBlobIphone(blob, filename);
            })
            .catch((error) => {
                console.error("Error:", error);
                alert("Network error", "loadingError", 3000, loading);
            });
    }

    getFile(path, uuid, fileType, callbackFunc, propFileName = "") {
        const loading = alert("downloading", "loading", 3000);
        axios({
            url: `${path}`,
            method: "GET",
            headers: { Authorization: getAuthToken() },
            responseType: "blob",
        })
            .then((response) => {
                if (response.data.type !== "application/octet-stream") {
                    //callbackFunc(false, "Unable to download");
                    alert("Unable to download", "loadingError", 3000, loading);
                } else {
                    //callbackFunc(true, "downloading");
                    const contentDisposition =
                        response?.headers?.["content-disposition"];
                    let fileName;

                    if (propFileName) {
                        fileName = propFileName;
                    } else if (contentDisposition) {
                        const fileNameMatch = contentDisposition.match(
                            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                        );
                        if (fileNameMatch && fileNameMatch[1]) {
                            fileName = fileNameMatch[1].replace(/['"]/g, "");
                        }
                    }

                    // if (path.includes("member/card/download")) {
                    //     downloadBlobIphone2(
                    //         new Blob([response.data]),
                    //         fileName
                    //     );
                    // } else {
                    downloadBlobIphone(
                        new Blob([response.data], {
                            type: generateContentType(fileName),
                        }),
                        fileName
                    );
                    // }

                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    console.log("**************************************");
                    let reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onloadend = function () {
                        let base64data = reader.result;
                        console.log(
                            "The Response Data Base64 ===== >",
                            base64data
                        );
                        window?.ReactNativeWebView?.postMessage(
                            JSON.stringify({
                                tag: "downloadfile",
                                url: url,
                                name: fileName,
                                data: base64data,
                            })
                        );
                    };

                    //callbackFunc(true, "File Download started");
                    alert(
                        "File Download Completed",
                        "loadingSuccess",
                        3000,
                        loading
                    );
                }
            })
            .catch((err) => {
                //callbackFunc(false, "Network error");
                alert("Network error", "loadingError", 3000, loading);
            });
    }

    postUpload(url, data) {
        console.log(data, "Upload data");
        return this.service.request({
            method: "POST",
            url: url,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: getAuthToken(),
            },
        });
    }

    put(path, payload) {
        checkSession();
        path = removeTrailingSlash(path);

        return this.service.request({
            method: "PUT",
            url: path,
            responseType: "json",
            data: payload,
            headers: { Authorization: getAuthToken() },
        });
    }

    putMultipart(url, data) {
        console.log(data, "Upload data");
        return this.service.request({
            method: "PUT",
            url: url,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: getAuthToken(),
            },
        });
    }

    delete(path, payload) {
        path = removeTrailingSlash(path);

        return this.service.request({
            method: "DELETE",
            url: path,
            responseType: "json",
            data: payload,
            headers: {
                Authorization: getAuthToken(),
            },
        });
    }

    get_member_request = (path, id, pid, callback) => {
        return this.service
            .request({
                method: "GET",
                url: `${path}${id}/${pid}`,
                responseType: "json",
                headers: {
                    Authorization: getAuthToken(),
                },
            })
            .then((res) => (callback ? callback(res) : null));
    };

    post_admin_action = (
        path,
        option,
        uuid,
        ids,
        policyType,
        pid,
        extras,
        callback
    ) => {
        console.log(policyType, "testtttting1");
        let alertPending = null;
        switch (option) {
            case "DOWNLOAD":
                alertPending = alert(
                    "Downloading, you will also receive a copy of this on your email",
                    "loading"
                );
                break;
            case "VERIFY":
                alert(
                    "We are processing your request, please check your email for the update",
                    "success"
                );
                break;
            case "REJECT":
                alert(
                    "We are processing your request, please check your email for the update",
                    "success"
                );
                break;
            default:
                alert(
                    "We are processing your request, please check your email for the update",
                    "success"
                );
                break;
        }

        // const payload = {
        //     uids: ids,
        //     uuid: uuid,
        //     ...extras,
        // };
        const payload = {
            emailPayloadDTO: {
                adminAction: option,
                createBatch: extras.batch || false,
                policyType: policyType,
                sentOn: extras.sentOn,
                uuid: uuid,
                comment: extras.comment,
            },
            leadData: extras.lead || {
                companyName: "",
            },
            data: extras.data,
            policyData: extras.policyData,
        };
        return this.service
            .request({
                method: "POST",
                url: path,
                responseType: "json",
                data: payload,
                headers: {
                    Authorization: getAuthToken(),
                },
            })
            .then((res) => {
                if (res.data.success) {
                    if (option === "DOWNLOAD") {
                        res.data.response.forEach((file, index) => {
                            const buffer = new Uint8Array(file.buffer.data);
                            const blob = new Blob([buffer], {
                                type: "octet/stream",
                            });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${file.name}`);
                            document.body.appendChild(link);
                            link.click();
                            setTimeout(
                                () => document.body.removeChild(link),
                                100
                            );
                        });
                        alert("Success", "loadingSuccess", 3000, alertPending);
                        this.get_member_request(
                            ADMIN_ACTIONS_GET,
                            uuid,
                            pid,
                            callback
                        );
                    }
                } else {
                    if (option === "DOWNLOAD") {
                        alert(
                            res.data.message,
                            "loadingError",
                            3000,
                            alertPending
                        );
                    } else {
                        alert(res.data.message, "error");
                    }
                }
            })
            .catch((err) => {
                alert(err, "loadingError", 3000, alertPending);
                callback(false, "Error occured");
            });
    };

    get_admin_users = (path, uuid, callback) => {
        return this.service
            .request({
                method: "GET",
                url: `${path}${uuid}`,
                responseType: "json",
                headers: {
                    Authorization: getAuthToken(),
                },
            })
            .then((res) => (callback ? callback(res) : null));
    };

    delete_admin_user = (path, uuid, callback) => {
        return this.service
            .request({
                method: "GET",
                url: `${path}${uuid}`,
                responseType: "json",
                headers: {
                    Authorization: getAuthToken(),
                },
            })
            .then((res) => (callback ? callback(res) : null));
    };
}

export default new Service();
