export enum ActionType {
    Redirect = "redirect",
    Upload = "upload",
    Download = "download",
    Component = "component",
    Options = "options",
}

export enum ButtonType {
    Submit = "submit",
    Confirm = "confirm",
}

export enum DocumentType {
    ClaimForm = "claim_form",
    DischargeSummary = "discharge_summmary",
    BillsAndPaymentReceipts = "bills_and_payment_receipts",
    UploadFinalBill = "upload_final_bill",
    UploadReceipts = "upload_receipts",
    UploadOtherReceipts = "upload_other_bills_and_payment_receipts",
    Reports = "reports",
    UploadLabReport = "upload_lab_report",
    UploadImplantSticker = "upload_implant_sticker",
    UploadMLCFIR = "upload_mlc_fir",
}

export type Action = {
    id: string;
    type: "redirect" | "download" | "upload" | "component" | "options";
    title?: string;
    text?: string;
    url?: string;
    redirectId?: string;
    options?: string[];
    componentType?: string;
    componentAction?: string;
};

// create a CustomFile type with all properties of File type and ad id, url, status as string
export interface CustomFile extends File {
    id: string;
    url: string;
    status: string;
}

export type Submit = {
    id: string;
    text: string;
    type: "upload" | "confirm";
    config: any; // Replace 'any' with the appropriate type for apiConfig
};

export type View = {
    id: string;
    title: string;
    text: string;
    banner: string;
    titleActions?: Action[][];
    actions: Action[][];
    submit: Submit;
    isMandatory?: boolean;
};

export type StoreType = {
    [key: string]: boolean;
};

export type requiredFieldsType = {
    [key: string]: boolean | string[];
};

export type ActionCardProps = {
    action: Action;
    claim?: any;
    handleAction: (
        action: Action,
        fileArr?: CustomFile[],
        key?: number,
        value?: boolean
    ) => void;
    files?: CustomFile[];
    setFiles?: (files: CustomFile[]) => void;
    fileLoader?: boolean;
    completed?: boolean;
    setshowUploadInfo: (showUploadInfo: boolean) => void;
    setFileLoader?: (fileLoader: boolean) => void;
    customCard: JSX.Element | null;
    loader: boolean;
    index: number;
    extraComponent?: JSX.Element | null;
};

export enum ClaimSteps {
    HOSPITALISATION = "Hospitalisation",
    UPLOADDOCUMENTS = "Upload Documents",
    KYC = "KYC",
    DECLARATION = "Declaration",
}

export enum ClaimViewStartId {
    UPLOADDOCUMENTS = "upload_documents_view",
    KYC = "upload_KYC_view",
    DECLARATION = "data_declaration_view",
}

export type ClaimStepsType = {
    id: ClaimSteps;
    heading: string;
    subText: string;
    additional?: string;
};

export enum componentActionType {
    PatientInfoCard = "PatientInfoCard",
    ClaimInfoCard = "ClaimInfoCard",
    CkycFormDownload = "CkycFormDownload",
    ClaimFormDownload = "claimFormDownload",
}

export interface ClaimLocationProps {
    pathname: string;
    search: string;
    hash: string;
    state: {
        claim: {
            uuid: string;
            policyNo: string;
            memberId: number;
            primaryMember: string;
            patientName: string;
            relationship: string;
            tpaId: string;
            insurerId: string | null;
            employeeId: string;
            hospitalId: string | null;
            hospitalName: string;
            hospitalAddress: string;
            pincode: string;
            city: string | null;
            state: string | null;
            tpaClaimId: string | null;
            insurerClaimId: string | null;
            email: string;
            phone: string;
            claimRegisteredDate: string;
            admissionDate: string;
            dischargeDate: string;
            dataUploadedDate: string | null;
            dataUploadedComments: string | null;
            dataVerifiedDate: string | null;
            fileReceivedDate: string | null;
            insurerComments: string | null;
            dob: string;
            gender: string;
            insurerFileReceivedDate: string | null;
            approvedDate: string | null;
            paymentDate: string | null;
            claimAmount: number;
            deduction: string | null;
            amountPaid: number | null;
            deductionReason: string | null;
            status: string;
            displayStatus: string;
            subStatus: string | null;
            expense: string;
            settlementType: string;
            disease: string;
            diseaseCategory: string | null;
            internalId: string;
            approxAmount: number;
            approxRoomRent: string | null;
            intimateComment: string;
            bankName: string | null;
            paymentRef: string | null;
            bankBranch: string | null;
            situation: string | null;
            comment: string | null;
            admissionDocument: string | null;
        };
        allViews?: View[];
        intimationStep?: string;
        viewId?: string;
        requiredFields?: requiredFieldsType | StoreType;
        internalId?: string;
        claimStep?: string;
        filesTimeline?: StoreType;
        claimFilledForm?: boolean;
    };
    key: string;
}
