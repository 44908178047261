import {
    Box,
    Divider,
    Skeleton,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import Iconify from "../../components/iconify";
import CoverageCard from "./CoverageCard";
import { useNavigate, useParams } from "react-router-dom";
// import currencyFormater from "../../util/function/currencyFormater";
import {
    PolicyFeatures,
    policyService,
} from "../../services/api/policyServics";
import { useQuery } from "react-query";
import ts from "typescript";
import {
    getCoverageIcon,
    getFeatureIcon,
} from "../../assets/images/policy_features_icons";

const Coverages = () => {
    const [searchText, setSearchText] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();
    const [included, setIncluded] = React.useState<PolicyFeatures[]>([]);
    const [excluded, setExcluded] = React.useState<PolicyFeatures[]>([]);

    const user = JSON.parse(sessionStorage.getItem("user") || "{}");
    const { uuid, pid } = useParams();

    const { data: policyData, isSuccess } = useQuery<{
        d_features: PolicyFeatures[];
    }>(
        ["policyFeatures", user.userType, uuid, pid],
        () =>
            policyService.getPolicyFeatures(
                user,
                pid as string,
                uuid as string
            ),

        {
            staleTime: 60000,
            enabled: !!user && pid !== null,
        }
    );

    useEffect(() => {
        if (isSuccess) {
            const policyFeatures = policyData?.d_features;
            const included = policyFeatures?.filter((item) => item.page === 1);
            const excluded = policyFeatures?.filter((item) => item.page === 2);

            setIncluded(included);
            setExcluded(excluded);
            setLoading(false);
        }
    }, [isSuccess, policyData]);

    const filteredData = included?.filter((item) =>
        item?.heading?.toLowerCase().includes(searchText.toLowerCase())
    );

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            {/* <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    height: "54px",
                    padding: "0px 20px",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "8px",
                    alignSelf: "stretch",
                    borderRadius: "46px",
                    border: "1px solid #7F85A3",
                    "&:focus-within": {
                        border: `1px solid ${theme.palette.brand.main}`,
                    },
                }}
            >
                <Iconify
                    icon={"material-symbols:search"}
                    width={"24px"}
                    height={"24px"}
                    color={"#464D6A"}
                />
                <TextField
                    variant="outlined"
                    placeholder="Search coverages"
                    fullWidth
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    sx={{
                        flex: 1,
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                border: "none",
                            },
                        },
                    }}
                />
                <Iconify
                    icon={"material-symbols:cancel-outline"}
                    width={"24px"}
                    height={"24px"}
                    color={"#464D6A"}
                    onClick={() => setSearchText("")}
                    sx={{
                        cursor: "pointer",
                    }}
                />
            </Box> */}
            <Stack
                flexDirection={"row"}
                justifyContent={
                    excluded?.length > 0 ? "space-between" : "start"
                }
                alignItems={"center"}
                width={"100%"}
            >
                <Typography color={theme.palette.text.primary} variant="h5">
                    {`${included?.length} Coverages`}
                </Typography>
                {excluded?.length > 0 && (
                    <Typography
                        variant="subtitle2"
                        sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontSize: "12px",
                        }}
                        onClick={() =>
                            navigate("/plans/whatsNotCovered", {
                                state: { excluded },
                            })
                        }
                    >
                        What's not covered?
                        <Iconify
                            sx={{
                                marginLeft: "8px",
                            }}
                            icon={"material-symbols:arrow-right-alt"}
                        />
                    </Typography>
                )}
            </Stack>
            <Divider
                variant="dashed"
                sx={{
                    borderColor: "grey.500",
                    opacity: 0.3,
                    marginBottom: "8px",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    gap: { xs: "16px", sm: "24px" },
                }}
            >
                {filteredData?.map((coverage, index) => {
                    if (coverage.label === "diseaseWiseLimitMap") {
                        return null;
                    }

                    let dialogBoxContent = null;
                    if (coverage.label === "diseaseWiseLimit") {
                        const diseaseWiseLimitMapObj = filteredData.find(
                            (item) => item.label === "diseaseWiseLimitMap"
                        );
                        if (diseaseWiseLimitMapObj) {
                            dialogBoxContent = JSON.parse(
                                diseaseWiseLimitMapObj.text
                            );
                        }
                    }

                    return (
                        <Stack
                            key={index}
                            flexDirection={"column"}
                            spacing={{ sm: "24px", xs: "16px" }}
                            width={{ sm: "calc(50% - 12px)", xs: "100%" }}
                        >
                            <CoverageCard
                                coverageName={coverage?.heading}
                                coverageAmount={coverage?.subheading}
                                coverageDescription={coverage?.text}
                                icon={getCoverageIcon(coverage?.label)}
                                DialogBoxContent={dialogBoxContent}
                                richText={coverage?.richText}
                                label={coverage?.label}
                            />
                            {((isMobile && index < filteredData.length - 1) ||
                                (!isMobile &&
                                    index < filteredData.length - 2)) && (
                                <Divider
                                    variant="dashed"
                                    sx={{
                                        borderColor: "grey.400",
                                        opacity: 0.3,
                                        marginBottom: "8px",
                                    }}
                                />
                            )}
                        </Stack>
                    );
                })}
                {loading && [1,2,3,4].map((each,index)=><Stack
                            key={'skeleton'}
                            flexDirection={"column"}
                            spacing={{ sm: "24px", xs: "16px" }}
                            width={{ sm: "calc(50% - 12px)", xs: "100%" }}
                        >
                            <Box
            sx={{
                display: "flex",
                width: { sm: "341px", xs: "100%" },
                // minHeight: "110px",
                height: "100%",
                alignItems: "flex-start",
                gap: "8px",
            }}
        >
            <Skeleton
            variant="circular"
                width={"24px"}
                height={"24px"}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                    flex: "1 0 0",
                    alignSelf: "stretch",
                    height: { xs: "fit-content", sm: "100%" },
                }}
            >
                <Stack flexDirection={"row"} alignItems={"center"} gap={"8px"} width={'100%'}>
                    <Skeleton variant="text" width={'50%'} />
                </Stack>

                <Skeleton variant="text" width={'100%'} />
                
            </Box>
        </Box>
                            {((isMobile && index < filteredData.length - 1) ||
                                (!isMobile &&
                                    index < filteredData.length - 2)) && (
                                <Divider
                                    variant="dashed"
                                    sx={{
                                        borderColor: "grey.400",
                                        opacity: 0.3,
                                        marginBottom: "8px",
                                    }}
                                />
                            )}
                        </Stack>)}
            </Box>
        </>
    );
};

export default Coverages;
