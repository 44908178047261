import React, { useEffect } from "react";

function Okta() {
    useEffect(() => {
        // get the token from the url

        const token = window.location.href.split("/").pop();
        if (!token) {
            return;
        }
        localStorage.setItem("Authorization", `Bearer ${token}`);
        console.log("Authorization", `Bearer ${token}`);
        window.location.href = "/";
    }, []);

    return <div>Redirecting ...</div>;
}

export default Okta;
