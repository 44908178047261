import React, { useState, useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./CustomPagination.scss";
const Next = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/next.svg";

function CustomPagination({ pageChange, page, nextPage, prevPage, tab }) {
    const [currentPage, setCurrentPage] = useState(page);

    useEffect(() => {
        setCurrentPage(currentPage);
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(page);
    }, [tab || page]);

    return (
        <div className="customPagination">
            <button
                className="customPaginationPrev customPaginationArrow"
                onClick={() => {
                    if (prevPage != 0) {
                        setCurrentPage(currentPage - 1);
                    pageChange(currentPage - 1,currentPage);
                    }
                }}
            >
                <img src={Next} alt="next" />
            </button>
            {currentPage > 1 && prevPage && "..."}
            {currentPage > 0 && (
                <button
                    onClick={() => {
                        setCurrentPage(currentPage - 1);
                        pageChange(currentPage - 1,currentPage);
                    }}
                >
                    {currentPage}
                </button>
            )}
            <button className="customPaginationSelected">
                {currentPage + 1}
            </button>
            {nextPage.length > 0 ? (
                <button
                    onClick={() => {
                        setCurrentPage(currentPage + 1);
                        pageChange(currentPage + 1,currentPage);
                    }}
                >
                    {currentPage + 2}
                </button>
            ) : (
                ""
            )}
            {nextPage.length > 0 ? "..." : ""}
            <button
                className="customPaginationNext customPaginationArrow"
                onClick={() => {
                    if (nextPage != 0) {
                        setCurrentPage(currentPage + 1);
                        pageChange(currentPage + 1,currentPage);
                    }
                }}
            >
                <img src={Next} alt="next" />
            </button>
        </div>
    );
}

export default CustomPagination;
