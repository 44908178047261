// @ts-nocheck
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    View,
    requiredFieldsType,
    ClaimSteps,
    ClaimStepsType,
    ClaimLocationProps,
} from "../../../../../../types/claims";
import "../../../Claims.scss";
import "./UploadDocuments.scss";
import Modal from "@mui/material/Modal/Modal";
import CustomerCareCard from "../../CutomerCareCard/CutomerCareCard";
import RightColumnCard from "../../RightColumnCard/RightColumnCard";
import BannerImg from "../../../../../../assets/images/Untitled.png";
const CheckIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/tick.png";
// const BannerImg =
//     "https://imtbepublic.s3.ap-south-1.amazonaws.com/assets/images/Illustration_lower_size/Illustration_Declaration.jpg";

const BackArrowIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/assets/images/icons/icon_action_back.png";
const QuestionMark =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/questionMark.svg";
const CloseIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Modals_icons/Close.svg";

type UploadHomeProps = {
    setShowHome?: (value: boolean) => void;
    setStep?: (value: number) => void;
    store?: {};
    refId?: string;
    claimStep?: string;
    patient?: {
        policies: [];
    };
};

const ProceedCTAText = {
    Hospitalisation: "Proceed",
    "Upload Documents": "Upload Documents",
    KYC: "Upload KYC",
    Declaration: "Give Declaration",
};

type JsonURLTypes = {
    [key: string]: string;
};

const hospitalisationUrls: JsonURLTypes = {
    [ClaimSteps.UPLOADDOCUMENTS]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/hospitalisation_data.json",
    [ClaimSteps.KYC]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/kyc_data.json",
    [ClaimSteps.DECLARATION]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/declaration_data.json",
};

const prepostUrls: JsonURLTypes = {
    [ClaimSteps.UPLOADDOCUMENTS]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/prepost_data.json",
    [ClaimSteps.KYC]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/kyc_data.json",
    [ClaimSteps.DECLARATION]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/declaration_data.json",
};

const dayCareUrls: JsonURLTypes = {
    [ClaimSteps.UPLOADDOCUMENTS]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/hospitalisation_data.json",
    [ClaimSteps.KYC]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/kyc_data.json",
    [ClaimSteps.DECLARATION]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/declaration_data.json",
};

const opdUrls: JsonURLTypes = {
    [ClaimSteps.UPLOADDOCUMENTS]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/prepost_data.json",
    [ClaimSteps.KYC]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/kyc_data.json",
    [ClaimSteps.DECLARATION]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/declaration_data.json",
};

const claimStepsText: ClaimStepsType[] = [
    {
        id: ClaimSteps.HOSPITALISATION,
        heading: "Hospitalisation details",
        subText:
            "To start, please share the details about patient's hospitalisation.",
        additional: "Ref. ID {refId}",
    },
    {
        id: ClaimSteps.UPLOADDOCUMENTS,
        heading: "Upload documents",
        subText:
            "After patient is discharged their hospitalisation documents bills, and reports must be uploaded on the portal",
    },
    {
        id: ClaimSteps.KYC,
        heading: "KYC information",
        subText:
            "For claims reimbursement, we need to verify the patient's identity(KYC) and employee's account",
    },
    {
        id: ClaimSteps.DECLARATION,
        heading: "Declaration",
        subText:
            "To complete the reimbursement process please take a moment to carefully review and acknowledge the terms and conditions",
    },
];

interface VariableMap {
    [key: string]: any;
}

function UploadHome({
    setStep,
    setShowHome,
    refId,
    store,
    claimStep,
    patient,
}: UploadHomeProps) {
    const location: ClaimLocationProps = useLocation();
    const navigate = useNavigate();
    const intimationStep = claimStep
        ? claimStep
        : location.state?.claimStep
            ? location.state?.claimStep
            : ClaimSteps.UPLOADDOCUMENTS;
    const [requiredFields, setRequiredFields] = useState<requiredFieldsType>(
        {}
    );
    const [doneSteps, setDoneSteps] = useState<string[]>([]);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [claim, setClaim] = useState(location.state?.claim);
    const [allViews, setAllViews] = useState<View[]>([]);
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleCompletedStep = (step: string) => {
        const done = [];
        switch (step) {
            case ClaimSteps.HOSPITALISATION:
                done.push("");
                break;
            case ClaimSteps.UPLOADDOCUMENTS:
                done.push(ClaimSteps.HOSPITALISATION);
                break;
            case ClaimSteps.KYC:
                done.push(
                    ClaimSteps.HOSPITALISATION,
                    ClaimSteps.UPLOADDOCUMENTS
                );
                break;
            case ClaimSteps.DECLARATION:
                done.push(
                    ClaimSteps.HOSPITALISATION,
                    ClaimSteps.UPLOADDOCUMENTS,
                    ClaimSteps.KYC
                );
                break;
        }
        setDoneSteps(done);
    };

    useEffect(() => {
        // Try scrolling the window
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    
        // If you have a custom scroll container, target it like this:
        const mainContent = document.getElementsByClassName('body-container')[0]; // Replace 'scroll-container' with your element ID
        if (mainContent) {
          mainContent.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      }, []);

    const getJSONData = async () => {
        handleCompletedStep(intimationStep);
        let jsonURL = "";
        switch (location.state?.claim.expense) {
            case "hospitalisation":
                jsonURL = hospitalisationUrls[intimationStep];
                break;
            case "daycare":
                jsonURL = hospitalisationUrls[intimationStep];
                break;
            case "prepost":
                jsonURL = prepostUrls[intimationStep];
                break;
            case "opd":
                jsonURL = prepostUrls[intimationStep];
                break;
            default:
                jsonURL =
                    "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/hospitalisation_data.json";
                break;
        }

        fetch(jsonURL, { cache: "no-cache" })
            .then((res) => res.json())
            .then((data) => {
                const views = data;

                setAllViews(views);
                if (intimationStep === ClaimSteps.DECLARATION) {
                    setRequiredFields({
                        1: false,
                        2: false,
                        3: false,
                    });
                } else {
                    findMandatoryFields(views);
                }
            })
            .catch((err) => console.error(err));
    };

    const findMandatoryFields = (views: View[]) => {
        const requiredFields: requiredFieldsType = {};
        views.forEach((view) => {
            if (view.isMandatory) {
                // this check to be removed till we figure out how to handle conditional mandatory fields
                if (view.id === "CKYC_form_view" && claim.claimAmount < 100000)
                    return;

                const hasUploadAction = view.actions.some((subArray) =>
                    subArray.some((action) => action.type === "upload")
                );

                if (hasUploadAction) {
                    requiredFields[view.submit.config] = false;
                } else {
                    const linkedUploadViews = view.actions
                        .flat()
                        .filter(
                            (action) =>
                                action.type === "redirect" &&
                                action.text === "Mandatory"
                        )
                        .map((action) => action.redirectId)
                        .flatMap((redirectId) =>
                            views
                                .find((v) => v.id === redirectId)
                                ?.actions.flat()
                        )
                        .some((action) => action?.type === "upload");
                    if (linkedUploadViews) {
                        const linkedUploadRedirectIds = view.actions
                            .flat()
                            .filter(
                                (action) =>
                                    action.type === "redirect" &&
                                    action.text === "Mandatory" &&
                                    action.redirectId
                            )
                            .map((action) => action.redirectId);
                        if (linkedUploadRedirectIds) {
                            const ids = linkedUploadRedirectIds.map(
                                (id) =>
                                    views.find((v) => v.id === id)?.submit
                                        .config
                            );
                            requiredFields[view.id] = [...(ids as string[])];
                        }
                    }
                }
            }
        });

        setRequiredFields(requiredFields);
    };

    useEffect(() => {
        if (location.state?.claim) {
            setClaim(location.state?.claim);
            getJSONData();
        }
    }, [location.state?.claim]);

    useEffect(() => {
        const searchText = location.state?.claimStep;

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            switch (searchText) {
                case ClaimSteps.KYC:
                    scrollContainer.scrollTop =
                        scrollContainer.scrollHeight * 5;
                    break;
                case ClaimSteps.DECLARATION:
                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                    break;
                default:
                    scrollContainer.scrollTop = 0;
                    break;
            }
        }
    }, [location.state?.claimStep]);

    return (
        <div
            className={
                claimStep === ClaimSteps.HOSPITALISATION
                    ? "uploadScreenColumn"
                    : "twoColumn uploadScreenColumn"
            }
        >
            {/* <div className="uploadDocuments claimJourneyHome twoColumn_left claim-upload"> */}
            <div className="claim-upload claimJourneyHome uploadScreenColumn_left">
                <div className="claim-upload_top">
                    <div className="claim-upload_top_navigation p20">
                        <div className="backArrow">
                            <img
                                className="backArrowImg"
                                onClick={() => {
                                    if (patient && setStep) {
                                        if (store?.expense === "prepost") {
                                            setStep(7);
                                        } else {
                                            setStep(6);
                                        }
                                    } else if (setStep) {
                                        setStep(0);
                                    } else {
                                        navigate("/claims", {
                                            state: {
                                                viewTabs: false,
                                            },
                                            replace: true,
                                        });
                                    }
                                }}
                                src={BackArrowIcon}
                                alt=""
                            />
                        </div>
                        <h1 className="m0 ml10">
                            {claimStep === ClaimSteps.HOSPITALISATION
                                ? "Know about"
                                : " Your Claims"}
                        </h1>
                        <button
                            onClick={() => setShowHelp(true)}
                            className="mobileOnlyHelpButton"
                        >
                            Help
                            <img src={QuestionMark} alt="help icon" />
                        </button>
                    </div>
                    <div className="claim-upload_top_banner">
                        <div className="claim-upload_top_banner_image uploadHome_banner_img">
                            <img src={BannerImg} alt="" />
                        </div>

                        <div className="claim-upload_top_banner_text">
                            <h2>Your reimbursement claim journey</h2>
                            <p>We will need some information to get started</p>
                        </div>
                    </div>
                </div>
                <div
                    className="claim-upload_body claim_journey_screen_body"
                    ref={scrollContainerRef}
                >
                    <div className="claim_journey_screen_body_steps">
                        {claimStepsText.map((step) => {
                            const done = doneSteps.includes(step.id);
                            const active = step.id === intimationStep;
                            return (
                                <div
                                    className={`claim_journey_screen_body_step ${doneSteps.includes(step.id)
                                        ? "done"
                                        : ""
                                        } `}
                                >
                                    <div className="claim_journey_screen_body_step_icon">
                                        {doneSteps.includes(step.id) ? (
                                            <img src={CheckIcon} alt="" />
                                        ) : (
                                            <div className="step_icon_circle">
                                                {""}
                                            </div>
                                        )}
                                    </div>
                                    <div className="step_card">
                                        <h2>{step.heading}</h2>
                                        {active || done ? (
                                            <p>{step.subText}</p>
                                        ) : null}
                                        {claim?.internalId &&
                                            step.additional ? (
                                            <p>
                                                {step.additional?.replace(
                                                    "{refId}",
                                                    ""
                                                )}{" "}
                                                <strong>
                                                    {claim?.internalId}
                                                </strong>{" "}
                                            </p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    className="claim-upload_footer"
                    style={
                        claimStep === ClaimSteps.HOSPITALISATION
                            ? { justifyContent: "flex-end" }
                            : { justifyContent: "flex-end" }
                    }
                >
                    {claim && intimationStep !== ClaimSteps.DECLARATION ? (
                        <button
                            className="buttonWhite pl25 pr25"
                            onClick={() => {
                                setShowPopup(true);
                            }}
                        >
                            I don't have documents right now
                        </button>
                    ) : null}

                    {claimStep === "Hospitalisation" ? (
                        <button
                            className="buttonPrimary pl50 pr50 active"
                            onClick={() => {
                                if (setStep) {
                                    if (patient) setStep(2);
                                    else setStep(4);
                                } else {
                                    return;
                                }
                            }}
                        >
                            Let's get started
                        </button>
                    ) : (
                        <button
                            className={`buttonPrimary pl50 pr50 ${!(allViews.length > 0) ? "disabled" : "active"
                                }`}
                            onClick={() => {
                                if (
                                    claim &&
                                    intimationStep &&
                                    allViews.length > 0
                                ) {
                                    navigate("/claims/uploadDocuments", {
                                        state: {
                                            intimationStep,
                                            requiredFields,
                                            claim,
                                            allViews,
                                        },
                                    });
                                } else {
                                    return;
                                }
                            }}
                        >
                            {
                                ProceedCTAText[
                                intimationStep as keyof typeof ProceedCTAText
                                ]
                            }
                        </button>
                    )}
                </div>
                {showPopup && (
                    <Modal
                        open={showPopup}
                        onClose={() => setShowPopup(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="uploadHomePopup">
                            <p>
                                You can upload documents once hospitalisation
                                documents arrive.
                            </p>
                            <button
                                className="buttonPrimary pl25 pr25"
                                onClick={() => {
                                    setShowPopup(false);
                                    if (setShowHome) {
                                        setShowHome(false);
                                    } else {
                                        // navigate("/claims", {
                                        //     state: {
                                        //         viewTabs: false,
                                        //     },
                                        //     replace: true,
                                        // });
                                    }
                                }}
                            >
                                Got it
                            </button>
                        </div>
                    </Modal>
                )}
                {showHelp && (
                    <Modal
                        open={showHelp}
                        onClose={() => setShowHelp(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="ClaimPopup customerCarePopup">
                            <div
                                className="popup_cancelIcon"
                                onClick={() => setShowHelp(false)}
                            >
                                <img src={CloseIcon} alt="" />
                            </div>
                            <CustomerCareCard />
                        </div>
                    </Modal>
                )}
            </div>
            {claimStep === "Hospitalisation" ? null : (
                <div className="twoColumn_right rightContainer uploadScreenColumn_right">
                    <CustomerCareCard classname="mb12" />
                    <RightColumnCard />
                </div>
            )}
        </div>
    );
}

export default UploadHome;
