import { AxiosResponse } from "axios";
import { JavaResponse } from "../../types";
import { LoginResponse } from "../../types/DTO/loginDTO";

import { updateAuthStep, updateAuthStore } from "../../features/auth/authSlice";

export default function checkResponseAndSetStep(
    res: AxiosResponse<JavaResponse<LoginResponse>>,
    dispatch: Function,
    count = 0
): false | AxiosResponse<JavaResponse<LoginResponse>> {
    dispatch(updateAuthStore({ authorization: res?.headers?.authorization }));
    dispatch(updateAuthStore({ user: res?.data?.response?.user }));

    if (res.data.response.page === "multipleLookupPhone") {
        dispatch(updateAuthStep("multipleLookupPhone"));
        // setStep("multipleLookupPhone");
        return false;
    } else if (res.data.response.page === "multipleLookupEmail") {
        dispatch(updateAuthStep("multipleLookupEmail"));
        // setStep("multipleLookupEmail");
        return false;
    } else if (
        res.data.response?.user?.userType === "BROKER" ||
        res.data.response?.user?.userType === "AGENT" ||
        res.data.response?.user?.userType === "EMPLOYER" ||
        res.data.response?.user?.userType === "EMPLOYEE" ||
        res.data.response?.user?.userType === "USER"
    ) {
        if (res.data.response.d_verify_phone) {
            dispatch(updateAuthStep("onBoard"));
            // setStep("onBoard");
            return false;
        }
    }
    return res;
}
