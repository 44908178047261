import { HOME, HOME_EMPLOYEE, NEW_HOME_EMPLOYEE } from "../../util/config";
import service from "../../util/axiosConfig";
import { Dispatch } from "react";
import { GET_HEALTHCARE_INFO } from "../../util/newconfig";
import { BenefitInfo } from "../../views/Benefits/BenefitView";
import { HomeDtoV2 } from "../../types/DTO/homeDTO";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../types";

export const homeService = {
    getHomeData: async (
        user: { userType: string; actualUserType: string },
        dispatch: Dispatch<any>
    ) => {
        return new Promise((resolve, reject) => {
            if (user) {
                const url =
                    user.userType === "EMPLOYEE" || user.userType === "USER"
                        ? `${HOME_EMPLOYEE}${
                              user.actualUserType !== "EMPLOYEE" &&
                              user.actualUserType !== "USER"
                                  ? sessionStorage.getItem("uuid")
                                  : ""
                          }`
                        : HOME + sessionStorage.getItem("uuid");
                service.get(url).then((res) => {
                    if (res?.data.success) {
                        resolve(res.data.response);
                        if (res.headers.authorization) {
                            dispatch({
                                type: "set-authorization",
                                data: res.headers.authorization,
                            });
                            window.location.href = "/";
                        }

                        const urlParams = new URLSearchParams(
                            window.location.search
                        );
                        const walletParam = urlParams.get("wallet");
                        if (walletParam === "true") {
                            setTimeout(() => {
                                const element =
                                    document.getElementById(
                                        "Healthcare_scroll"
                                    );
                                if (element) {
                                    element.scrollIntoView();
                                }
                            }, 200);
                        }
                        return;
                    }
                    reject(res.data.message);
                    throw new Error(res.data.message);
                });
            }
            document.body.style.background =
                user.userType === "EMPLOYEE" ? "#fff" : "#f6f6f6";
        });
    },
    getHomeDataV2: async (): Promise<HomeDtoV2> => {
        return new Promise((resolve, reject) => {
            service
                .get(NEW_HOME_EMPLOYEE)
                .then((res: AxiosResponse<JavaResponse<HomeDtoV2>>) => {
                    if (res?.data.success) {
                        resolve(res.data.response);
                        return;
                    }
                    reject(res.data.message);
                    throw new Error(res.data.message);
                });
        });
    },
    getBnefitInfo: async (id: string): Promise<BenefitInfo> => {
        return new Promise((resolve, reject) => {
            service
                .get(GET_HEALTHCARE_INFO.url.replace("{templateId}", id))
                .then((res) => {
                    if (res?.data.success) {
                        resolve(res.data.response);
                        return;
                    }
                    reject(res.data.message);
                    throw new Error(res.data.message);
                });
        });
    },
};
