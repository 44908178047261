// @ts-nocheck
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    View,
    requiredFieldsType,
    ClaimSteps,
    ClaimStepsType,
    ClaimLocationProps,
} from "../../../../../types/claims";
import "../../../Claims.scss";
import "./UploadDocuments.scss";
import Modal from "@mui/material/Modal/Modal";
import CustomerCareCard from "../../CutomerCareCard/CutomerCareCard";
import RightColumnCard from "../../RightColumnCard/RightColumnCard";
import BannerImg from "../../../../../assets/images/Untitled.png";
import ClaimsContainer from "../../../ClaimsContainer";
import { Button, Divider, Typography, useTheme } from "@mui/material";
import Iconify from "../../../../../components/iconify";
const CheckIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/tick.png";
// const BannerImg =
//     "https://imtbepublic.s3.ap-south-1.amazonaws.com/assets/images/Illustration_lower_size/Illustration_Declaration.jpg";

const BackArrowIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/assets/images/icons/icon_action_back.png";
const QuestionMark =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/questionMark.svg";
const CloseIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Modals_icons/Close.svg";

type UploadHomeProps = {
    setShowHome?: (value: boolean) => void;
    setStep?: (value: number) => void;
    store?: {};
    refId?: string;
    claimStep?: string;
    patient?: {
        policies: [];
    };
    claimsList: any[]
};

const ProceedCTAText = {
    Hospitalisation: "Proceed",
    "Upload Documents": "Upload Documents",
    KYC: "Upload KYC",
    Declaration: "Give Declaration",
};

type JsonURLTypes = {
    [key: string]: string;
};

const hospitalisationUrls: JsonURLTypes = {
    [ClaimSteps.UPLOADDOCUMENTS]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/hospitalisation_data.json",
    [ClaimSteps.KYC]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/kyc_data.json",
    [ClaimSteps.DECLARATION]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/declaration_data.json",
};

const prepostUrls: JsonURLTypes = {
    [ClaimSteps.UPLOADDOCUMENTS]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/prepost_data.json",
    [ClaimSteps.KYC]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/kyc_data.json",
    [ClaimSteps.DECLARATION]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/declaration_data.json",
};

const dayCareUrls: JsonURLTypes = {
    [ClaimSteps.UPLOADDOCUMENTS]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/hospitalisation_data.json",
    [ClaimSteps.KYC]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/kyc_data.json",
    [ClaimSteps.DECLARATION]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/declaration_data.json",
};

const opdUrls: JsonURLTypes = {
    [ClaimSteps.UPLOADDOCUMENTS]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/prepost_data.json",
    [ClaimSteps.KYC]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/kyc_data.json",
    [ClaimSteps.DECLARATION]:
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/declaration_data.json",
};

const claimStepsText: ClaimStepsType[] = [
    {
        id: ClaimSteps.HOSPITALISATION,
        heading: "Claim Details",
        subText:
            "To start, please share the details about the patient’s claim.",
        additional: "Ref. ID {refId}",
    },
    {
        id: ClaimSteps.UPLOADDOCUMENTS,
        heading: "Upload documents",
        subText:
            "After patient is discharged their hospitalisation documents bills, and reports must be uploaded on the portal",
    },
    {
        id: ClaimSteps.KYC,
        heading: "KYC information",
        subText:
            "For claims reimbursement, we need to verify the patient's identity(KYC) and employee's account",
    },
    {
        id: ClaimSteps.DECLARATION,
        heading: "Declaration",
        subText:
            "To complete the reimbursement process please take a moment to carefully review and acknowledge the terms and conditions",
    },
];

interface VariableMap {
    [key: string]: any;
}

function UploadHome({
    setStep,
    setShowHome,
    refId,
    store,
    claimStep,
    patient,
    claimsList
}: UploadHomeProps) {
    const location: ClaimLocationProps = useLocation();
    const navigate = useNavigate();
    const intimationStep = claimStep
        ? claimStep
        : location.state?.claimStep
        ? location.state?.claimStep
        : ClaimSteps.UPLOADDOCUMENTS;
    const [requiredFields, setRequiredFields] = useState<requiredFieldsType>(
        {}
    );
    const theme = useTheme();
    const [doneSteps, setDoneSteps] = useState<string[]>([]);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [claim, setClaim] = useState(location.state?.claim);
    const [allViews, setAllViews] = useState<View[]>([]);
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleCompletedStep = (step: string) => {
        const done = [];
        switch (step) {
            case ClaimSteps.HOSPITALISATION:
                done.push("");
                break;
            case ClaimSteps.UPLOADDOCUMENTS:
                done.push(ClaimSteps.HOSPITALISATION);
                break;
            case ClaimSteps.KYC:
                done.push(
                    ClaimSteps.HOSPITALISATION,
                    ClaimSteps.UPLOADDOCUMENTS
                );
                break;
            case ClaimSteps.DECLARATION:
                done.push(
                    ClaimSteps.HOSPITALISATION,
                    ClaimSteps.UPLOADDOCUMENTS,
                    ClaimSteps.KYC
                );
                break;
        }
        setDoneSteps(done);
    };

    useEffect(() => {
        // Try scrolling the window
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        // If you have a custom scroll container, target it like this:
        const mainContent =
            document.getElementsByClassName("body-container")[0]; // Replace 'scroll-container' with your element ID
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    }, []);

    const getJSONData = async () => {
        handleCompletedStep(intimationStep);
        let jsonURL = "";
        switch (location.state?.claim.expense) {
            case "hospitalisation":
                jsonURL = hospitalisationUrls[intimationStep];
                break;
            case "daycare":
                jsonURL = hospitalisationUrls[intimationStep];
                break;
            case "prepost":
                jsonURL = prepostUrls[intimationStep];
                break;
            case "opd":
                jsonURL = prepostUrls[intimationStep];
                break;
            default:
                jsonURL =
                    "https://imtbepublic.s3.ap-south-1.amazonaws.com/configs/hospitalisation_data.json";
                break;
        }

        fetch(jsonURL, { cache: "no-cache" })
            .then((res) => res.json())
            .then((data) => {
                const views = data;

                setAllViews(views);
                if (intimationStep === ClaimSteps.DECLARATION) {
                    setRequiredFields({
                        1: false,
                        2: false,
                        3: false,
                    });
                } else {
                    findMandatoryFields(views);
                }
            })
            .catch((err) => console.error(err));
    };

    const findMandatoryFields = (views: View[]) => {
        const requiredFields: requiredFieldsType = {};
        views.forEach((view) => {
            if (view.isMandatory) {
                // this check to be removed till we figure out how to handle conditional mandatory fields
                if (view.id === "CKYC_form_view" && claim.claimAmount < 100000)
                    return;

                const hasUploadAction = view.actions.some((subArray) =>
                    subArray.some((action) => action.type === "upload")
                );

                if (hasUploadAction) {
                    requiredFields[view.submit.config] = false;
                } else {
                    const linkedUploadViews = view.actions
                        .flat()
                        .filter(
                            (action) =>
                                action.type === "redirect" &&
                                action.text === "Mandatory"
                        )
                        .map((action) => action.redirectId)
                        .flatMap((redirectId) =>
                            views
                                .find((v) => v.id === redirectId)
                                ?.actions.flat()
                        )
                        .some((action) => action?.type === "upload");
                    if (linkedUploadViews) {
                        const linkedUploadRedirectIds = view.actions
                            .flat()
                            .filter(
                                (action) =>
                                    action.type === "redirect" &&
                                    action.text === "Mandatory" &&
                                    action.redirectId
                            )
                            .map((action) => action.redirectId);
                        if (linkedUploadRedirectIds) {
                            const ids = linkedUploadRedirectIds.map(
                                (id) =>
                                    views.find((v) => v.id === id)?.submit
                                        .config
                            );
                            requiredFields[view.id] = [...(ids as string[])];
                        }
                    }
                }
            }
        });

        setRequiredFields(requiredFields);
    };

    useEffect(() => {
        if (location.state?.claim) {
            setClaim(location.state?.claim);
            getJSONData();
        }
    }, [location.state?.claim]);

    useEffect(() => {
        const searchText = location.state?.claimStep;

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            switch (searchText) {
                case ClaimSteps.KYC:
                    scrollContainer.scrollTop =
                        scrollContainer.scrollHeight * 5;
                    break;
                case ClaimSteps.DECLARATION:
                    scrollContainer.scrollTop = scrollContainer.scrollHeight;
                    break;
                default:
                    scrollContainer.scrollTop = 0;
                    break;
            }
        }
    }, [location.state?.claimStep]);

    return (
        <ClaimsContainer
            noFooter={true}
            headerScrollDynamic={true}
            title="Your reimbursement claim journey"
            subTitle="We will need some information to get started"
            back={() => {
                if (patient && setStep) {
                    if (store?.expense === "prepost" && claimsList.length > 0) {
                        setStep(7);
                    } else {
                        setStep(6);
                    }
                } else if (setStep) {
                    setStep(0);
                } else {
                    navigate("/claims", {
                        state: {
                            viewTabs: false,
                        },
                        replace: true,
                    });
                }
            }
            }
        >
            <div
                className={
                    claimStep === ClaimSteps.HOSPITALISATION
                        ? "uploadScreenColumn uploadScreenColumn__v2"
                        : "twoColumn uploadScreenColumn uploadScreenColumn__v2"
                }
            >
                {/* <div className="uploadDocuments claimJourneyHome twoColumn_left claim-upload"> */}
                <div className="claim-upload claimJourneyHome uploadScreenColumn_left">
                    <div
                        className="claim-upload_body claim_journey_screen_body"
                        ref={scrollContainerRef}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <div className="claim_journey_screen_body_steps">
                            {claimStepsText.map((step, index) => {
                                const done = doneSteps.includes(step.id);
                                const active = step.id === intimationStep;
                                return (
                                    <>
                                        <div
                                            style={{
                                                border: "1px solid #ECEFFF",
                                                borderRadius: "24px",
                                                padding: "16px",
                                                backgroundColor: 'white'
                                            }}
                                        >
                                            <div className="claim_journey_screen_body_step active ">
                                                <div className="">
                                                    {doneSteps.includes(
                                                        step.id
                                                    ) ? (
                                                        <Iconify
                                                            icon="material-symbols:check-circle"
                                                            color={"#896bff"}
                                                            // fontSize={"16px"}
                                                            ml={1.5}
                                                        />
                                                    ) : (
                                                        <Iconify
                                                            icon="ic:sharp-radio-button-unchecked"
                                                            color={"primary"}
                                                            // fontSize={"16px"}
                                                            ml={1.5}
                                                        />
                                                    )}
                                                </div>
                                                <div className="step_card">
                                                    <Typography
                                                        variant="subtitle2"
                                                        color={
                                                            theme.palette.text
                                                                .primary
                                                        }
                                                    >
                                                        {step.heading}
                                                    </Typography>

                                                    <Typography variant="body3">
                                                        {step.subText}
                                                    </Typography>
                                                    {claim?.internalId &&
                                                    step.additional ? (
                                                        <p>
                                                            {step.additional?.replace(
                                                                "{refId}",
                                                                ""
                                                            )}{" "}
                                                            <strong>
                                                                {
                                                                    claim?.internalId
                                                                }
                                                            </strong>{" "}
                                                        </p>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                            {active&&<Divider variant="dashed" sx={{width:'100%', marginTop:'16px', marginBottom:'16px'}} />}

                                            {active && (
                                                <div
                                                    className="claim-upload_footer"
                                                    style={
                                                       {...( claimStep ===
                                                        ClaimSteps.HOSPITALISATION
                                                            ? {
                                                                  justifyContent:
                                                                      "flex-end",
                                                              }
                                                            : {
                                                                  justifyContent:
                                                                      "flex-end",
                                                              }),
                                                               padding:0, marginTop:0
                                                            }
                                                    }
                                                >
                                                    {claim &&
                                                    intimationStep !==
                                                        ClaimSteps.DECLARATION ? (
                                                        <button
                                                            className="buttonWhite pl25 pr25"
                                                            onClick={() => {
                                                                setShowPopup(
                                                                    true
                                                                );
                                                            }}
                                                        ><Typography
                                                        variant="overline"
                                                        sx={{
                                                            textTransform: "none",
                                                        }}
                                                                color={
                                                                    '#896BFF'
                                                                }
                                                            >
                                                                I don't have
                                                                documents right
                                                                now
                                                            </Typography>
                                                        </button>
                                                    ) : null}

                                                    {claimStep ===
                                                    "Hospitalisation" ? (
                                                        <Button
                                                            className="buttonPrimary pl50 pr50 active"
                                                            variant="contained"
                                                            sx={{
                                                                py: "12px",
                                                                backgroundColor: theme.palette.brand.main
                                                            }}
                                                            onClick={() => {
                                                                if (setStep) {
                                                                    if (patient)
                                                                        setStep(
                                                                            2
                                                                        );
                                                                    else
                                                                        setStep(
                                                                            4
                                                                        );
                                                                } else {
                                                                    return;
                                                                }
                                                            }}
                                                        >
                                                        <Typography
                                                            variant="overline"
                                                            sx={{
                                                                textTransform: "none",
                                                                color:'white'
                                                            }}
                                                        >
                                                            
                                                            Let's get started
                                                        </Typography>
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className={`buttonPrimary pl50 pr50 ${
                                                                !(
                                                                    allViews.length >
                                                                    0
                                                                )
                                                                    ? "disabled"
                                                                    : "active"
                                                            }`}
                                                            variant="contained"
                                                            sx={{
                                                                py: "12px",
                                                                backgroundColor: theme.palette.brand.main
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    claim &&
                                                                    intimationStep &&
                                                                    allViews.length >
                                                                        0
                                                                ) {
                                                                    navigate(
                                                                        "/claims/uploadDocuments",
                                                                        {
                                                                            state: {
                                                                                intimationStep,
                                                                                requiredFields,
                                                                                claim,
                                                                                allViews,
                                                                            },
                                                                        }
                                                                    );
                                                                } else {
                                                                    return;
                                                                }
                                                            }}
                                                        ><Typography
                                                        variant="overline"
                                                        sx={{
                                                            textTransform: "none",
                                                            color:'white'
                                                        }}
                                                    >
                                                            {
                                                                ProceedCTAText[
                                                                    intimationStep as keyof typeof ProceedCTAText
                                                                ]
                                                            }
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {doneSteps.includes(step.id) ? (
                                            <div className="line"></div>
                                        ) : (
                                            <div className="line__inactive"></div>
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </div>

                    {showPopup && (
                        <Modal
                            open={showPopup}
                            onClose={() => setShowPopup(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div className="uploadHomePopup">
                                <Typography variant="subtitle2" style={{textAlign:'center', marginBottom:'16px'}}>
                                    You can upload documents once
                                    hospitalisation documents arrive.
                                </Typography>
                                <Button
                                                            className="buttonPrimary pl50 pr50 active"
                                                            variant="contained"
                                                            style={{
                                                                backgroundColor:'#896BFF'
                                                            }}
                                    onClick={() => {
                                        setShowPopup(false);
                                        if (setShowHome) {
                                            setShowHome(false);
                                        } else {
                                            // navigate("/claims", {
                                            //     state: {
                                            //         viewTabs: false,
                                            //     },
                                            //     replace: true,
                                            // });
                                        }
                                    }}
                                ><Typography
                                variant="overline"
                                sx={{
                                    textTransform: "none",
                                    color:'white'
                                }}
                            >
                                    Got it</Typography>
                                </Button>
                            </div>
                        </Modal>
                    )}
                    {showHelp && (
                        <Modal
                            open={showHelp}
                            onClose={() => setShowHelp(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div className="ClaimPopup customerCarePopup">
                                <div
                                    className="popup_cancelIcon"
                                    onClick={() => setShowHelp(false)}
                                >
                                    <img src={CloseIcon} alt="" />
                                </div>
                                <CustomerCareCard />
                            </div>
                        </Modal>
                    )}
                </div>
            </div>
        </ClaimsContainer>
    );
}

export default UploadHome;
