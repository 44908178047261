import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";

//Routing
import { useNavigate } from "react-router";
import GenericNotFound from "../../views/NotFound";

const NotFound = ({ status, redirectPath = null, notFoundText }) => {
    const navigate = useNavigate();
    console.log(typeof status);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    useEffect(() => {
        const defaultRoutes = {
            ADMIN: "/leads",
            BROKER: "/leads",
            EMPLOYEE: "/home",
            EMPLOYER: "/home",
        };

        let timerId;

        if (status === 404) {
            const userType = JSON.parse(
                sessionStorage.getItem("user")
            )?.userType;
            if (!userType) setTimeout(() => navigate("/login"), 3000);
            else setTimeout(() => navigate(defaultRoutes[userType]), 3000);
        } else {
            timerId = setTimeout(() => {
                navigate(redirectPath);
            }, 3000);
        }
        return () => clearTimeout(timerId);
    }, [status, navigate, redirectPath]);
    return (
        <Box
            sx={{
                paddingTop: { sm: "33px", xs: "" },
                background: `url(${
                    isMobile ? benifitMobilebg : benifitDesktopbg
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",

                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    padding: { sm: "40px 32px", xs: "32px 20px" },
                    justifyContent: "center",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    gap: { sm: "48px", xs: "40px" },
                    flexWrap: "wrap",
                    borderRadius: { sm: "40px", xs: "24px 24px 0 0" },
                    border: "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    minHeight: { xs: "100%", sm: "456px" },
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "",
                    },
                    margin: "auto",
                }}
            >
                <GenericNotFound
                    title={notFoundText || `Oops! ${status}`}
                    message={
                        notFoundText
                            ? ""
                            : "The page you are looking for doesn't exist or has been moved. Please go back to the homepage."
                    }
                />
            </Box>
        </Box>
    );
};

export default NotFound;
