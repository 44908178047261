import React from "react";
import FileInput from "../../components/Input/FileInput";
import GenericModalContainer from "../../components/Modals/GenericModalContainer";
import Button from "../Table/controls/Button";
import { CustomFile } from "../../types/claims";
import CloseIcon from "../../assets/images/icons/close-icon.svg";
import openIphoneLink from "../../util/function/openIphoneLink";
import generateIcon from "../../util/function/generateIconForFiles";
import "./fileUloadModal.scss";
import MyText from "../../components/Input/MyText";
import { Form, Formik } from "formik";

type Props = {
    openUploadModal: boolean;
    setOpenUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
    files: File[] | Blob[] | CustomFile[];
    setFiles: React.Dispatch<
        React.SetStateAction<File[] | Blob[] | CustomFile[]>
    >;
    uploadFiles: (nameValues: { [key: string]: string }) => void;
};

const FileUploadModal = ({
    openUploadModal,
    setOpenUploadModal,
    files,
    setFiles,
    uploadFiles,
}: Props) => {
    if (openUploadModal)
        return (
            <GenericModalContainer
                close={() => setOpenUploadModal(false)}
                headingText={<h1 className="m0 light">Upload document</h1>}
                styles={{ maxWidth: "560px", minWidth: "35%" }}
            >
                <Formik initialValues={{}} onSubmit={uploadFiles}>
                    <Form className="">
                        <FileInput
                            files={[]}
                            setFiles={(
                                newFiles: File[] | Blob[] | CustomFile[]
                            ) =>
                                setFiles((prevFiles) => [
                                    ...prevFiles,
                                    ...newFiles,
                                ])
                            }
                            types={"image,pdf"}
                            multiple
                            wrongInputFunction={() => console.log("wrong")}
                        />
                        <div
                            className={`fileUpload_Input2 ${
                                !!files.length && "fileUpload_Input__files"
                            }`}
                            key={`uploadScreen_id`}
                        >
                            <div className="fileUpload_Input_images">
                                {files.map(
                                    (
                                        image: File | Blob | CustomFile,
                                        index: number
                                    ) => {
                                        // im using CustomFile type to check if the file is already uploaded or not but it is not working

                                        return (
                                            <div
                                                className="fileUpload_Input_image"
                                                key={`uploadScreen_image_${index}`}
                                            >
                                                <div className="fileUpload_Input_image_selected">
                                                    <img
                                                        onClick={() => {
                                                            openIphoneLink(
                                                                URL.createObjectURL(
                                                                    image
                                                                )
                                                            );
                                                        }}
                                                        key={`uploadScreen_image_${index}`}
                                                        src={generateIcon(
                                                            image
                                                        )}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="fileUpload_Input_bottom2">
                                                    <MyText
                                                        label="Name"
                                                        // @ts-ignore
                                                        name={image.name
                                                            .split(".")
                                                            .join("_")
                                                            .split(" ")
                                                            .join("_")}
                                                        type="text"
                                                        placeholder="Name"
                                                        onChange={undefined}
                                                    />
                                                </div>
                                                <img
                                                    src={CloseIcon}
                                                    alt="check"
                                                    className="fileUpload_Input_image_close pointer"
                                                    onClick={() => {
                                                        const newFiles = [
                                                            ...files,
                                                        ];
                                                        newFiles.splice(
                                                            index,
                                                            1
                                                        );
                                                        setFiles([...newFiles]);
                                                    }}
                                                />
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <div className="buttons">
                            <Button
                                loading={0}
                                className="btn simple primary"
                                type="submit"
                                style={{
                                    marginTop: 30,
                                    color: "#fff",
                                }}
                                text={"Submit"}
                            ></Button>
                        </div>
                    </Form>
                </Formik>
            </GenericModalContainer>
        );
    else return null;
};
export default FileUploadModal;
