const evaluate = (expression, row) => {
    console.log(expression, "str");
    if (expression === "true") return true;
    else if (expression === "false") return false;
    else if (expression.length > 0) {
        for (let i = 0; i < expression.length; i++) {
            if (expression[i] === "#") {
                expression =
                    expression.substring(0, i) +
                    "row." +
                    expression.substring(i + 1, expression.length);
            }
        }
        console.log("This is the expression: " + expression);
        console.log("row: " + JSON.stringify(row));
        return eval(expression);
    } else
        return (
            row[expression] !== null &&
            row[expression] !== undefined &&
            row[expression] !== "" &&
            row[expression]
        );
};

export default evaluate;
