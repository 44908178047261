import React from "react";
import Loader from "../Loader/Loader";
import {
    Box,
    IconButton,
    Modal,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Iconify from "../iconify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const CloseIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Modals_icons/Close.svg";

const GenericModalContainer = ({
    open = true,
    fullView = false,
    noHeader = false,
    ...props
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    if (isMobile) {
        return (
            <Modal
                open={open}
                onClose={props.close}
                className="GenericModalContainer"
                aria-labelledby="modal-modal-title"
                style={
                    fullView
                        ? {
                              width: "100vw",
                              height: "100vh",
                              top: 0,
                              left: 0,
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                          }
                        : {}
                }
                aria-describedby="modal-modal-description"
            >
                <>
                    <Box
                        sx={{
                            position: fullView ? "fixed" : "absolute",
                            bottom: fullView ? "0" : "0px",
                            left: "50%",
                            transform: "translate(-50%, 0%)",
                            width: fullView ? "100vw" : "100%",
                            height: fullView ? "100vh" : "auto",
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            borderRadius: fullView ? "0" : "24px 24px 0px 0px",
                            p: props?.padding || 4,
                            ...props?.headerStyle || {}
                        }}
                    >
                        {fullView && (
                            <KeyboardBackspaceIcon
                                sx={{
                                    color: theme.palette.grey[800],
                                    cursor: "pointer",
                                    width: "32px",
                                    height: "32px",
                                    marginBottom: "24px",
                                }}
                                onClick={props.close}
                            />
                        )}
                        {!noHeader && (
                            <Stack
                                flexDirection={"row"}
                                width={"100%"}
                                justifyContent={"space-between"}
                                alignItems={"flex-start"}
                            >
                                <Stack flexDirection={"column"}>
                                    <Typography
                                        id="modal-modal-title"
                                        variant="h5"
                                        color={theme.palette.text.primary}
                                    >
                                        {props.headingText}
                                    </Typography>
                                    <Typography
                                        id="modal-modal-description"
                                        variant="body3"
                                        sx={{ mb: 2 }}
                                    >
                                        {props.subHeadingText}
                                    </Typography>
                                </Stack>
                                {!fullView && (
                                    <Iconify
                                        icon="material-symbols:close"
                                        onClick={props.close}
                                        width={"36px"}
                                        height={"36px"}
                                        sx={{
                                            cursor: "pointer",
                                            color: theme.palette.text.primary,
                                            padding: "8px",
                                        }}
                                    />
                                )}
                            </Stack>
                        )}
                        {props.children}
                    </Box>
                </>
            </Modal>
        );
    } else {
        return (
            <Modal
                open={open}
                onClose={props.close}
                className="modal-container modal-container__v2"
            >
                <div
                    className={`main-modal delete-modal GenericModalContainer ${
                        props.addClass ? props.addClass : ""
                    } main-modal__v2`}
                    style={{ ...props.styles }}
                >
                    <Loader loader={props.loader}>
                        {!noHeader && (
                            <div
                                className="modal-header"
                                style={{
                                    alignItems: "flex-start",
                                    ...props?.headerStyle || {}
                                }}
                            >
                                {props.backButton && (
                                    // <IconButton
                                    //     onClick={() => {
                                    //         props.backButton();
                                    //     }}
                                    //     color="primary"
                                    //     component="span"
                                    //     style={{
                                    //         padding: "1rem 1rem",
                                    //         // margin: "-1rem auto -1rem -1rem",
                                    //     }}
                                    // >
                                    //     <ArrowBackIcon />
                                    // </IconButton>

                                    // <Iconify
                                    //     color={"#0C1230"}
                                    //     height={24}
                                    //     width={24}
                                    //     icon="material-symbols:arrow-left-alt"
                                    //     onClick={() => {
                                    //         props.backButton();
                                    //     }}
                                    // />

                                    <KeyboardBackspaceIcon
                                        onClick={() => {
                                            props.backButton();
                                        }}
                                        style={{
                                            cursor: "pointer",
                                            color: "#0C1230",
                                            width: 24,
                                            height: 24,
                                        }}
                                    />
                                )}
                                <Box style={{ flex: 1 }}>
                                    <Typography
                                        variant="h5"
                                        className={`headings_font_color ${props?.headingClassName}`}
                                    >
                                        {props.headingText}
                                    </Typography>
                                    {props.subHeadingText && (
                                        <Typography
                                            id="modal-modal-description"
                                            variant="body3"
                                            sx={{ mb: 2 }}
                                        >
                                            {props.subHeadingText}
                                        </Typography>
                                    )}
                                </Box>
                                <span
                                    className="close"
                                    style={{
                                        position: "relative",
                                        zIndex: 998,
                                    }}
                                    onClick={props.close}
                                >
                                    <img
                                        src={CloseIcon}
                                        onClick={props.close}
                                        alt="close"
                                    />
                                </span>
                            </div>
                        )}
                        {props.children}
                    </Loader>
                </div>
            </Modal>
        );
    }
};

export default GenericModalContainer;
