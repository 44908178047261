import {PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Config {}

const initialState: Config = {};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state: Config, action: PayloadAction<Config>) => action.payload,
  },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;