import { Gender } from "../gender";

const RelationShips: {
    id: string;
    name: string;
    gender: Gender;
    relationShip: string;
}[] = [
    // {
    //     id: "Husband",
    //     name: "Husband",
    //     gender: Gender.Male,
    //     relationShip: "Spouse",
    // },
    // // add wife, son, daughter, father, mother, fatherInLaw, motherInLaw
    // {
    //     id: "Wife",
    //     name: "Wife",
    //     gender: Gender.Female,
    //     relationShip: "Spouse",
    // },
    {
        id: "Son",
        name: "Son",
        gender: Gender.Male,
        relationShip: "Child",
    },
    {
        id: "Daughter",
        name: "Daughter",
        gender: Gender.Female,
        relationShip: "Child",
    },
    {
        id: "Father",
        name: "Father",
        gender: Gender.Male,
        relationShip: "Parent",
    },
    {
        id: "Mother",
        name: "Mother",
        gender: Gender.Female,
        relationShip: "Parent",
    },
    {
        id: "FatherInLaw",
        name: "FatherInLaw",
        gender: Gender.Male,
        relationShip: "ParentInLaw",
    },
    {
        id: "MotherInLaw",
        name: "MotherInLaw",
        gender: Gender.Female,
        relationShip: "ParentInLaw",
    },
];

export const RelationShipsMapFull: {
    id: string;
    name: string;
    gender: Gender;
    relationShip: string;
}[] = [
    {
        id: "Husband",
        name: "Husband",
        gender: Gender.Male,
        relationShip: "Spouse",
    },
    // // add wife, son, daughter, father, mother, fatherInLaw, motherInLaw
    {
        id: "Wife",
        name: "Wife",
        gender: Gender.Female,
        relationShip: "Spouse",
    },
    {
        id: "Son",
        name: "Son",
        gender: Gender.Male,
        relationShip: "Child",
    },
    {
        id: "Daughter",
        name: "Daughter",
        gender: Gender.Female,
        relationShip: "Child",
    },
    {
        id: "Father",
        name: "Father",
        gender: Gender.Male,
        relationShip: "Parent",
    },
    {
        id: "Mother",
        name: "Mother",
        gender: Gender.Female,
        relationShip: "Parent",
    },
    {
        id: "FatherInLaw",
        name: "FatherInLaw",
        gender: Gender.Male,
        relationShip: "ParentInLaw",
    },
    {
        id: "MotherInLaw",
        name: "MotherInLaw",
        gender: Gender.Female,
        relationShip: "ParentInLaw",
    },
];

export default RelationShips;
