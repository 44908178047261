import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ShowEmployeeView: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const userString = sessionStorage.getItem("user");
        if (userString) {
            const user = JSON.parse(userString) as { userType: string };
            user.userType = "EMPLOYEE";
            sessionStorage.setItem("user", JSON.stringify(user));
            navigate("/home");
        }
    }, [navigate]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <CircularProgress />
        </Box>
    );
};

export default ShowEmployeeView;
