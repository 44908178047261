import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerCareData } from "../../../../../features/claims/claimSlice";

import { useStateValue } from "../../../../../util/stateProvider";

import service from "./../../../../../util/axiosConfig";

import { HOME, HOME_EMPLOYEE } from "./../../../../../util/config";
import Addon from "../../../Home/Addon";
import addonConfig, { serviceToStyle } from "../../../Home/addonsConfig";

import "./RightColumnCard.scss";
import "../../../Home/addon.scss";
import { setHomeDto } from "../../../../../features/home/homeSlice";
import { RootState } from "../../../../../store";

const RightColumnCard = () => {
    const uuid = sessionStorage.getItem("uuid");
    const user = JSON.parse(sessionStorage.getItem("user") || "");
    const reducerDispatch = useDispatch();
    const { homeDto } = useSelector((state: RootState) => state.home);
    const [addons, setAddons] = useState([] as any);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (homeDto && homeDto.d_home_addOns) {
            setAddons(homeDto.d_home_addOns as any);
            setLoader(false);
        } else {
            if (user) {
                (async () => {
                    const url =
                        user.userType === "EMPLOYEE" || user.userType === "USER"
                            ? `${HOME_EMPLOYEE}${
                                  user.actualUserType !== "EMPLOYEE"
                                      ? sessionStorage.getItem("uuid")
                                      : ""
                              }`
                            : HOME + uuid;

                    await service.get(url).then((res) => {
                        if (res?.data?.success) {
                            setAddons(res?.data?.response.d_home_addOns);
                            reducerDispatch(setHomeDto(res.data.response));
                        }

                        setLoader(false);
                    });
                })();
            }
        }
    }, [user?.userType]);

    return (
        <div
            className="rightColumnCard rightContainer"
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                // overflow: "hidden",
            }}
        >
            {loader ? (
                <>
                    <AddonSkeleton />
                    <AddonSkeleton />
                    <AddonSkeleton />
                    <AddonSkeleton />
                </>
            ) : Object.keys(addons).length > 0 ? (
                Object.keys(addons)?.map((data: any, index) => {
                    console.log("rightColumn", data);
                    let configData = addons[data];
                    // @ts-ignore
                    if (!serviceToStyle?.[configData?.service]) {
                        return;
                    }
                    const currentConfig = addonConfig[configData?.partner];

                    return (
                        <Addon
                            config={{
                                ...currentConfig,
                                partner: configData.partner,
                                isEmployee: configData.isEmployee,
                                howToUse: configData.howToUse
                                    ? JSON.parse(configData.howToUse)
                                    : configData.howToUse,
                                whatsCovered: configData.whatsCovered
                                    ? JSON.parse(configData.whatsCovered)
                                    : null,
                                params: configData.params
                                    ? JSON.parse(configData.params)
                                    : null,
                                uid: configData.uid,
                                title: configData.cardText,
                                buttonText: configData.ctaText,
                                popupHeading: configData.popupHeadingText,
                                ...(configData?.service &&
                                    serviceToStyle[
                                        configData?.service as keyof typeof serviceToStyle
                                    ][
                                        configData?.isEmployee
                                            ? "isEmployee"
                                            : "isEmployer"
                                    ]),
                                subText: configData.cardSubText,
                            }}
                            // className="cardCustom"
                            className=""
                        />
                    );
                })
            ) : null}
        </div>
    );
};

export default RightColumnCard;

function AddonSkeleton() {
    return (
        <div
            className="addon pr35 pl23 pt23 pb20 addon__Skeleton"
            style={{
                height: "168px",
                backgroundColor: "#fff",
            }}
        >
            {/* <h1 className="addon_text fontColor_dark fontSize_20">title</h1> */}
            <Skeleton
                variant="text"
                width="80%"
                // height="40px"
                className="addon_text fontColor_dark fontSize_20"
            />

            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    width: "70%",
                }}
            >
                <Skeleton
                    variant="rectangular"
                    width="150px"
                    height="40px"
                    className="addon_button"
                />
            </div>
            <Skeleton
                variant="rectangular"
                width="70px"
                height="70px"
                className="addon_background"
            />
            <div className="addon_partner">
                <Skeleton
                    variant="rectangular"
                    className="addon_partner_image"
                />
            </div>
        </div>
    );
}
