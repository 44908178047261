import { Link, useLocation } from "react-router-dom";
import {
    Badge,
    Box,
    Divider,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import iconCreator from "../iconCreator";
import Iconify from "../../iconify/iconify";
import { useStateValue } from "../../../util/stateProvider";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
const UserIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/user.png";

const SideNavbar: React.FC<{ scrolled: boolean }> = ({ scrolled }) => {
    const location = useLocation();
    const pathname = window.location.pathname;
    const [{ user, leadInfo }] = useStateValue();

    const healthCareBenefits = useSelector(
        (state: RootState) => state.home?.homeDtoV2?.healthCare
    );
    const { homeDtoV2 } = useSelector((state: RootState) => state.home);

    const theme = useTheme();

    return (
        <Box
            sx={{
                minWidth: "189px",
                height: "100vh",
                // position: "sticky",
                top: "0",
                left: 0,
                display: "flex",
                flexDirection: "column",
                // gap: "36px",
                backgroundColor: "#fff",
                zIndex: "100",
                "@media (max-width: 1165px)": {
                    display: "none",
                },
            }}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-evenly"}
                gap={1}
                height={64}
                sx={{
                    padding: "24px 20px",
                    borderRight:
                        (pathname !== "/home" && pathname !== "/") || scrolled
                            ? `1px solid var(--Components-Outline, #ECEFFF)`
                            : "none",
                    borderBottom: `1px solid var(--Components-Outline, #ECEFFF)`,
                }}
            >
                {(pathname !== "/home" && pathname !== "/") || scrolled ? (
                    <>
                        <Box
                            component={Link}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "92px",
                                height: "40px",
                                alignItems: "center",
                            }}
                            to={"/home"}
                        >
                            {leadInfo?.agentLogo &&
                                ((pathname !== "/home" && pathname !== "/") ||
                                    scrolled) && (
                                    <img
                                        src={leadInfo?.agentLogo}
                                        alt="Broker logo"
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                        }}
                                    />
                                )}
                        </Box>
                        <div
                            style={{
                                width: 0,
                                height: "40px",
                                borderRight: `1px dashed ${theme.palette.divider}`,
                            }}
                        ></div>
                        <Box
                            width={40}
                            height={40}
                            sx={{
                                borderRadius: "20px",
                                border: "1px solid #FFF",
                                background: "rgba(255, 255, 255, 0.70)",
                                boxShadow:
                                    "0px 2px 6px 0px rgba(39, 57, 147, 0.06)",
                                cursor: "pointer",
                                padding: 1,
                            }}
                            component={Link}
                            to={"/profile"}
                        >
                            {((pathname !== "/home" && pathname !== "/") ||
                                scrolled) && (
                                <img
                                    src={UserIcon}
                                    alt={"Profile"}
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                    }}
                                />
                            )}
                        </Box>
                    </>
                ) : (
                    <Box height={40}></Box>
                )}
            </Stack>
            <Stack
                gap={"36px"}
                sx={{
                    padding: "24px 20px",
                    borderRight: `1px solid var(--Components-Outline, #ECEFFF)`,
                    height: "100%",
                }}
            >
                <Box
                    position={"relative"}
                    alignSelf={"center"}
                    padding={"8px 8px 8px 4px "}
                    minHeight={"40px"}
                >
                    <Link
                        to="/home"
                        className={
                            location.pathname === "/" ||
                            location.pathname === "/home"
                                ? "emp-nav-link active-link"
                                : "emp-nav-link"
                        }
                    >
                        <Iconify
                            icon={
                                location.pathname === "/" ||
                                location.pathname === "/home"
                                    ? "solar:home-smile-bold"
                                    : "solar:home-smile-outline"
                            }
                            height={20}
                            sx={{
                                ml: "5px",
                                mr: "5px",
                                color:
                                    location.pathname === "/" ||
                                    location.pathname === "/home"
                                        ? "$primary"
                                        : "#0C1230",
                            }}
                        />
                        <Typography
                            variant="navbutton"
                            color={
                                location.pathname === "/home"
                                    ? "$primary"
                                    : "#0C1230"
                            }
                        >
                            Home
                        </Typography>
                    </Link>
                </Box>
                <Box
                    position={"relative"}
                    alignSelf={"center"}
                    padding={"8px 8px 8px 4px "}
                    minHeight={"40px"}
                >
                    <Link
                        to="/plans"
                        className={
                            location.pathname.startsWith("/plans")
                                ? "emp-nav-link active-link"
                                : "emp-nav-link"
                        }
                    >
                        <Iconify
                            icon={`solar:shield-check-${
                                location.pathname.startsWith("/plans")
                                    ? "bold"
                                    : "outline"
                            }`}
                            height={20}
                            sx={{
                                ml: "5px",
                                mr: "5px",
                                color: location.pathname.startsWith("/plans")
                                    ? "$primary"
                                    : "#0C1230",
                            }}
                        />
                        <Typography
                            variant="navbutton"
                            color={
                                location.pathname.startsWith("/plans")
                                    ? "$primary"
                                    : "#0C1230"
                            }
                        >
                            Policies
                        </Typography>
                    </Link>
                </Box>

                <Box
                    position={"relative"}
                    alignSelf={"center"}
                    padding={"8px 8px 8px 4px "}
                    minHeight={"40px"}
                >
                    <Badge
                        badgeContent={
                            homeDtoV2?.d_wallet_balance
                                ? "₹" +
                                  homeDtoV2?.d_wallet_balance?.toLocaleString(
                                      "en-IN"
                                  )
                                : null
                        }
                        sx={{
                            "& .MuiBadge-badge": {
                                color: "white",
                                backgroundColor: theme.palette.brand.main,
                                padding: "0 8px",
                                height: "16px",
                                right: "55px",
                                top: "-10px",
                            },
                        }}
                    >
                        <Link
                            to="/benefits"
                            className={
                                location.pathname.startsWith("/benefits")
                                    ? "emp-nav-link active-link"
                                    : "emp-nav-link"
                            }
                        >
                            <Iconify
                                icon={`solar:heart-pulse-${
                                    location.pathname.startsWith("/benefits")
                                        ? "bold"
                                        : "outline"
                                }`}
                                height={20}
                                sx={{
                                    ml: "5px",
                                    mr: "5px",
                                    color: location.pathname.startsWith(
                                        "/benefits"
                                    )
                                        ? "$primary"
                                        : "#0C1230",
                                }}
                            />
                            <Typography
                                variant="navbutton"
                                className="benefit_wallet"
                                color={
                                    location.pathname.startsWith("/benefits")
                                        ? "$primary"
                                        : "#0C1230"
                                }
                            >
                                Benefits
                            </Typography>
                        </Link>
                    </Badge>
                </Box>
                <Box
                    position={"relative"}
                    alignSelf={"center"}
                    padding={"8px 8px 8px 4px "}
                    minHeight={"40px"}
                >
                    <Link
                        to="/claims"
                        className={
                            location.pathname.startsWith("/claims")
                                ? "emp-nav-link active-link"
                                : "emp-nav-link"
                        }
                    >
                        <Iconify
                            icon={`solar:document-${
                                location.pathname.startsWith("/claims")
                                    ? "bold"
                                    : "outline"
                            }`}
                            height={20}
                            sx={{
                                ml: "5px",
                                mr: "5px",
                                color: location.pathname.startsWith("/claims")
                                    ? "$primary"
                                    : "#0C1230",
                            }}
                        />
                        <Typography
                            variant="navbutton"
                            color={
                                location?.pathname.startsWith("/claims")
                                    ? "$primary"
                                    : "#0C1230"
                            }
                        >
                            Claims
                        </Typography>
                    </Link>
                </Box>
            </Stack>
        </Box>
    );
};

export default SideNavbar;
