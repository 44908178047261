import { alpha, createTheme } from "@mui/material/styles";
import { useStateValue } from "./stateProvider";
import { switchClasses, SwitchProps } from "@mui/material";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        body3: React.CSSProperties;
        navbutton: React.CSSProperties;
        label: React.CSSProperties;
    }
    interface TypeText {
        dark: string;
        accent: string;
    }

    interface Palette {
        text: TypeText;
    }

    interface PaletteOptions {
        text?: Partial<TypeText>;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        body3?: React.CSSProperties;
        navbutton?: React.CSSProperties;
        label?: React.CSSProperties;
        mobileLabel?: React.CSSProperties;
    }
    interface Palette {
        neutral: {
            main: string;
        };
        brand: {
            main: string;
        };
        purple: Palette["primary"];
        cream: Palette["primary"];
        basic: {
            white: string;
            black: string;
            dark: string;
        };
    }

    interface PaletteOptions {
        neutral?: {
            main: string;
        };
        brand?: {
            main: string;
        };
        purple?: PaletteOptions["primary"];
        cream?: PaletteOptions["primary"];
        basic?: {
            white: string;
            black: string;
            dark: string;
        };
    }
    interface PaletteColor {
        darker?: string;
        lighter?: string;
    }

    interface SimplePaletteColorOptions {
        darker?: string;
        lighter?: string;
    }
}

declare module "@mui/material/Divider" {
    interface DividerPropsVariantOverrides {
        dashed: true;
        dashedMiddle: true;
        dashedInset: true;
    }
}

declare module "@mui/material/BottomNavigationAction" {
    interface BottomNavigationActionPropsVariantOverrides {
        Actionrow: true;
    }
}

declare module "@mui/material/IconButton" {
    interface IconButtonPropsSizeOverrides {
        xlarge: true;
    }
}

// OverridesStyleRules to allow custom props in MuiDivider
declare module "@mui/material/styles" {
    interface OverridesStyleRules {
        dashed: true;
        dashedMiddle: true;
        dashedInset: true;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        body3: true;
        navbutton: true;
        label: true;
        mobileLabel: true;
    }
}
const baseTheme = createTheme();

const useTheme = () => {
    const [{ subDomainInfo }] = useStateValue();

    const rootStyles = (ownerState: SwitchProps) => ({
        padding: "9px 13px 9px 12px",
        width: 58,
        height: 38,
        ...(ownerState.size === "small" && {
            padding: "4px 8px 4px 7px",
            width: 40,
            height: 24,
        }),
        [`& .${switchClasses.thumb}`]: {
            width: 14,
            height: 14,
            boxShadow: "none",
            color: theme.palette.common.white,
            ...(ownerState.size === "small" && {
                width: 10,
                height: 10,
            }),
        },
        [`& .${switchClasses.track}`]: {
            opacity: 1,
            borderRadius: 14,
            backgroundColor: alpha(theme.palette.grey[500], 0.48),
        },
        [`& .${switchClasses.switchBase}`]: {
            left: 3,
            padding: 12,
            ...(ownerState.size === "small" && {
                padding: 7,
            }),
            [`&.${switchClasses.checked}`]: {
                transform: "translateX(13px)",
                [`&+.${switchClasses.track}`]: {
                    opacity: 1,
                },
                ...(ownerState.size === "small" && {
                    transform: "translateX(9px)",
                }),
            },
            [`&.${switchClasses.disabled}`]: {
                [`& .${switchClasses.thumb}`]: {
                    opacity: 1,
                },
                [`&+.${switchClasses.track}`]: {
                    opacity: 0.48,
                },
            },
        },
    });

    const theme = createTheme({
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: "outlined",
                },
                styleOverrides: {
                    root: {
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "16px",
                            "& fieldset": {
                                borderColor: "#7F85A3",
                            },
                            // '&:hover fieldset': {
                            //     borderColor: '#464D6A',
                            // },
                            // '&.Mui-focused fieldset': {
                            //     borderColor: '#464D6A',
                            // },
                        },
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: ({ ownerState }: { ownerState: SwitchProps }) =>
                        rootStyles(ownerState),
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: "outlined",
                },
                styleOverrides: {
                    root: {
                        borderRadius: "16px",
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#7F85A3",
                            },
                        },
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        borderRadius: "16px",
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        // Map the new variant to render a <h1> by default
                        body3: "h1",
                        navbutton: "h1",
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        color: "#000",
                        borderColor: "#000",
                    },
                },
                variants: [
                    {
                        props: { variant: "dashed" },
                        style: {
                            borderWidth: "0px",
                            borderStyle: "dashed",
                            borderBottomWidth: "thin",
                            borderColor: "#6F6B7A",
                            opacity: 0.2,
                            width: "100%",
                        },
                    },
                    {
                        props: { variant: "dashedMiddle" },
                        style: {
                            borderWidth: "0px",
                            borderStyle: "dashed",
                            borderBottomWidth: "thin",
                            marginLeft: "16px",
                            marginRight: "16px",
                        },
                    },
                    {
                        props: { variant: "dashedInset" },
                        style: {
                            borderWidth: "0px",
                            borderStyle: "dashed",
                            borderBottomWidth: "thin",
                            marginLeft: "72px",
                        },
                    },
                ],
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: "8px",
                        borderRadius: "100%",
                        borderColor: "#000",
                    },
                },
                variants: [
                    {
                        props: { size: "small" },
                        style: {
                            padding: "10px",
                            height: "28px",
                            width: "28px",
                        },
                    },
                    {
                        props: { size: "large" },
                        style: {
                            padding: "16px",
                            height: "40px",
                            width: "40px",
                        },
                    },
                    {
                        props: { size: "medium" },
                        style: {
                            padding: "16px",
                            height: "36px",
                            width: "36px",
                        },
                    },
                    {
                        props: { size: "xlarge" },
                        style: {
                            padding: "24px",
                            height: "48px",
                            width: "48px",
                        },
                    },
                ],
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    // root: {
                    //     // flex to diection column
                    //     flexDirection: "row",
                    //     padding: "8px",
                    // },
                    label: {
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "18px",
                    },
                },
            },
            MuiIcon: {
                styleOverrides: {
                    root: {
                        fontSize: "24px",
                    },
                    fontSizeLarge: {
                        fontSize: "32px",
                    },
                    fontSizeSmall: {
                        fontSize: "16px",
                    },
                },
            },

            MuiBadge: {
                styleOverrides: {
                    badge: {
                        height: "16px",
                        minWidth: "16px",
                        borderRadius: "8px",
                        fontSize: "10px",
                        padding: "1px 4px",
                    },
                    dot: {
                        width: "6px",
                        height: "6px",
                        minWidth: "6px",
                        borderRadius: "3px",
                        padding: "0",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: "none",
                        borderRadius: "500px",
                        textAlign: "left",
                        fontSize: "12px",
                        fontWeight: "600 !important",
                        boxShadow: "none",
                        textShadow: "none",
                        "&:hover": {
                            boxShadow: "none",
                        },
                    },
                    sizeSmall: {
                        padding: "12px 24px",

                        "&:has(.MuiButton-endIcon)": {
                            paddingRight: "20px",
                        },
                        "&:has(.MuiButton-startIcon)": {
                            paddingLeft: "20px",
                        },
                    },
                    sizeMedium: {
                        padding: "18px 38px",
                        "&:has(.MuiButton-endIcon)": {
                            paddingRight: "24px",
                        },
                        "&:has(.MuiButton-startIcon)": {
                            paddingLeft: "24px",
                        },
                    },
                    sizeLarge: {
                        padding: "28px 56px",
                        "&:has(.MuiButton-endIcon)": {
                            paddingRight: "48px",
                        },
                        "&:has(.MuiButton-startIcon)": {
                            paddingLeft: "48px",
                        },
                    },
                    outlined: {
                        border: "1px solid  #EAEAEF",
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        borderRadius: "24px",
                    },
                    sizeSmall: {
                        gap: "1px",
                    },
                    sizeMedium: {
                        gap: "4px",
                    },
                    label: {
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "0px 8px",
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        borderRadius: "12px",
                        border: "1px solid rgba(217, 215, 224, 0.60)",
                        boxShadow: "none",
                    },
                },
            },
        },
        palette: {
            neutral: {
                main: "#d6d6d6",
            },
            primary: {
                main: "#464D6A",
            },
            text: {
                primary: "#3E3C47",
                secondary: "#6F6B7A",
                disabled: "#BEBBC6",
                accent: subDomainInfo?.theme?.primary || "#0C1230",
                dark: "#111014",
            },
            error: {
                main: "#FF5A65",
                darker: "#8F0E0E",
                lighter: "#FFEAEB",
                light: "#FFACB2",
                dark: "#DC2B2B",
            },
            brand: {
                main: subDomainInfo?.theme?.primary || "#896BFF",
            },
            grey: {
                100: "#F9F9FB",
                200: "#F8F7FA",
                300: "#EAEAEF",
                400: "#D9D7E0",
                500: "#BEBBC6",
                600: "#888594",
                700: "#6F6B7A",
                800: "#3E3C47",
                900: "#111014",
            },
            purple: {
                main: "#896BFF",
                darker: "#3D2192",
                lighter: "#F0ECFF",
                light: "#C4B5FF",
                dark: "#663AE7",
            },
            success: {
                main: "#05C168",
                lighter: "#DEF7EB",
                darker: "#076443",
                light: "#82E0B3",
                dark: "#11845B",
            },
            info: {
                main: "#1D88FE",
                lighter: "#E2F0FF",
                darker: "#034995",
                light: "#8EC3FF",
                dark: "#086CD9",
            },
            warning: {
                main: "#D5691B",
                lighter: "#FFF2E4",
                darker: "#93450C",
                light: "#FFCF95",
                dark: "#D5691B",
            },
            cream: {
                main: "#E8AD8C",
                darker: "#64410D",
                lighter: "#FFF4EE",
                light: "#FFE3D3",
                dark: "#9A5D01",
            },
            basic: {
                white: "#FFFFFF",
                black: "#000000",
                dark: "#27266A",
            },
        },
        typography: {
            fontFamily: "Poppins, Arial, sans-serif",
            h1: {
                fontFamily: "Poppins",
                fontSize: 64,
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "80px",
                margin: 0,
                letterSpacing: 0,
                color: "#0C1230",
            },

            h2: {
                fontFamily: "Poppins",
                fontSize: 48,
                fontStyle: "normal",
                fontWeight: 700,
                margin: 0,
                letterSpacing: 0,
                lineHeight: "64px",
                color: "#0C1230",
            },

            h3: {
                fontFamily: "Poppins",
                fontSize: "32px",
                fontStyle: "normal",
                letterSpacing: 0,
                fontWeight: 600,
                margin: 0,

                lineHeight: "48px" /* 150% */,
                //Title, Salutation//
                color: "#0C1230",
            },

            h4: {
                fontFamily: "Poppins",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "36px" /* 150% */,
                letterSpacing: "-0.48px",
                // marginBottom: 0,
                margin: 0,

                color: "#0C1230",

                //Page headings, Desktop Policy card headings //
            },

            h5: {
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "30px" /* 150% */,
                // marginBottom: 0,
                margin: 0,
                letterSpacing: 0,

                color: "#0C1230",

                // //
            },

            h6: {
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
                letterSpacing: 0,
                margin: 0,

                lineHeight: "28px" /* 155.556% */,
                color: "#0C1230",

                // //
            },

            subtitle1: {
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                margin: 0,

                lineHeight: "24px" /* 150% */,
                color: "#0C1230",

                letterSpacing: -0.16,
            },

            subtitle2: {
                fontFamily: "Poppins",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 600,
                color: "#0C1230",
                margin: 0,
                letterSpacing: 0,
                lineHeight: "22px" /* 157.143% */,
                // Medium/Large button text, Subtitles, Active Nav button //
            },

            body1: {
                fontFamily: "Poppins",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px" /* 150% */,
                color: "#464D6A",
                letterSpacing: 0,
                margin: 0,
                //  //
            },

            body2: {
                fontFamily: "Poppins",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 400,
                color: "#464D6A",
                letterSpacing: 0,
                margin: 0,
                lineHeight: "22px" /* 157.143% */,
                //Card content, paragraph //
            },

            body3: {
                fontFamily: "Poppins",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                color: "#464D6A",
                margin: 0,

                lineHeight: "18px" /* 150% */,
                letterSpacing: -0.12,
                //Small button text, Highlighting, Section heading//
            },

            overline: {
                fontFamily: "Poppins",
                fontSize: 12,
                fontStyle: "normal",
                margin: 0,
                fontWeight: 600,
                color: "#0C1230",
                textTransform: "none",
                letterSpacing: 0,
                lineHeight: "18px" /* 150% */,
                //Small button text, Highlighting, Section heading//
            },

            navbutton: {
                fontFamily: "Poppins",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 500,
                margin: 0,
                color: "var(--primary)",
                letterSpacing: 0,
                lineHeight: "22px" /* 157.143% */,
                // margin: undefined,
                //Speacial style to be used as default state of navigation //
            },
            label: {
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                color: "var(--primary)",
                margin: 0,
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: 0.18,
            },
            mobileLabel: {
                fontFamily: "Poppins",
                fontSize: "10px",
                fontStyle: "normal",
                color: "var(--primary)",
                margin: 0,
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: 0.2,
            },
        },
    });


    const oldTheme = createTheme({
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: "outlined",
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: "outlined",
                },
            },
        },
        palette: {
            neutral: {
                main: "#d6d6d6",
            },
            primary: {
                main: subDomainInfo?.theme?.primary || "#4f3dd1",
            },
            error: {
                main: "#e94e4e",
            },
        },
        typography: {
            fontFamily: "Poppins, Arial, sans-serif",
        },
    });

    if(JSON.parse(sessionStorage.getItem("user") || "{}")?.userType !== "EMPLOYEE") {
        return oldTheme
    }
    return theme;
};

export default useTheme;
