import service from "./axiosConfig";
import alert from "./alerts";

const conf = {
    url: "test.com",
    method: "POST",
    sucess: "popup, toast",
    failure: "popup, toast",
    processing: "toast, setLoader",
    messageSucess: "message",
    messageFail: "messageFai",
    messageProcessing: "messageProcessing",
    notes: "note",
    setLoader: (val) => {},
};

const extractMessage = (message, payload, response = {}) => {
    if (!message || message === "") return "";
    console.log(message);
    console.log("called");
    let newMessage = "";
    console.log(typeof message);
    for (let i = 0; i < message.length; i++) {
        if (message[i] !== "#") {
            newMessage += message[i];
        } else {
            let j = i;
            let variable = "";
            let field = "";
            while (message[j + 1] !== ".") {
                variable += message[j + 1];
                j++;
            }
            //j++; //cond
            while (j + 1 !== message.length && message[j + 1] !== " ") {
                field += message[j + 1];
                j++;
            }
            i = j;
            console.log(variable);
            //newMessage += (variable === 'res') ? response[field] : request[field];
            let value;
            try {
                value =
                    variable === "res"
                        ? eval(`response${field}`)
                        : eval(`payload${field}`);
            } catch (e) {
                value = "";
            }

            newMessage += value !== undefined ? value : "";
            console.log(field);
        }
    }
    message = newMessage;
    console.log(message);
    return message;
};

const getFile = (config, callback) => {
    // const loader = alert(
    //     config.messageProcessing || "Downloading File ",
    //     "loading"
    // );
    service.getFile(config.url, null, null, (success) => {
        if (success) {
            alert(
                config.messageSuccess || "File Download started",
                "success",
                3000
                // loader
            );
            callback && callback();
        } else {
            alert(
                config.messageFail || "Error Downloading file",
                "error",
                3000
                // loader
            );
        }
    });
};

const callService = async (
    config,
    type,
    payload,
    callback = () => {},
    headers
) => {
    let loader;

    //default messages
    if (config.messageSuccess === "")
        config.messageSuccess = "#res.data.message";
    if (config.messageFail === "") config.messageFail = "#res.data.message";

    config.messageProcessing = extractMessage(
        config.messageProcessing,
        payload,
        {}
    );
    console.log(config.messageProccessing);
    if (config.processing === "toast") {
        loader = alert(config.messageProcessing || "Processing ", "loading");
    } else if (config.processing === "popup") {
        if (config.setLoader) {
            config?.setLoader({
                state: "loading",
                component: config.messageProcessing,
            });
        }
    }
    let response;
    try {
        response = await service[type](config.url, payload, headers);
        if (response.data.success) {
            console.log("success");
            config.messageSuccess = extractMessage(
                config.messageSuccess,
                payload,
                response
            );
            if (config.success === "toast") {
                console.log("s1");
                if (config.processing === "toast") {
                    alert(
                        config.messageSuccess || response.data.message,
                        "loadingSuccess",
                        3000,
                        loader
                    );
                } else {
                    alert(
                        config.messageSuccess || response.data.message,
                        "success"
                    );
                }
                config.setLoader && config.setLoader(null);
                alert("", "dismiss", 3000);
            } else if (config.success === "popup") {
                console.log("s2");
                alert("", "dismiss", 3000);
                config.setLoader({
                    state: "success",
                    component: config.messageSuccess,
                });
            }
            if (config.success === "popup" && config.failure === "popup") {
                setTimeout(() => callback(response), 3000);
            } else callback(response);
        } else {
            console.log("error");
            config.messageFail = extractMessage(
                config.messageFail,
                payload,
                response
            );
            console.log(config.messageFail);

            console.log("error");
            if (config.failure === "toast") {
                console.log("e1");
                if (config.processing === "toast") {
                    alert(
                        config.messageFail || response.data.message,
                        "loadingError",
                        3000,
                        loader
                    );
                } else {
                    alert(config.messageFail || response.data.message, "error");
                }
                config.setLoader && config.setLoader(null);
                alert("", "dismiss", 3000);
            } else if (config.failure === "popup") {
                console.log("e2");
                alert("", "dismiss", 3000);
                config.setLoader({
                    state: "error",
                    component: config.messageFail,
                });
            }
            if (config.success === "popup" && config.failure === "popup") {
                setTimeout(() => callback(response), 3000);
            } else callback(response);
        }
    } catch (err) {
        console.log(err);
        if (config.failure === "toast") {
            console.log("se1");
            if (config.processing === "toast") {
                alert(
                    response?.response?.data?.message || "Server Error",
                    "loadingError",
                    3000,
                    loader
                );
            } else {
                alert(
                    response?.response?.data?.message || "Server Error",
                    "loadingError",
                    3000
                );
            }
            config.setLoader && config.setLoader(null);
            alert("", "dismiss", 3000);
        } else if (config.failure === "popup") {
            console.log("se2");
            alert("", "dismiss", 3000);
            config.setLoader({ state: "error", component: "Server Error" });
        }
        callback(response);
    }
    // config.closeModal&&config.closeModal(false)
    if (!config.success) {
        config.setLoader && config.setLoader({ state: "", component: "" });
    } else {
        config.setLoader &&
            setTimeout(
                () => config.setLoader({ state: "", component: "" }),
                3000
            );
    }
};

const advService = (config, payload, callback, headers = {}) => {
    switch (config.method) {
        case "GET":
            callService(config, "get", payload, callback, headers);
            break;
        case "POST":
            callService(config, "post", payload, callback, headers);
            break;
        case "POST_FILE":
            callService(config, "postUpload", payload, callback, headers);
            break;
        case "PUT":
            callService(config, "put", payload, callback, headers);
            break;
        case "PUT_MULTIPART":
            callService(config, "putMultipart", payload, callback, headers);
            break;
        case "DELETE":
            callService(config, "delete", payload, callback, headers);
            break;
        case "GETFILE":
            getFile(config, "getFile", callback);
            break;
        default:
            break;
    }
};

export default advService;
