import React from "react";
import Button from "../../components/Layout/Button";
// import { HighlightOff } from '@mui/icons-material';
import ModalContainer from "../../components/Modals/GenericModalContainer";

const ConfirmationModal = ({
    handleConfirmation,
    close,
    name,
    text,
    confType,
    loader = false,
}) => {
    return (
        <ModalContainer close={close} headingText={text} loader={loader}>
            {confType === "closable-popup" ? (
                <div
                    className="buttons confirmation-btns mt-5"
                    style={{ justifyContent: "flex-end" }}
                >
                    <Button
                        loading={0}
                        className="btn simple"
                        type="submit"
                        onClick={close}
                    >
                        Close
                    </Button>
                </div>
            ) : (
                <div className="buttons confirmation-btns mt-5">
                    <Button
                        loading={0}
                        className="btn simple"
                        type="submit"
                        onClick={close}
                    >
                        No
                    </Button>
                    <Button
                        loading={0}
                        className="btn simple primary"
                        type="submit"
                        onClick={() => handleConfirmation(true)}
                    >
                        Yes
                    </Button>
                </div>
            )}
        </ModalContainer>
    );
};

export default ConfirmationModal;
