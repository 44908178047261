import { saveAs } from "file-saver";
import { checkIphone } from "./checkIphone";
import generateContentType from "./generateContentType";

export default function downloadBlobIphone(file: Blob, filename: string) {
    try {
        const newBlob = new Blob([file], {
            type: generateContentType(filename),
        });
        var reader = new FileReader();
        reader.onload = function () {
            var link = document.createElement("a");
            link.setAttribute("href", reader.result?.toString() || "");
            link.setAttribute("download", filename);

            var fragment = document.createDocumentFragment();
            fragment.appendChild(link);

            // function appendFragmentAndClick() {
            //     window.document.body.appendChild(fragment);
            //     link.click();
            // }
            if (checkIphone()) {
                // window.document.body.appendChild(link);
                // link.click();

                var userAgent = window.navigator.userAgent.toLowerCase(),
                    safari = /safari/.test(userAgent),
                    ios = /iphone|ipod|ipad/.test(userAgent);

                if (ios) {
                    if (safari) {
                        //browser
                        // window.document.body.appendChild(link);
                        // window.document.body.appendChild(fragment);
                        link.click();
                    } else if (!safari) {
                        //webview
                        link.href = link.href + "_____" + filename;
                        // window.document.body.appendChild(fragment);
                        link.click();
                    }
                } else {
                    //not iOS
                    // window.document.body.appendChild(link);
                    link.click();
                }
            } else {
                // if (window.navigator.userAgent === "androidWebView") {
                //     alert(link.href[link.href.length - 1] + link.href[link.href.length - 2])
                //     link.href = link.href + "fileName" + filename;
                // }
                // alert(window.navigator.userAgent);
                // window.document.body.appendChild(link);
                link.click();
            }
        };
        reader.readAsDataURL(newBlob);
    } catch (e) {
        alert("Error in downloading file" + e);
    }
}

export function downloadBlobIphone2(file: Blob, filename: string) {
    if (checkIphone()) {
        var reader = new FileReader();
        reader.onload = function () {
            var dataURL = reader.result;
            // @ts-ignore
            var newWindow = window.open(dataURL, "_blank");
            if (!newWindow) {
                alert("Please disable your popup blocker and try again");
            }
        };
        // Add an extension to the filename
        filename += ".pdf"; // Change this to the correct extension for your file
        reader.readAsDataURL(file);
    } else {
        downloadBlobIphone(file, filename);
    }
}
