import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import SuccessImg from "../../assets/images/icons/Check.png";
import ErrorImg from "../../assets/images/icons/Error.png";
import { Button, Divider } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import "./KmdPaymentComplete.scss";
import { useDispatch, useSelector } from "react-redux";
import advService from "../../util/advService";
import {
    KMD_PAYMENT_RETRY_POST,
    KMD_POLICY_DOWNLOAD_GET,
    NEW_KMD_POLICY_DOWNLOAD_GET,
} from "../../util/newconfig";
import { AxiosResponse } from "axios";
import downloadBlobIphone from "../../util/function/downloadBlobIphone";
import alert from "../../util/alerts";
import service from "../../util/axiosConfig";
import { RootState } from "../../store";
import { HOME_EMPLOYEE } from "../../util/config";
import { setHomeDto } from "../../features/home/homeSlice";
import { JavaResponse } from "../../types";

const iciciLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/icici.svg";

const KmdPaymentComplete: React.FC = () => {
    const { policyDto, homeDto } = useSelector(
        (state: RootState) => state.home
    );
    const searchParam = new URLSearchParams(window.location.search);
    const status = searchParam.get("status");
    const policyUuid =
        searchParam.get("policyUuid") || searchParam.get("policyuuid");
    const transactionId =
        searchParam.get("transactionId") || searchParam.get("transactionid");
    const policyType = searchParam.get("policyType");
    const policyNumber =
        searchParam.get("PolicyNumber") || searchParam.get("policyNumber");
    const uid = searchParam.get("uid");
    const navigate = useNavigate();
    const reducerDispatch = useDispatch();

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    useEffect(() => {
        console.log("status", status);
        console.log("policyUuid", policyUuid);
        console.log("transactionId", transactionId);
    }, [status, policyUuid, transactionId]);

    useEffect(() => {
        let user = JSON.parse(sessionStorage.getItem("user") || "");

        if (user) {
            (async () => {
                const url = `${HOME_EMPLOYEE}${
                    user.actualUserType !== "EMPLOYEE"
                        ? sessionStorage.getItem("uuid")
                        : ""
                }`;
                await service.get(url).then((res) => {
                    if (res?.data?.success) {
                        reducerDispatch(
                            setHomeDto({
                                ...res.data.response,
                            })
                        );
                    }
                });
            })();
        }
    }, []);

    const retryPayment = (transactionId: string) => {
        let config = { ...KMD_PAYMENT_RETRY_POST };
        config.url = config.url.replace("{transactionId}", transactionId);
        debugger;
        advService(
            config,
            {},
            (
                res: AxiosResponse<
                    JavaResponse<{
                        link: string;
                        type: string;
                    }>
                >
            ) => {
                if (res.data.success) {
                    window.location.href = res.data.response.link;
                }
            }
        );
    };

    return (
        <div className="kmdPaymentComplete pt32 pb32">
            <div className="kmdPaymentComplete_container p16">
                <img
                    className="kmdPaymentComplete_statusImage mb27 pt32"
                    src={status === "true" ? SuccessImg : ErrorImg}
                    alt=""
                />
                <h1 className="mb27">
                    <b>
                        {status === "true"
                            ? "Payment successful!"
                            : "Transaction failed!"}
                    </b>
                </h1>
                <img
                    className="kmdPaymentComplete_iciciLogo mb8"
                    src={iciciLogo}
                    alt=""
                />
                <p className="kmdPaymentComplete_DetailText mb8 ">
                    {status === "true"
                        ? policyType?.toUpperCase() === "GPA"
                            ? "You may download the certificate. Complete document will be issued in 3 working days."
                            : policyNumber
                            ? "Congratulations! You're now insured. Below is your Policy Number."
                            : "Policy is under process. Policy document will be sent to you within 48hrs on your registered email id."
                        : "Payment against product is failed. You can retry paying the amount."}
                </p>
                {status === "true" && policyType?.toUpperCase() !== "GPA" && (
                    <b>{policyNumber}</b>
                )}
                <Divider className="pt16 pb16" />
                {!(policyType?.toUpperCase() === "PI" && !policyNumber) && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (status === "true") {
                                let policy = policyDto.d_home_policies.find(
                                    (policy: any) => policy.uuid === policyUuid
                                );
                                if (policy?.selfPurchased) {
                                    service.getFile(
                                        NEW_KMD_POLICY_DOWNLOAD_GET.url
                                            .replace(
                                                "{policyUuid}",
                                                policyUuid || ""
                                            )
                                            .replace(
                                                "{pid}",
                                                policy?.pid || ""
                                            ),
                                        null,
                                        null,
                                        null,
                                        policyType?.toUpperCase() === "GPA"
                                            ? "Receipt.pdf"
                                            : "Policy.pdf"
                                    );
                                } else {
                                    service.getFile(
                                        KMD_POLICY_DOWNLOAD_GET.url
                                            .replace(
                                                "{policyUuid}",
                                                policyUuid || ""
                                            )
                                            .replace(
                                                "{policyType}",
                                                policyType?.toUpperCase() || ""
                                            ),
                                        null,
                                        null,
                                        null,
                                        policyType?.toUpperCase() === "GPA"
                                            ? "Receipt.pdf"
                                            : "Policy.pdf"
                                    );
                                }
                            } else {
                                if (transactionId) {
                                    retryPayment(transactionId);
                                } else {
                                    navigate(
                                        // @ts-ignore
                                        `/kmd/${policyType}/${homeDto?.kmd_associate_config_map?.[policyType]}?purchase=true`
                                    );
                                }
                            }
                        }}
                        size="large"
                    >
                        {status === "true" ? (
                            <div>
                                {policyType?.toUpperCase() === "GPA"
                                    ? "Download Payment Receipt"
                                    : "Download policy"}
                                &nbsp;
                                <DownloadForOfflineIcon />
                            </div>
                        ) : (
                            "Retry "
                        )}
                    </Button>
                )}

                {policyType === "PI" && !policyNumber && status === "false" && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (transactionId) {
                                retryPayment(transactionId);
                            } else {
                                navigate(
                                    // @ts-ignore
                                    `/kmd/GPI/${homeDto?.kmd_associate_config_map?.[policyType]}`
                                );
                            }
                        }}
                        size="large"
                    >
                        Retry
                    </Button>
                )}
                <Button
                    color="primary"
                    onClick={() => {
                        navigate("/home");
                    }}
                    size="large"
                >
                    Go to home
                </Button>
            </div>
        </div>
    );
};

export default KmdPaymentComplete;
