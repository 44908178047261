import { Form, Formik, Field } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Dropdown from "../../components/Input/Dropdown";
import MyText from "../../components/Input/MyText";
import Button from "../../components/Layout/Button";
import { useStateValue } from "../../util/stateProvider";
import {
    BusinessType,
    EmployeeType,
    EmployeeTypeMap,
    NewEstimateDTO,
} from "../../types/DTO/NewEstimateDTO";
import advService from "../../util/advService";
import { LEAD_ONBOARD_POST, REFERENCE_NAME_GET } from "../../util/newconfig";
import { useNavigate } from "react-router-dom";
import GenericModalContainer from "../../components/Modals/GenericModalContainer";
import { changeDateFormat } from "../../util/date";
import { Autocomplete, Switch, TextField } from "@mui/material";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../types";

interface Props {
    close: () => void;
}

const NewEstimateModal = ({ close }: Props) => {
    const [{ user }, dispatch] = useStateValue();
    const navigate = useNavigate();
    const [loader, setLoader] = useState({});
    const [refName, setRefName] = useState<string>("");
    const [clientList, setClientList] = useState([]);
    const [agentId, setAgentId] = useState<string>("");
    const [refNames, setRefNames] = useState<{ id: string; label: string }[]>(
        []
    );
    const [refNameError, setRefNameError] = useState<string>("");

    useEffect(() => {
        if (user.userType === "ADMIN") {
            let config = { ...REFERENCE_NAME_GET, setLoader: setLoader };
            advService(
                config,
                undefined,
                (
                    res: AxiosResponse<
                        JavaResponse<{
                            d_agent_names_map: { [key: string]: string };
                        }>
                    >
                ) => {
                    if (res.data.success) {
                        setRefNames(
                            Object.entries(
                                res.data.response.d_agent_names_map
                            ).map(([key, value]) => {
                                return {
                                    id: key,
                                    label: value,
                                };
                            })
                        );
                    }
                }
            );
        }
    }, []);

    const generateUniqueClientId = (clients: any): string => {
        const uniqueId = Math.random()
            .toString(36)
            .substring(2, 8)
            .toUpperCase(); // Generate a random number between 0 and 999999
        if (
            clients.some((user: any) => user.clientCode === uniqueId) ||
            typeof uniqueId !== "string"
        ) {
            return generateUniqueClientId(clients); // If the ID is not unique, call the function again
        }
        return uniqueId;
    };

    const handleSubmit = (values: NewEstimateDTO) => {
        if (
            JSON.parse(sessionStorage.getItem("user") || "{}").userType ===
                "ADMIN" &&
            (!refName || refName.trim() === "")
        ) {
            setRefNameError("Required");
            return;
        }
        advService(
            {
                ...LEAD_ONBOARD_POST,
                setLoader: setLoader,
            },
            {
                ...values,
                ...(JSON.parse(sessionStorage.getItem("user") || "{}")
                    ?.userType === "ADMIN" && { refName: refName }),
                agentId: agentId,
            },
            (res: any) => {
                if (res.data.success) {
                    dispatch({
                        type: "set-uuid",
                        uuid: res.data.response.lead.uuid,
                    });
                    navigate("/details");
                    close();
                }
            }
        );
    };

    const [fresh, setFresh] = useState<boolean>(true);
    const [businessType, setBusinessType] = useState<BusinessType>(
        BusinessType.Agriculture
    );
    const [employeeType, setEmployeeType] = useState<number>(
        EmployeeTypeMap[EmployeeType.Blue]
    );

    return (
        <GenericModalContainer
            close={close}
            headingText={<h1 className="m0 light">New Estimate</h1>}
            loader={loader}
        >
            {
                <Formik
                    initialValues={{
                        companyName: "",
                        address: "",
                        clientCode: generateUniqueClientId(clientList),
                        // ...(user.userType === "ADMIN" && {refName: ""}),
                        ...(!fresh && { dueDate: new Date() }),
                    }}
                    validationSchema={Yup.object({
                        companyName: Yup.string().required("Required"),
                        clientCode: Yup.string()
                            .test(
                                "ClientCode",
                                "Client Id Length should be less than 7",
                                (value: any) => value.length < 7
                            )
                            .required("Required"),
                        address: Yup.string().required("Required"),
                        ...(!fresh && {
                            dueDate: Yup.string().required("Required"),
                        }),
                        // ...(user.userType === "ADMIN" && {
                        //     refName: Yup.string().required("Required"),
                        // }),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit({
                            ...values,
                            businessType: businessType,
                            employeeType: employeeType,
                            fresh: fresh,
                            ...{
                                dueDate: !fresh
                                    ? (changeDateFormat(
                                          "javascriptDateObject",
                                          "yyyy-mm-dd",
                                          values.dueDate
                                      ) as Date)
                                    : undefined,
                            },
                        });
                    }}
                >
                    <Form className="agentModal">
                        <div className="elementContainer">
                            <MyText
                                label="Company Name"
                                name="companyName"
                                type="text"
                                onChange={undefined}
                            />
                            <MyText
                                label="Address"
                                name="address"
                                type="text"
                                onChange={undefined}
                            />
                            <MyText
                                label="Client Id"
                                name="clientCode"
                                type="text"
                                onChange={undefined}
                            />

                            <div className="custom-form-group">
                                <Dropdown
                                    title="Business Type"
                                    defaultVal={businessType}
                                    items={Object.entries(BusinessType)
                                        .sort()
                                        .map(([key, value]) => {
                                            return {
                                                id: value,
                                                name: value,
                                            };
                                        })}
                                    change={setBusinessType}
                                />
                            </div>

                            <div className="custom-form-group">
                                <Dropdown
                                    title="Employee Type"
                                    defaultVal={employeeType}
                                    items={Object.entries(EmployeeType)
                                        .sort()
                                        .map(([key, value]) => {
                                            return {
                                                id: EmployeeTypeMap[value],
                                                name: value,
                                            };
                                        })}
                                    change={setEmployeeType}
                                />
                            </div>

                            {/* create mui slider input inside custom-form-group */}
                            <div className="custom-form-group">
                                <label>Fresh</label>
                                <Switch
                                    checked={fresh}
                                    onChange={() => {
                                        setFresh(!fresh);
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            </div>

                            {JSON.parse(sessionStorage.getItem("user") || "{}")
                                ?.userType === "ADMIN" &&
                                refNames && (
                                    // <>yoo</>
                                    <Autocomplete
                                        id="refName"
                                        options={refNames}
                                        value={
                                            { id: agentId, label: refName } ||
                                            ""
                                        }
                                        onChange={(_, newValue) => {
                                            setRefName(newValue?.label || "");
                                            setAgentId(newValue?.id || "");
                                        }}
                                        inputValue={refName || ""}
                                        onInputChange={(_, newInputValue) => {
                                            setRefName(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Ref Name"
                                                // add helper text for error in red color
                                                error={
                                                    refNameError ? true : false
                                                }
                                                helperText={refNameError}
                                            />
                                        )}
                                    />
                                )}
                            {!fresh && (
                                <MyText
                                    label="Due Date"
                                    name="dueDate"
                                    type="date"
                                    onChange={undefined}
                                />
                            )}
                        </div>

                        <div className="buttons">
                            <Button
                                loading={0}
                                className="btn simple primary"
                                type="submit"
                                style={{ fontWeight: "600" }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Formik>
            }
        </GenericModalContainer>
    );
};

export default NewEstimateModal;
