import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import Iconify from "../components/iconify";

import "./alerts.scss";

const alert = (message, type, duration = 3000, alertObj) => {
    const settings = {
        position: "bottom-center",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        // theme: "colored",
    };

    const getIconAndBackground = (type) => {
        switch (type) {
            case "info":
                return {
                    icon: "material-symbols:info",
                    iconColor: "#1D88FE",
                    background: "#E2F0FF",
                };
            case "success":
                return {
                    icon: "material-symbols:check-circle",
                    background: "#DEF7EB",
                    iconColor: "#05C168",
                };
            case "warning":
                return {
                    icon: "material-symbols:warning-rounded",
                    background: "#FFF3E4",
                    iconColor: "#FF9E2C",
                };
            case "error":
                return {
                    icon: "material-symbols:cancel",
                    background: "#FFEAEB",
                    iconColor: "#FF5A65",
                };
            case "loading":
                return {
                    icon: null,
                    background: "#E2F0FF",
                    iconColor: "#1D88FE",
                };
            default:
                return {
                    icon: "material-symbols:info",
                    iconColor: "#1D88FE",
                    background: "#E2F0FF",
                };
        }
    };

    const SnackbarComponent = ({ message, type }) => {
        const { icon, background, iconColor } = getIconAndBackground(type);

        return (
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    padding: "4px 12px 4px 4px",
                    alignItems: "center",
                    gap: "12px",
                    borderRadius: "8px",
                    border: "1px solid #ECEFFF",
                    backgroundColor: "#FFF",
                    // boxShadow: "0px 8px 16px rgba(145, 158, 171, 0.16)",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "48px",
                        height: "48px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        flexShrink: 0,
                        backgroundColor: background,
                    }}
                >
                    {type === "loading" ? (
                        <CircularProgress size={24} />
                    ) : (
                        <Iconify
                            icon={icon}
                            color={iconColor}
                            width={24}
                            height={24}
                        />
                    )}
                </Box>

                <Typography
                    variant="overline"
                    sx={{
                        textTransform: "none",
                        lineHeight: 1.2,
                    }}
                    color={(theme) => theme.palette.text.primary}
                >
                    {message}
                </Typography>
            </Box>
        );
    };

    switch (type) {
        case "info":
            toast(
                <SnackbarComponent message={message} type={type} />,
                settings
            );
            break;
        case "success":
            toast(
                <SnackbarComponent message={message} type={type} />,
                settings
            );
            break;
        case "warning":
            toast(
                <SnackbarComponent message={message} type={type} />,
                settings
            );
            break;
        case "error":
            toast(
                <SnackbarComponent message={message} type={type} />,
                settings
            );
            break;
        case "loading":
            return toast(
                <SnackbarComponent message={message} type="loading" />,
                settings
            );
        case "loadingSuccess":
            toast.update(alertObj, {
                ...settings,
                render: <SnackbarComponent message={message} type="success" />,
                isLoading: false,
                autoClose: false,
            });
            setTimeout(() => toast.dismiss(alertObj), duration);
            break;
        case "loadingWarning":
            toast.update(alertObj, {
                ...settings,
                render: <SnackbarComponent message={message} type="warning" />,
                isLoading: false,
                autoClose: false,
            });
            setTimeout(() => toast.dismiss(alertObj), duration);
            break;
        case "loadingError":
            toast.update(alertObj, {
                ...settings,
                render: <SnackbarComponent message={message} type="error" />,
                isLoading: false,
                autoClose: false,
            });
            setTimeout(() => toast.dismiss(alertObj), duration);
            break;
        case "dismiss":
            setTimeout(() => toast.dismiss(alertObj), duration);
            break;
        default:
            toast(
                <SnackbarComponent message={message} type={type} />,
                settings
            );
    }
};

export default alert;
