import React, { useState, useRef, useEffect } from "react";

import "./style.scss";

const Otp = ({ onChange, error, ...props }) => {
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [currentFieldNo, setCurrentFieldNo] = useState(0);

    const handleChange = (event, id) => {
        let newOtp = [...otp];
        event.preventDefault();
        if (event.target.value) {
            for (let i = id, j = 0; i < otp.length; i++, j++) {
                newOtp[i] = event.target.value[j];
            }
            setCurrentFieldNo(
                Math.min(otp.length - 1, id + event.target.value.length)
            );
            inputRefs.current[
                Math.min(otp.length - 1, id + event.target.value.length)
            ]?.focus();
        } else {
            inputRefs.current[id]?.focus();
            newOtp[id] = "";
        }
        setOtp(newOtp);

        let flag = true;
        for (let i = 0; i < newOtp.length; i++) {
            if (newOtp[i] === "") {
                flag = false;
            }
        }
        if (flag) {
            onChange(newOtp.join(""));
        }
    };

    const handleFocus = (event, id) => {
        event.target.select();
    };

    const inputRefs = useRef([]);

    useEffect(() => {
        if (error) {
            setOtp(["", "", "", ""]);
        }
    }, [error]);

    return (
        <div className={`otpInputContainer ${props.className}`}>
            {[0, 1, 2, 3].map((id) => (
                <input
                    type="number"
                    value={otp[id]}
                    onChange={(event) => handleChange(event, id)}
                    className={`otp ${error ? "otpError" : ""} ${
                        currentFieldNo === id ? "otp-focused" : ""
                    }`}
                    onClick={() => setCurrentFieldNo(id)}
                    autoFocus={currentFieldNo === id}
                    onFocus={(event) => handleFocus(event, id)}
                    ref={(el) => (inputRefs.current[id] = el)}
                />
            ))}
        </div>
    );
};

export default Otp;
