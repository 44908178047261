import React, { useEffect, useState } from "react";
import KmdInfo from "./KmdInfo";
import KmdStep2 from "./steps/kmdStep2";
import KmdStep4 from "./steps/kmdStep4";
import { v4 as uuidv4 } from "uuid";

import {
    setKmdComment,
    setMasterUuid,
    setMemberInfo,
    setMembers,
    setPlan,
    setPlanList,
    setPolicyType,
    setSumInsured,
    setSumInsuredList,
    setKmdCommentList,
    setCkyc,
} from "../../features/kmd/kmdSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useParams } from "react-router-dom";
import KmdStep3 from "./steps/kmdStep3";
import KmdGpiStep2 from "./steps/kmdGpiStep2";
import KmdGpiStep4 from "./steps/kmdGpiStep4";

const Kmd: React.FC = () => {
    const { policyType, masterUuid } = useParams<{
        policyType: "GMC" | "GPA" | "GPI";
        masterUuid: string;
    }>();
    const { memberInfo, isKyc } = useSelector((state: RootState) => state.kmd);
    const logId = uuidv4();
    const reducerDispatch = useDispatch();

    const [currentStep, setCurrentStep] = useState(1);

    useEffect(() => {
        if (
            window.location.hostname !== "kmdastur.imt.care" &&
            window.location.hostname !== "insurance.kmdastur.com" &&
            window.location.hostname !== "kmddemo.imt.care"
        ) {
            window.location.href = "/";
        }
    }, []);

    useEffect(() => {
        reducerDispatch(setMasterUuid(masterUuid || null));
        return () => {
            reducerDispatch(setMembers([]));
            reducerDispatch(setPolicyType(null));
            reducerDispatch(setSumInsuredList([]));
            reducerDispatch(
                setMemberInfo({
                    name: "",
                    dob: "",
                    phone: "",
                    addressLine1: "",
                    addressLine2: "",
                    pincode: "",
                    nomineeName: "",
                    nomineeRelation: "",
                    addressUuid: "",
                    city: "",
                    nomineeDob: "",
                })
            );
            reducerDispatch(setSumInsured(null));
            reducerDispatch(setPlan(null));
            reducerDispatch(setPlanList([]));
            reducerDispatch(setKmdComment(""));
            reducerDispatch(setKmdCommentList([]));
            reducerDispatch(setCkyc(null));
        };
    }, []);

    useEffect(() => {
        if (policyType) {
            reducerDispatch(setPolicyType(policyType));
        }
    }, [policyType]);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    return (
        <div>
            {/* <h1 className="fontSize_32">Multi-Step Component</h1> */}
            <div>
                {currentStep === 1 && <KmdInfo submit={handleNextStep} />}
                {currentStep === 2 &&
                    (policyType === "GPI" ? (
                        <KmdGpiStep2
                            submit={(val: {}) => {
                                // reducerDispatch(setMemberInfo(val));
                                handleNextStep();
                                // setCurrentStep(4);
                            }}
                            setCurrentStep={setCurrentStep}
                        />
                    ) : (
                        <KmdStep2
                            submit={(val: {
                                // email: string;
                                name: string;
                                dob: string;
                                phone: string;
                                addressLine1: string;
                                addressLine2: string;
                                pincode: string;
                                nomineeName: string;
                                nomineeRelation: string;
                                addressUuid: string;
                                city: string;
                                nomineeDob: string;
                            }) => {
                                reducerDispatch(setMemberInfo(val));
                                if (isKyc) {
                                    handleNextStep();
                                } else {
                                    setCurrentStep(4);
                                }
                                // setCurrentStep(4);
                            }}
                            setCurrentStep={setCurrentStep}
                        />
                    ))}
                {currentStep === 3 && (
                    <KmdStep3 setCurrentStep={setCurrentStep} logId={logId} />
                )}
                {currentStep === 4 &&
                    (policyType === "GPI" ? (
                        <KmdGpiStep4
                            setCurrentStep={setCurrentStep}
                            logId={logId}
                        />
                    ) : (
                        <KmdStep4
                            setCurrentStep={setCurrentStep}
                            logId={logId}
                        />
                    ))}
                {/* Add more steps as needed */}
            </div>
        </div>
    );
};

export default Kmd;
