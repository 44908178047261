import { PolicyState } from "../../features/policy/policySlice";
import service from "../../util/axiosConfig";
import { BASE_URL } from "../../util/config";

export interface PolicyFeatures {
    label: string;
    heading: string;
    subheading: string;
    text: string;
    page: number;
    richText: boolean;
}

export const policyService = {
    employee: {
        getPolicyData: (user: { multipleLeads: Boolean }) => {
            return new Promise((resolve, reject) => {
                if (user) {
                    const url = `${BASE_URL}v1/policy/employee${
                        user.multipleLeads
                            ? "/" + sessionStorage.getItem("uuid")
                            : ""
                    }`;
                    service.get(url).then((res) => {
                        if (res?.data.success) {
                            resolve(res.data.response);
                            return;
                        }

                        reject(res.data.message);
                        throw new Error(res.data.message);
                    });
                } else {
                    reject("User not defined");
                }
            });
        },
    },
    getPolicyFeatures: (
        user: { userType: string },
        pid: string,
        uuid: string
    ): Promise<{ d_features: PolicyFeatures[] }> => {
        return new Promise((resolve, reject) => {
            if (user) {
                const url =
                    user.userType === "EMPLOYEE"
                        ? `${BASE_URL}v1/policy/employee/features/v3/${uuid}/${pid}`
                        : `${BASE_URL}v1/policy/features/v2/${pid}/${uuid}`;
                service.get(url).then((res) => {
                    if (res?.data.success) {
                        resolve(res.data.response);
                        return;
                    }
                    reject(res.data.message);
                    throw new Error(res.data.message);
                });
            } else {
                reject("User not defined");
            }
        });
    },
    getPolicyDetails: (
        pid: string,
        uuid: string
    ): Promise<PolicyState["policyDetailDto"]> => {
        return new Promise((resolve, reject) => {
            const url = `${BASE_URL}v1/employee/policy/v2/${uuid}/${pid}`;
            service.get(url).then((res) => {
                if (res?.data.success) {
                    resolve(res.data.response);
                    return;
                }
                reject(res.data.message);
                throw new Error(res.data.message);
            });
        });
    },
    getPolicyCards: (): Promise<PolicyState["policyCardDto"]> => {
        return new Promise((resolve, reject) => {
            const url = `${BASE_URL}v1/employee/policy/v2`;
            service.get(url).then((res) => {
                if (res?.data.success) {
                    resolve(res.data.response);
                    return;
                }
                reject(res.data.message);
                throw new Error(res.data.message);
            });
        });
    },
};
