import React from "react";
import GenericModalContainer from "../../components/Modals/GenericModalContainer";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import MyText from "../../components/Input/MyText";
import { FileDataType } from "../../types/DownloadCenter/FileDataType";

type Props = {
    opeEditModal: boolean;
    setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRow: FileDataType | null;
    handleEdit: (data: { fileName: string }) => void;
};

const EditDocumentModal = ({
    opeEditModal,
    setOpenEditModal,
    selectedRow,
    handleEdit,
}: Props) => {
    if (opeEditModal)
        return (
            <GenericModalContainer
                close={() => setOpenEditModal(false)}
                headingText={<h1 className="m0 light">Edit document</h1>}
                styles={{ minWidth: "50%" }}
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        fileName: selectedRow?.fileName || "",
                    }}
                    validationSchema={Yup.object({
                        fileName: Yup.string(),
                    })}
                    onSubmit={handleEdit}
                >
                    <Form className="memberModal">
                        <div className="elementContainer">
                            <MyText
                                label="Name"
                                name="fileName"
                                type="text"
                                placeholder="Name"
                                onChange={undefined}
                            />
                        </div>
                        <div className="buttons">
                            <button
                                className="buttonPrimary pr10 pl10 btn simple primary"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </Form>
                </Formik>
            </GenericModalContainer>
        );
    else return null;
};

export default EditDocumentModal;
