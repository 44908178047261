import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        console.log("scroll to top");
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    return null;
};

export default ScrollToTop;
