import React, { useState } from "react";
import Otp from "../../../components/Otp";
import "./kmdOtp.scss";
import { KmdOtpReqDto } from "../../../types/DTO/kmdDto";
import advService from "../../../util/advService";
import { KMD_OTP_POST } from "../../../util/newconfig";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../../types";
import { useInterval } from "../../UserAuthentication/Otp/CommonOtp";

interface Props {
    name: string;
    basicUserInfo: KmdOtpReqDto | null;
    handleSubmit: (otp: string) => void;
}

const KmdOtp: React.FC<Props> = ({ name, basicUserInfo, handleSubmit }) => {
    const [resendOtpAllowed, setResendOtpAllowed] = useState(false);
    const [resendSeconds, setResendSeconds] = useState(0);
    const [timer, setTimer] = useState(true);

    const [otp, setOtp] = React.useState<string | null>(null);
    const [error, setError] = React.useState<string | null>(null);

    const submit = () => {
        // console;
        if (otp?.length === 4) {
            const isNumeric = /^\d+$/.test(otp);
            if (isNumeric) {
                setError(null);
                handleSubmit(otp);
            } else {
                setError("Otp must be numeric");
            }
        } else {
            setError("Otp must be 4 characters long");
        }
    };
    useInterval(() => {
        if (!timer) return;
        if (resendSeconds === 30) {
            setResendSeconds(0);
            setResendOtpAllowed(true);
            setTimer(false);
        }
        setResendSeconds((i: number) => i + 1);
    }, 1000);

    const resendOtp = () => {
        setError(null);
        if (resendOtpAllowed) {
            advService(
                { ...KMD_OTP_POST },
                { registrationNumber: basicUserInfo?.registrationNumber },
                (res: AxiosResponse<JavaResponse<KmdOtpReqDto>>) => {
                    if (res.data.success) {
                        setTimer(true);
                        setResendOtpAllowed(false);
                    }
                }
            );
        }
    };

    return (
        <div className="kmdOtp">
            <div className="kmdOtp_container">
                <Otp onChange={setOtp} error={error} />
            </div>
            <button className="buttonPrimary mt20" onClick={submit}>
                Submit
            </button>
            {resendOtpAllowed ? (
                <div
                    onClick={resendOtp}
                    className="fontColor_dark fontWeight_500 mr ml mt20 pointer"
                >
                    Resend Otp
                </div>
            ) : (
                <div
                    style={{
                        textAlign: "center",
                        marginTop: "20px",
                    }}
                >
                    Resend Otp in 00:
                    {(String(30 - resendSeconds).length === 1 ? "0" : "") +
                        String(30 - resendSeconds)}
                </div>
            )}
        </div>
    );
};

export default KmdOtp;
