import NotFound from "../NotFound";

import { useStateValue } from "../../util/stateProvider";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import { getValidSessionItem } from "../../util/functions";

const AuthorizedRoute = ({
    authorized,
    migrate = false,
    notFoundText,
    ...props
}) => {
    //const allowed = ["employee", "employer", "admin", "broker"];
    //if (!authorized) return <Navigate to={redirectPath} />;
    const { policyDto } = useSelector((state) => state.home);
    const { homeDtoV2 } = useSelector((state) => state.home);
    const [{ user, uuid, config }] = useStateValue();
    const defaultRoutes = {
        ADMIN: "/leads",
        BROKER: "/leads",
        EMPLOYEE: "/home",
        EMPLOYER: "/home",
        USER: "/home",
        AGENT: "/home",
    };

    const expiredPolicyType = getValidSessionItem("expiredPolicy");
    const allowedInExpiredPolicy = [
        "/payments",
        "/members",
        "/claims",
        "/home",
        "/claim-report",
        "/app-usage-report",
        "/enrollment-report",
        "/emaildata-report",
    ];

    const screenCreator = () => {
        console.log("screen creator", user);
        let temp = sessionStorage.getItem("requestedSite");
        if (
            !localStorage.getItem("Authorization") &&
            !sessionStorage.getItem("Authorization")
        ) {
            sessionStorage.removeItem("uuid");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("leads");
            sessionStorage.removeItem("lead");
            sessionStorage.removeItem("verifyPhone");
            sessionStorage.removeItem("requestedSite");
            localStorage.removeItem("navBar");
            localStorage.removeItem("topBar");
            sessionStorage.removeItem("navBar");
            sessionStorage.removeItem("topBar");
            sessionStorage.setItem("requestedSite", window.location.pathname);
            return <NotFound status={401} redirectPath="/login" />;
            //return <Navigate to={"/login"} />;
        } else if (user && !authorized.includes(user?.userType.toLowerCase())) {
            sessionStorage.removeItem("requestedSite");
            return (
                <NotFound
                    status={403}
                    redirectPath={temp || defaultRoutes[user?.userType]}
                    notFoundText={notFoundText}
                />
            );
        } else if (
            user &&
            user.userType === "EMPLOYEE" &&
            homeDtoV2?.d_enrollment &&
            window.location.pathname !== "/enrollmentHome" &&
            window.location.pathname !== "/newEnrollment" &&
            window.location.pathname !== "/profile"
        ) {
            window.location.pathname = "/enrollmentHome";
            return (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70vh",
                    }}
                >
                    <CircularProgress color="neutral" />
                </Box>
            );
            // return <NotFound status={403} redirectPath={"/enrollmentHome"} />;
        } else if (user) {
            if (!expiredPolicyType) {
                return props.children;
            } else if (
                expiredPolicyType &&
                allowedInExpiredPolicy.includes(window.location.pathname)
            ) {
                return props.children;
            } else {
                return (
                    <NotFound
                        status={403}
                        redirectPath={temp || defaultRoutes[user?.userType]}
                        notFoundText={notFoundText}
                    />
                );
            }
        }
    };
    if (user?.isMigrated && migrate) {
        window.location.pathname = "/crm";
    } else if (
        (user?.userType === "EMPLOYEE" ||
            user?.userType === "EMPLOYER" ||
            user?.userType === "USER") &&
        uuid
            ? Array.isArray(policyDto?.d_home_policies) && true
            : true
    ) {
        return screenCreator();
    } else {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                }}
            >
                <CircularProgress color="neutral" />
            </Box>
        );
    }
};

export default AuthorizedRoute;
