import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./AddonSteps.scss";
import Steps from "../../../components/Steps/Steps";
import { stringParser } from "../../../util/functions";
import advService from "../../../util/advService";
import { ADDONS_REDIRECT_GET, ADDONS_POPUP_GET } from "../../../util/newconfig";
import { checkIphone } from "../../../util/function/checkIphone";
import openIphoneLink from "../../../util/function/openIphoneLink";
import Otp from "../../../components/Otp";
import {
    OTP_VERIFY_POST_WELLNESS,
    OTP_POST_WELLNESS,
} from "../../../util/newconfig";
import { Typography } from "antd";
import { TextField } from "@mui/material";
import { useStateValue } from "../../../util/stateProvider";
import { useInterval } from "../../UserAuthentication/Otp/CommonOtp";
const executiveIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/executive.svg";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    minWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    padding: 0,
    margin: 0,
};

function AddonSteps({ config, data, open, handleClose, phoneRequired }) {
    const [steps, setSteps] = useState(null);
    const [phoneVerified, setPhoneVerified] = useState(
        JSON.parse(sessionStorage.getItem("user")).phoneVerified || false
    );
    const [step, setStep] = useState("1");
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [inputCompleted, setInputCompleted] = useState(false);
    const [resendOtpAllowed, setResendOtpAllowed] = useState(false);
    const [resendSeconds, setResendSeconds] = useState(0);
    const [phoneError, setPhoneError] = useState(false);
    // const [redirectLink, setRedirectLink] = useState("");

    const [loader, setLoader] = useState({
        state: "",
        component: "",
    });
    const [{ Authorization }, dispatch] = useStateValue();

    const [phone, setPhone] = useState();
    const [timer, setTimer] = useState(true);

    useEffect(() => {
        setPhoneVerified(
            JSON.parse(sessionStorage.getItem("user")).phoneVerified
        );
    }, [JSON.parse(sessionStorage.getItem("user")).phoneVerified]);

    useInterval(() => {
        // console.log('setting',resendSeconds);
        if (!timer) return;
        if (resendSeconds === 30) {
            setResendSeconds(0);
            setResendOtpAllowed(true);
            setTimer(false);
        }
        setResendSeconds((i) => i + 1);
    }, 1000);

    const resendOtp = () => {
        setOtpError(false);
        if (resendOtpAllowed) {
            let config = { ...OTP_POST_WELLNESS };
            let payload = {
                username: phone,
                phone: true,
            };

            config.setLoader = setLoader;
            advService(config, payload, (_res) => {
                setTimer(true);
                setResendOtpAllowed(false);
            });
        }
    };

    useEffect(() => {
        if (inputCompleted) {
            let callConfig = { ...OTP_VERIFY_POST_WELLNESS };
            let payload = {
                username: phone,
                phone: true,
                otp: Number(otp),
            };
            callConfig.setLoader = setLoader;
            advService(callConfig, payload, (res) => {
                if (res.data.success) {
                    dispatch({
                        type: "set-authorization",
                        data: res?.headers?.authorization,
                    });
                    if (config?.popup) {
                        setPhoneVerified(true);
                    } else {
                        handleClose();
                        config?.cta();
                    }
                } else {
                    setOtpError(true);
                    setResendSeconds(0);
                    setTimer(false);
                    setResendOtpAllowed(true);
                }
            });
        }
        setInputCompleted(false);
    }, [inputCompleted, otp, setLoader]);

    useEffect(() => {
        if (data?.params) {
            let params = { ...data?.params };
            if (params.link) {
                params.link.url += config?.uid;
            }
            setSteps(
                data?.steps?.map((step, i) => {
                    return stringParser(step, params);
                })
            );
        }
    }, [data?.params]);

    // useEffect(() => {
    //     if(redirectLink){
           
    //         checkIphone()
    //             ?  window.location.href = redirectLink
    //             : window.open(redirectLink, "_blank");

    //         setRedirectLink("");
    //     }
    // }, [redirectLink]);

    const handleCtxClick = () => {
        // console.log(config.params.button.action)
        let urlConfig = config.isEmployee
            ? { ...ADDONS_REDIRECT_GET }
            : { ...ADDONS_POPUP_GET };
        if (!config.isEmployee) {
            urlConfig.url +=
                JSON.parse(sessionStorage.getItem("user")).uuid + "/";
        }
        urlConfig.url += data?.uid;
        // let windowReference;
        // if (checkIphone()) {
        //     windowReference = window.open();
        // }

        advService(urlConfig, {}, (res) => {
            if (res.data.success && res.data.response.url) {
                checkIphone()
                    ? openIphoneLink(res.data.response.url)
                    : window.open(res.data.response.url, "_blank");
                    // setRedirectLink(res.data.response.url);

            }
            handleClose();
        });
    };

    // TODO: use this style to replace style in addonSteps_title
    const [styles, setStyles] = useState();

    useEffect(() => {
        setStyles({
            title: {
                background: config?.background,
            },
            bgImage: {
                bottom: config?.isEmployee ? "20px" : "50%",
                ...(!config?.isEmployee && { transform: "translateY(50%)" }),
            },
        });
    }, [config]);

    return (
        <Modal
            open={open}
            onClose={() => {
                handleClose();
                setStep("1");
                setPhone("");
                setOtp("");
                setOtpError(false);
                setResendOtpAllowed(false);
                setResendSeconds(0);
                setTimer(true);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="addonSteps_box">
                <div className="addonSteps modal-container">
                    <div
                        className={`addonSteps_title pr27 pl27 ${
                            config?.isEmployee ? "pt70 pb12" : "pt25 pb25"
                        }`}
                        style={styles?.title}
                    >
                        <h1>
                            {phoneRequired && !phoneVerified
                                ? "Verify your phone number to continue"
                                : data?.title}
                        </h1>
                        {config?.isEmployee && config?.brandImage && (
                            <div className="addonSteps_brandImage">
                                <img
                                    src={config?.brandImage}
                                    alt={`${data?.title}_title_image`}
                                />
                            </div>
                        )}
                        <img
                            src={config?.bgImage}
                            alt={`${data?.title}_bg_image`}
                            style={styles?.bgImage}
                            className="addonSteps_background"
                        />
                    </div>
                    {phoneRequired && !phoneVerified ? (
                        <>
                            <div style={{ margin: "20px" }}>
                                {step === "1" ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "20px",
                                        }}
                                    >
                                        <Typography.Title level={5}>
                                            In order to avail this wellness
                                            service your phone number should be
                                            verified to continue
                                        </Typography.Title>
                                        <TextField
                                            label="Phone no."
                                            variant="outlined"
                                            type="number"
                                            error={phoneError}
                                            helperText={
                                                phoneError
                                                    ? "Phone number must be 10 digits"
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setPhone(e.target.value);
                                                if (phoneError) {
                                                    setPhoneError(
                                                        e.target.value
                                                            .length !== 10
                                                    );
                                                }
                                            }}
                                        />
                                        <Typography variant="caption">
                                            An OTP will trigger to provided
                                            phone number
                                        </Typography>
                                        <button
                                            className="buttonPrimary"
                                            onClick={() => {
                                                if (phone.length !== 10) {
                                                    setPhoneError(true);
                                                    return;
                                                }
                                                let config = {
                                                    ...OTP_POST_WELLNESS,
                                                };
                                                let payload = {
                                                    username: phone,
                                                    phone: true,
                                                };
                                                config.setLoader = setLoader;
                                                advService(
                                                    config,
                                                    payload,
                                                    (res) => {
                                                        if (
                                                            res?.data?.success
                                                        ) {
                                                            setStep("2");
                                                        }
                                                    }
                                                );
                                            }}
                                        >
                                            Send Otp
                                        </button>
                                    </div>
                                ) : (
                                    <form
                                        className="elementContainer"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            if (
                                                otp.length === 4 &&
                                                Number(otp)
                                            ) {
                                                setInputCompleted(true);
                                            }
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Otp
                                            onChange={(otp) => {
                                                setOtpError(false);
                                                setOtp(otp);
                                                if (
                                                    otp.length === 4 &&
                                                    Number(otp)
                                                )
                                                    setInputCompleted(true);
                                            }}
                                            error={otpError}
                                            className="mb25"
                                        />
                                        <button className="buttonPrimary">
                                            Submit
                                        </button>
                                        {resendOtpAllowed ? (
                                            <div
                                                onClick={resendOtp}
                                                style={{
                                                    cursor: "pointer",
                                                    textAlign: "center",
                                                    marginTop: "20px",
                                                    color: "black",
                                                }}
                                            >
                                                Resend Otp
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                Resend Otp in 00:
                                                {(String(30 - resendSeconds)
                                                    .length === 1
                                                    ? "0"
                                                    : "") +
                                                    String(30 - resendSeconds)}
                                            </div>
                                        )}
                                    </form>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="addonSteps_container p25">
                                {config && config.isEmployee ? (
                                    <>
                                        <h2 className="mb20">
                                            {data?.popupHeading}
                                        </h2>
                                        <div className="addonSteps_body">
                                            <Steps
                                                data={steps}
                                                className="pb10 fontColor_dark"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className="addonSteps_knowMore mt20">
                                        <img
                                            src={executiveIcon}
                                            alt=""
                                            srcSet=""
                                        />
                                        <div>
                                            <h2>
                                                Thanks for showing your
                                                interest.
                                            </h2>
                                            <p>
                                                Our executive will connect with
                                                you in 2 hrs.
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {config && config.isEmployee && (
                                    <button
                                        className="buttonOutlined"
                                        onClick={handleCtxClick}
                                    >
                                        {data?.buttonText}
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </Box>
        </Modal>
    );
}

export default AddonSteps;
