const AirAmbulance =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/airAmbulance.svg";
const Ambulance =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/ambulance.svg";
const Ambulance1 =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/ambulance1.svg";
const BabyCoverage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/babyCoverage.svg";
const CashlessHospitals =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/cashlessHospitals.svg";
const Claim =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/claim.svg";
const Consumables =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/consumables.svg";
const Copay =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/copay.svg";
const Copay1 =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/copay1.svg";
const CorporateBuffer =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/corporateBuffer.svg";
const Covid19 =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/covid19.svg";
const DayCare =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/dayCare.svg";
const Dental =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/dental.svg";
const DiseaseWiseLimit =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/diseaseWiseLimit.svg";
const Domiciliary =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/domiciliary.svg";
const ForeignTreatment =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/foreignTreatment.svg";
const GenericFeature =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/genericFeature.svg";
const IcuRoomRentLimit =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/icuRoomRentLimit.svg";
const LasikSurgery =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/lasikSurgery.svg";
const Maternity =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/maternity.svg";
const MentalWelness =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/mentalWelness.svg";
const Opd =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/opd.svg";
const PedCoverage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/pedCoverage.svg";
const PrePostHospitalisation =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/prePostHospitalisation.svg";
const PrePostHospitalisation1 =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/prePostHospitalisation1.svg";
const RoomRentLimit =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/roomRentLimit.svg";
const SumInsured =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/sumInsured.svg";
const RoomRentLimit1 =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/roomRentLimit1.svg";
const SumInsured1 =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/sumInsured1.svg";
const Hospitalization =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/hospitalization.svg";
const Wait =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/wait.svg";
const Wait1 =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/policy_features_icons/wait1.svg";

export const getFeatureIcon = (icon = "genericFeature") => {
    switch (icon) {
        case "airAmbulance":
            return AirAmbulance;

        case "ambulance":
            return Ambulance;
        case "ambulance1":
            return Ambulance1;

        case "babyCoverage":
            return BabyCoverage;

        case "cashlessHospitals":
            return CashlessHospitals;

        case "hospitalisation":
            return Hospitalization;

        case "claim":
            return Claim;

        case "consumables":
            return Consumables;

        case "nocopay":
            return Copay;
        case "copay1":
            return Copay1;

        case "corporateBuffer":
            return CorporateBuffer;

        case "covid19":
            return Covid19;

        case "dayCare":
            return DayCare;

        case "dental":
            return Dental;

        case "diseaseWiseLimit":
            return DiseaseWiseLimit;

        case "domiciliary":
            return Domiciliary;

        case "foreignTreatment":
            return ForeignTreatment;

        case "icuRoomRentLimit":
            return IcuRoomRentLimit;

        case "lasikSurgery":
            return LasikSurgery;

        case "maternity":
            return Maternity;

        case "mentalWelness":
            return MentalWelness;

        case "opd":
            return Opd;

        case "pedCoverage":
            return PedCoverage;

        case "prePostHospitalisation":
            return PrePostHospitalisation;

        case "roomRentLimit":
            return RoomRentLimit;

        case "sumInsured":
            return SumInsured;

        case "wait":
            return Wait;

        case "prePostHospitalisation1":
            return PrePostHospitalisation1;

        case "roomRentLimit1":
            return RoomRentLimit1;

        case "sumInsured1":
            return SumInsured1;

        case "wait1":
            return Wait1;

        default:
            return GenericFeature;
    }
};

export const getCoverageIcon = (icon, type = "covered") => {
    switch (icon) {
        case "airAmbulance":
            return "material-symbols:flight-rounded";

        case "ambulance":
            return "material-symbols:ambulance-outline-rounded";
        case "ambulance1":
            return "material-symbols:ambulance-outline-rounded";

        case "babyCoverage":
            return "material-symbols:child-care-outline";

        case "cashlessHospitals":
            return "material-symbols:local-hospital-outline";

        case "hospitalisation":
            return "material-symbols:home-health-outline";

        case "claim":
            return "material-symbols:draft-outline";

        case "consumables":
            return "mdi:needle";

        case "nocopay":
            return "material-symbols:payments-outline";
        case "copay1":
            return "material-symbols:payments-outline";

        case "corporateBuffer":
            return "material-symbols:health-and-safety-outline";

        case "covid19":
            return "material-symbols:coronavirus-outline";

        case "dayCare":
            return "material-symbols:sunny-outline-rounded";

        case "dental":
            return "material-symbols:dentistry-outline";

        case "diseaseWiseLimit":
            return "material-symbols:shield-with-heart-outline-rounded";

        // case "domiciliary":
        //     return Domiciliary;

        case "foreignTreatment":
            return "material-symbols:flight-takeoff";

        case "icuRoomRentLimit":
            return "material-symbols:home-health-outline";

        case "lasikSurgery":
            return "material-symbols:visibility-outline-rounded";

        case "maternity":
            return "ic:outline-pregnant-woman";

        case "mentalWelness":
            return "material-symbols:spa-outline-rounded";

        case "opd":
            return "material-symbols:outpatient-outline-rounded";

        case "pedCoverage":
            return "material-symbols:shield-with-heart-outline-rounded";

        case "prePostHospitalisation":
            return "material-symbols:event-outline-rounded";

        case "roomRentLimit":
            return "material-symbols:home-health-outline";

        case "sumInsured":
            return "material-symbols:payments-outline";

        case "wait":
            return "material-symbols:pace";

        case "prePostHospitalisation1":
            return "material-symbols:event-outline-rounded";

        case "roomRentLimit1":
            return "material-symbols:home-health-outline";

        case "sumInsured1":
            return "material-symbols:payments-outline";

        case "wait1":
            return "material-symbols:pace";

        default:
            return type === "covered"
                ? "material-symbols:verified-outline"
                : "material-symbols:cancel-outline";
    }
};
