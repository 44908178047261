import React, { useEffect } from "react";
import { userService } from "../../services/api/userService/userService";
import { useStateValue } from "../../util/stateProvider";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Box, CircularProgress } from "@mui/material";
import handleButtonClick from "../../util/functions";

const UpstoxRedirect: React.FC<{ setLoading: any }> = ({ setLoading }) => {
    const [{ user }, dispatch] = useStateValue();
    const navigate = useNavigate();
    const [page, setPage] = React.useState<string>("");
    const { policyDto } = useSelector((state: RootState) => state.home);
    const reducerDispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            let url = window.location.search;
            let route = await userService.urlLogin.session(url, dispatch);
            setPage(route);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (user && page !== "" && policyDto) {
            if (page === "home") {
                navigate("/home");
                return;
            } else if (page === "hospital") {
                handleButtonClick("networkHospital", navigate, reducerDispatch);
                return;
            } else if (page === "policy") {
                navigate("/plans");
                return;
            } else if (page === "claim") {
                navigate("/claims");
                return;
            }
            navigate(`/${page}`);
        }
    }, [user, policyDto]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <CircularProgress />
        </Box>
    );
};

export default UpstoxRedirect;
