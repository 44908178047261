import React, { useState, useEffect } from "react";

// Extend the Window interface to include the webkit property
import "./FileInput.scss";
import { CustomFile } from "../../types/claims";

import CircularProgress from "@mui/material/CircularProgress";
import openIphoneLink from "../../util/function/openIphoneLink";
import { is } from "date-fns/locale";
import ImageIcon from "../../assets/images/icons/note-add.svg";
import CloseIcon from "../../assets/images/icons/close-icon.svg";
import { Skeleton } from "@mui/material";
import downloadBlobIphone from "../../util/function/downloadBlobIphone";
const docIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/doc.svg";
const ExcelIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/excel.svg";
const pdfIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/pdf.svg";
const EditIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/edit.svg";
// const ImageIcon =
//     "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/image.svg";
// const CloseIcon =
//     "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Modals_icons/Close.svg";

// create props interface
interface Props {
    files: File[] | Blob[] | CustomFile[];
    setFiles: any;
    // setFiles: (files: File[] | Blob[]) => void;
    multiple: any;
    types: any;
    label?: string;
    wrongInputFunction: any;
}

function FileInput({
    files,
    setFiles,
    multiple,
    types,
    wrongInputFunction,
    label,
}: Props) {
    const [dragClass, setDragClass] = useState(false);
    const imageExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "tiff",
        "tif",
        "svg",
    ];
    const pdfExtension = "pdf";
    const docExtension = ["doc", "docx"];
    const xlsExtension = ["xls", "xlsx", "csv", "xlsm", "xlsb"];
    const [acceptedTypes, setAcceptedTypes] = useState("");

    useEffect(() => {
        const typesArray = types.split(",").map((item: string) => item.trim());
        var fileTypes = "";
        if (typesArray.includes("image")) {
            fileTypes =
                fileTypes + imageExtensions.map((ext) => "." + ext).join(",");
        }
        if (typesArray.includes("pdf")) {
            fileTypes = fileTypes + ", ." + pdfExtension;
        }
        if (typesArray.includes("doc")) {
            fileTypes =
                fileTypes + docExtension.map((ext) => "." + ext).join(",");
        }
        if (typesArray.includes("xls")) {
            fileTypes =
                fileTypes + xlsExtension.map((ext) => "." + ext).join(",");
        }

        setAcceptedTypes(fileTypes);
    }, []);

    const dropHandler = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();
        setDragClass(false);
        if (ev.dataTransfer.items) {
            const temp = [];
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === "file") {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    if (file && checkFileType([file])) {
                        temp.push(file);
                    }
                }
            }
            setFiles([...files, ...temp]);
        } else {
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                console.log(
                    "... file[" +
                        i +
                        "].name = " +
                        ev.dataTransfer.files[i].name,
                    ev.dataTransfer.files[i]
                );
            }
        }
    };

    const filterCorrectFiles = (files: FileList | File[]) => {
        const temp = [];
        for (let i = 0; i < files.length; i++) {
            if (checkFileType([files[i]])) {
                temp.push(files[i]);
            }
        }
        return temp;
    };

    const checkFileType = (files: FileList | File[]) => {
        let validity = false;
        let typesArray = types.split(",").map((item: string) => item.trim());
        for (let i = 0; i < files.length; i++) {
            let fileExtension = files[i].name.split(".").pop();
            if (typesArray.includes("image") && fileExtension) {
                validity = imageExtensions.includes(fileExtension) || validity;
            }
            if (typesArray.includes("pdf")) {
                validity = fileExtension === pdfExtension || validity;
            }
            if (typesArray.includes("doc") && fileExtension) {
                validity = docExtension.includes(fileExtension) || validity;
            }
            if (typesArray.includes("xls") && fileExtension) {
                console.log("4");
                validity = xlsExtension.includes(fileExtension) || validity;
            }

            if (!validity) {
                wrongInputFunction(files[i].name);
                return validity;
            }
        }
        return validity;
    };

    const displayPreview = (file: any) => {
        if (
            imageExtensions.includes(file.name.split(".").pop().toLowerCase())
        ) {
            return (
                <img
                    src={URL.createObjectURL(file)}
                    alt="preview"
                    className="fileUpload_preview"
                />
            );
        }
        // else if(pdfExtension === file.name.split('.').pop().toLowerCase()){
        //     return <img src={PDFIcon} alt="preview" className='fileUpload_preview'/>
        // }
        // else{
        //     return <img src={FileIcon} alt="preview" className='fileUpload_preview'/>
        // }

        // if
    };

    const possibleFileTypes = () => {
        const typesArray = types.split(",").map((item: string) => item.trim());
        let possibleTypes = [];
        if (typesArray.includes("image")) {
            possibleTypes.push(imageExtensions.join(", "));
        }
        if (typesArray.includes("pdf")) {
            possibleTypes.push(pdfExtension);
        }
        if (typesArray.includes("doc")) {
            possibleTypes.push(docExtension.join(", "));
        }
        if (typesArray.includes("xls")) {
            possibleTypes.push(xlsExtension.join(", "));
        }

        return possibleTypes.join(", ");
    };

    const generateIcon = (file?: File | Blob | CustomFile) => {
        const typesArray = types.split(",").map((item: string) => item.trim());
        if (!file) {
            if (typesArray[0] === "image") {
                return ImageIcon;
            } else if (typesArray[0] === "pdf") {
                return pdfIcon;
            } else if (typesArray[0] === "doc") {
                return docIcon;
            } else {
                return ExcelIcon;
            }
        } else {
            // debugger;
            if (isCustomFilePDF(file) && file.status === "uploading") {
                return docIcon;
            } else {
                //@ts-ignore
                let fileExtension = file?.name.split(".").pop();
                if (file?.type === "pdf" || fileExtension === pdfExtension) {
                    return pdfIcon;
                } else if (
                    file?.type.startsWith("image/") ||
                    (fileExtension && imageExtensions.includes(fileExtension))
                ) {
                    return isCustomFilePDF(file)
                        ? file.url
                        : URL.createObjectURL(file);
                } else return docIcon;
            }
        }

        // if (typesArray.length > 1) {
        //     return docIcon;
        // } else {
        //     if (typesArray[0] === "image") {
        //         return ImageIcon;
        //     } else if (typesArray[0] === "pdf") {
        //         return pdfIcon;
        //     } else if (typesArray[0] === "doc") {
        //         return docIcon;
        //     } else {
        //         return ExcelIcon;
        //     }
        // }
    };

    function isCustomFile(obj: any): obj is CustomFile {
        return "id" in obj; // Assuming 'id' is a property unique to CustomFile
    }

    function isCustomFilePDF(obj: any): obj is CustomFile {
        // do not check for valid values but just check if they have id, url and status
        return (
            obj &&
            typeof obj === "object" &&
            "id" in obj &&
            "url" in obj &&
            "status" in obj &&
            obj.type === "pdf"
        );
    }

    return (
        <div
            className={`fileUpload_Input ${
                !!files.length && "fileUpload_Input__files"
            }`}
            key={`uploadScreen_id`}
            onDrop={dropHandler}
            onDragOver={(e) => {
                e.preventDefault();
                setDragClass(true);
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                setDragClass(false);
            }}
        >
            <div
                className={`fileUpload_Input_drop_overlay ${
                    dragClass ? "fileUpload_Input_Drag_Over" : ""
                }`}
            >
                <h2>Drag and drop here</h2>
            </div>

            {files.length ? (
                <div
                    className="fileUpload_Input_images"
                    // onDrop={dropHandler}
                    // onDragOver={(e) => e.preventDefault()}
                >
                    {/* ---Files Staged--- */}
                    {files.map(
                        (image: File | Blob | CustomFile, index: number) => {
                            // im using CustomFile type to check if the file is already uploaded or not but it is not working

                            return (
                                <div
                                    className="fileUpload_Input_image"
                                    key={`uploadScreen_image_${index}`}
                                >
                                    <div className="fileUpload_Input_image_selected">
                                        {isCustomFile(image) &&
                                        image.status === "uploading" ? (
                                            <CircularProgress
                                                key={`uploadScreen_image_${index}`}
                                            />
                                        ) : (
                                            <img
                                                onClick={() => {
                                                    if (
                                                        window.navigator
                                                            .userAgent ===
                                                        "androidWebView"
                                                    ) {
                                                        // @ts-ignore
                                                        downloadBlobIphone(
                                                            image,
                                                            // @ts-ignore
                                                            image.name
                                                        );
                                                    } else {
                                                        openIphoneLink(
                                                            URL.createObjectURL(
                                                                image
                                                            )
                                                        );
                                                    }
                                                }}
                                                key={`uploadScreen_image_${index}`}
                                                src={generateIcon(image)}
                                                alt=""
                                            />
                                        )}
                                    </div>
                                    <div className="fileUpload_Input_bottom">
                                        {isCustomFile(image) &&
                                        image.status !== "uploading" ? (
                                            <>
                                                <a
                                                    className="fileUpload_Input_prviewLink"
                                                    href={
                                                        window.navigator
                                                            .userAgent ===
                                                        "androidWebView"
                                                            ? "javascript:void(0);"
                                                            : URL.createObjectURL(
                                                                  image
                                                              )
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            window.navigator
                                                                .userAgent ===
                                                            "androidWebView"
                                                        ) {
                                                            // @ts-ignore
                                                            downloadBlobIphone(
                                                                image,
                                                                image.name
                                                            );
                                                        }
                                                    }}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {image.name}
                                                </a>
                                            </>
                                        ) : (
                                            <a
                                                className="fileUpload_Input_prviewLink"
                                                href={
                                                    window.navigator
                                                        .userAgent ===
                                                    "androidWebView"
                                                        ? "javascript:void(0);"
                                                        : URL.createObjectURL(
                                                              image
                                                          )
                                                }
                                                onClick={() => {
                                                    if (
                                                        window.navigator
                                                            .userAgent ===
                                                        "androidWebView"
                                                    ) {
                                                        // @ts-ignore
                                                        downloadBlobIphone(
                                                            image,
                                                            // @ts-ignore
                                                            image.name
                                                        );
                                                    }
                                                }}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {
                                                    // @ts-ignore
                                                    image.name
                                                }
                                            </a>
                                        )}
                                    </div>
                                    <img
                                        src={CloseIcon}
                                        alt="check"
                                        className="fileUpload_Input_image_close pointer"
                                        onClick={() => {
                                            const tempArr = [...files];
                                            tempArr.splice(index, 1);
                                            setFiles([...tempArr]);
                                        }}
                                    />
                                </div>
                            );
                        }
                    )}

                    {/* ---For adding file when some files are already uploaded--- */}
                    {!!multiple && (
                        <>
                            <input
                                type="file"
                                multiple={multiple}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (e.target.files) {
                                        const correctFiles = filterCorrectFiles(
                                            e.target.files
                                        );
                                        setFiles([...files, ...correctFiles]);
                                        e.target.value = "";
                                    }
                                }}
                                id="file"
                                name="file"
                                className="hide"
                                onClick={(e) => {
                                        var valueToSend = "fileInputClicked";
                                        // @ts-ignore
                                        window?.webkit?.messageHandlers.jsMessageHandler.postMessage(valueToSend);
                                }}
                                accept={acceptedTypes}
                            />

                            <label
                                htmlFor="file"
                                className="fileUpload_Input_image fileUpload_Input_image_uploadButton"
                            >
                                <div className="fileUpload_Input_image_selected pointer">
                                    <img src={ImageIcon} alt="image" />
                                </div>
                                <div className="fileUpload_Input_bottom pointer">
                                    <span className="fileUpload_Input_prviewLink ">
                                        upload more
                                    </span>
                                </div>
                            </label>
                        </>
                    )}
                </div>
            ) : (
                <label htmlFor="file" className="fileUpload_Input_Icon pointer">
                    <img src={generateIcon()} alt="" />
                </label>
            )}
            {!!!files.length && (
                <label htmlFor="file" className="pointer">
                    <p>{label ? label : `Click to upload ${types}`}</p>
                    <p>
                        {label ? "click to upload " : ""} or drag and drop, copy
                        and paste files
                        <span className="primaryColor">
                            <input
                                type="file"
                                multiple={multiple}
                                onChange={(e) => {
                                    if (e.target.files) {
                                        const correctFiles = filterCorrectFiles(
                                            e.target.files
                                        );
                                        setFiles([...files, ...correctFiles]);
                                    }
                                }}
                                onClick={(e) => {
                                    var valueToSend = "fileInputClicked";
                                    // @ts-ignore
                                    window?.webkit?.messageHandlers.jsMessageHandler.postMessage(valueToSend);
                                }}
                                id="file"
                                name="file"
                                className="hide"
                                accept={acceptedTypes}
                            />

                            <label htmlFor="file" className="pointer">
                                &nbsp; choose {possibleFileTypes()}
                            </label>
                        </span>
                    </p>
                </label>
            )}
        </div>
    );
}

export default FileInput;
