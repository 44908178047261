import React, { useEffect, useState } from "react";
import KmdSignIn from "./kmdSignIn";
import KmdOtp from "./kmdOtp";
import "./kmdAuthentication.scss";
import { Divider, Typography } from "@mui/material";
import advService from "../../../util/advService";
import { KMD_OTP_POST, KMD_OTP_VERIFY_POST } from "../../../util/newconfig";
import { AxiosResponse } from "axios";
import { Gender, JavaResponse, Lead } from "../../../types";
import { KmdOtpReqDto, KmdUser } from "../../../types/DTO/kmdDto";
import { setSignIn } from "../../../util/function";
import { useStateValue } from "../../../util/stateProvider";
import { redirect } from "../../../util/function/redirect";
import { useNavigate } from "react-router-dom";
import { LoginResponse } from "../../../types/DTO/loginDTO";

const kmdLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/New+asset/host_logos/logos/KMD-Logo_Transparent_72ppi_Medium.png";
const coaLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/New+asset/host_logos/logos/COA_Logo-removebg-preview.png";
const iciciLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/icici.svg";

const BACK =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/back.svg";

const KmdAuthentication: React.FC = () => {
    const [{ user }, dispatch] = useStateValue();
    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const [basicUserInfo, setBasicUserInfo] = useState<KmdOtpReqDto | null>(
        null
    );

    const handleNextStep = (registrationNumber: string) => {
        advService(
            { ...KMD_OTP_POST },
            { registrationNumber: registrationNumber },
            (res: AxiosResponse<JavaResponse<KmdOtpReqDto>>) => {
                if (res.data.success) {
                    setBasicUserInfo(res.data.response);
                    setStep(step + 1);
                }
            }
        );
    };

    const handlePrevStep = () => {
        setStep(step - 1);
    };

    const handleLogin = (otp: string) => {
        advService(
            { ...KMD_OTP_VERIFY_POST },
            {
                username: basicUserInfo?.username,
                phone: true,
                registrationNumber: basicUserInfo?.registrationNumber,
                otp: otp,
            },
            (res: AxiosResponse<JavaResponse<LoginResponse>>) => {
                if (res.data.success) {
                    console.log(res);
                    setSignIn(
                        res.headers.authorization,
                        res.data.response.user,
                        res.data.response.lead || null,
                        res.data.response.uuid || null,
                        res.data.response.d_verify_phone,
                        dispatch
                    );
                    redirect(res, navigate);
                }
            }
        );
    };

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f5f5f5";
        };
    }, []);

    return (
        <div className="kmdAuthWrapper">
            <div className="kmdAuthentication">
                <div className="kmdAuthentication_header mb20">
                    <img src={coaLogo} alt="" className="mr20" />
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ borderStyle: "dashed" }}
                    />
                    <img src={kmdLogo} alt="" className="ml20 mr20" />
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ borderStyle: "dashed" }}
                    />
                    <img
                        src={iciciLogo}
                        alt=""
                        className="ml20"
                        style={{ width: "156px" }}
                    />
                </div>

                <p
                    className="mb20"
                    style={{
                        color: "#474747",
                        textAlign: "center",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "18px",
                    }}
                >
                    Specialised Insurance Policies at attractive prices and
                    wider coverage designed specifically for Architects
                    registered with COA
                </p>
                <div className="kmdAuthentication_container pt50 pb50 pl25 pr25">
                    {step === 1 && (
                        <>
                            <h1 className="mb20">Log In</h1>
                            <KmdSignIn handleSubmit={handleNextStep} />
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <h1 className="mb20">
                                <img
                                    src={BACK}
                                    alt="back"
                                    className="back-button pointer"
                                    onClick={() => {
                                        setStep(1);
                                    }}
                                />
                                &nbsp;OTP sent on{" "}
                                {basicUserInfo?.username.replace(
                                    /(\d{2})\d{4}(\d{3})/,
                                    "$1XXXX$2"
                                )}
                            </h1>
                            <KmdOtp
                                name="otp"
                                basicUserInfo={basicUserInfo}
                                handleSubmit={handleLogin}
                            />
                            {/* Step 2 content */}
                            {/* <button onClick={handlePrevStep}>Previous</button> */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default KmdAuthentication;
