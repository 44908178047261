// @ts-nocheck
import { Space, Dropdown as AntDropdown } from "antd";
import React, { ReactElement } from "react";
import evaluate from "../../util/function/evaluate";
import getIcon from "../../util/function/getIcon.tsx";
import { ColumnTypes } from "../../types/Components";
import currencyFormater from "../../util/function/currencyFormater";
import { changeDateFormat } from "../../util/date";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "./Table.scss";

interface RowProps<T> {
    column: {};
    text: string;
    record: T;
    // functions?: ((rowData: T|string) => string);
    functions: {
        [name: string]: (rowData: T) => void;
    };
    data?: any[];
}

const CustomRow = <T extends object>({
    column,
    text,
    record,
    functions,
    data,
}: RowProps<T>): ReactElement => {
    const DataTypeRenderer = (
        text: string | null,
        type: ColumnTypes
    ): string => {
        if (!text && isNaN(parseFloat(text))) return "";
        switch (type) {
            case ColumnTypes.Money:
                return (
                    <>
                        {" "}
                        <span className="rupee">₹</span>{" "}
                        {currencyFormater(parseFloat(text))}
                    </>
                );
            case ColumnTypes.dd_mmm_yyyy:
                return changeDateFormat(
                    "yyyy-mm-dd",
                    "dd-mmm-yyyy",
                    text
                ).toString();
            case ColumnTypes.Status:
                return column.dMap[text] ? column.dMap[text] : text;
            case ColumnTypes.Relationship:
                if (text === "Spouse") {
                    return record.gender === "Male" ? "Husband" : "Wife";
                } else if (text === "Child") {
                    return record.gender === "Male" ? "Son" : "Daughter";
                } else if (text === "Parent") {
                    return record.gender === "Male" ? "Father" : "Mother";
                } else {
                    return text;
                }

            default:
                return text;
        }
    };
    return column.type === ColumnTypes.Action ? (
        <Space>
            {column.actions.length > column.numberOfButtons ? (
                <Space style={{ width: column.width }}>
                    <AntDropdown
                        overlay={
                            <div
                                style={{
                                    maxHeight: "400px",
                                    overflowY: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "#fff",
                                    padding: "8px",
                                    borderRadius: "4px",
                                    gap: "5px",
                                    border: "1px solid #d9d9d9",
                                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                                }}
                            >
                                {column.actions?.map((action, index) => {
                                    if (
                                        evaluate(
                                            action.display.condition,
                                            record
                                        )
                                    ) {
                                        return (
                                            <div
                                                key={index}
                                                onClick={() =>
                                                    functions[action.func](
                                                        record,
                                                        data
                                                    )
                                                }
                                                style={{
                                                    width: "100%",
                                                    cursor: "pointer",
                                                    padding: "8px",
                                                    borderRadius: "4px",
                                                    transition:
                                                        "background-color 0.3s",
                                                }}
                                                onMouseEnter={(e) => {
                                                    e.currentTarget.style.backgroundColor =
                                                        "#f0f0f0";
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.currentTarget.style.backgroundColor =
                                                        "#fff";
                                                }}
                                            >
                                                <div className="dropdown___style">
                                                    {action.display.icon &&
                                                        getIcon(
                                                            action.display.icon
                                                        )}
                                                    <div>
                                                        {evaluate(
                                                            action.display.text,
                                                            record
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        }
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <MoreHorizIcon />
                            </Space>
                        </a>
                    </AntDropdown>
                </Space>
            ) : (
                <Space style={{ width: column.width }}>
                    {column.actions?.map(
                        (action) =>
                            evaluate(action.display.condition, record) && (
                                <div
                                    onClick={() =>
                                        functions[action.func](record)
                                    }
                                >
                                    {action.display.icon &&
                                        getIcon(action.display.icon)}
                                    {evaluate(action.display.text, record)}
                                </div>
                            )
                    )}
                </Space>
            )}
        </Space>
    ) : (
        <Space size="middle">
            <div
                className="ellipsis"
                style={{
                    color: column.color ? column.color[text] : "",
                    width: column.width,
                }}
            >
                {DataTypeRenderer(text, column.type)}
            </div>
        </Space>
    );
};

export default CustomRow;
