import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { FileDataType } from "../../types/DownloadCenter/FileDataType";

type Props = {
    confirmationModal: boolean;
    setConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleDeleteConfirmation: () => void;
    selectedRow: FileDataType | null;
};

const DeleteDocumentModal = ({
    confirmationModal,
    setConfirmationModal,
    handleDeleteConfirmation,
    selectedRow,
}: Props) => {
    if (confirmationModal)
        return (
            <ConfirmationModal
                handleConfirmation={handleDeleteConfirmation}
                close={() => setConfirmationModal(false)}
                confType={"delete"}
                text={
                    <h1>
                        Do you want to remove{" "}
                        <span style={{ color: "#4F3DD1" }}>
                            {selectedRow?.fileName}
                        </span>{" "}
                        document ?{" "}
                    </h1>
                }
                name={"delete"}
            />
        );
    else return null;
};

export default DeleteDocumentModal;
