/**
 * Renders employee navbar.
 * @returns {JSX.Element} Navbar component JSX.
 */

import React from "react";
import SideNavbar from "./SideNavbar";
import "./employee_nav.scss";
import MobileNavbar from "./MobileNavbar";

const EmployeeNavbar: React.FC<{ scrolled: boolean }> = ({ scrolled }) => {
    return (
        <>
            <SideNavbar scrolled={scrolled} />
            <MobileNavbar />
        </>
    );
};

export default EmployeeNavbar;
