import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import CryptoJS from "crypto-js";

function Redirect({ initiate }) {
    const navigate = useNavigate();
    const { token } = useParams();

    useLayoutEffect(() => {
        localStorage.setItem(
            "Authorization",
            CryptoJS.AES.decrypt(token, "Secret").toString(CryptoJS.enc.Utf8)
        );
        initiate();
    }, []);
    return <div></div>;
}

export default Redirect;
