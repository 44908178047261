import {
    Box,
    Tab,
    Tabs,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";

interface RenderFilterComponentProps {
    config: {
        filter?: {
            keys?: {
                key: number;
                color: string;
                title: string;
                count?: number;
            }[];
        };
    };
    activeTab: number;
    handleTabChange: (event: any, newTab: number) => void;
}

const RenderFilterComponent: React.FC<RenderFilterComponentProps> = ({
    config,
    activeTab,
    handleTabChange,
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    if (config?.filter?.keys) {
        const activeTabIndex = config.filter.keys.findIndex(
            (tab) => tab.key === activeTab
        );
        const isMobileView = windowWidth <= 600;

        if (isMobileView) {
            return (
                <Box sx={{ maxWidth: 560, marginTop: "0.4rem" }}>
                    <FormControl sx={{ minWidth: 120 }} size={"small"}>
                        <Select
                            id="dropdown"
                            value={activeTab}
                            onChange={(event) => {
                                // @ts-ignore
                                handleTabChange(event, event.target.value);
                            }}
                            style={{
                                marginBottom: "0.5rem",
                                marginRight: "0.5rem",
                            }}
                        >
                            {config.filter.keys.map((tab) => (
                                <MenuItem
                                    key={tab.key}
                                    value={tab.key}
                                    style={{ color: tab.color }}
                                >
                                    {tab.count != undefined &&
                                    !(tab.count !== tab.count) ? (
                                        <>
                                            {tab.title} ({tab.count})
                                        </>
                                    ) : (
                                        tab.title
                                    )}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            );
        }

        return (
            <Box
                sx={{
                    maxWidth: 560,
                }}
            >
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    textColor="secondary"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor:
                                config.filter.keys[activeTabIndex]?.color,
                        },
                    }}
                    variant={"scrollable"}
                    scrollButtons={"auto"}
                    style={{
                        marginBottom: "0.5rem",
                        marginRight: "0.5rem",
                        backgroundColor: "white",
                        borderRadius: "6px",
                    }}
                >
                    {config?.filter?.keys.map((tab) => (
                        <Tab
                            style={{ minWidth: 0, color: tab.color }}
                            value={tab.key}
                            label={
                                tab.count != undefined &&
                                !(tab.count !== tab.count) ? (
                                    <>
                                        {tab.title} ({tab.count})
                                    </>
                                ) : (
                                    tab.title
                                )
                            }
                        ></Tab>
                    ))}
                </Tabs>
            </Box>
        );
    }

    return null;
};

export default RenderFilterComponent;
