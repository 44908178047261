import React, { useEffect } from "react";
import KmdInvoice from "./kmdInvoice";
import "./kmdStep2.scss";
import "./kmdStep4.scss";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { changeDateFormat } from "../../../util/date";
import advService from "../../../util/advService";
import { KMD_CREATE_PROPOSAL_POST } from "../../../util/newconfig";
import { KmdProposalDTO } from "../../../types/DTO/kmdDto";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../../types";

const bannerImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Super_TopUp/superIllustration.png";

interface Props {
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    logId: string;
}

const KmdStep4: React.FC<Props> = ({ setCurrentStep, logId }) => {
    const [loader, setLoader] = React.useState(false);
    const {
        members,
        memberInfo,
        sumInsured,
        plan,
        policyType,
        clientUuid,
        premium,
        ckyc,
        masterUuid,
        adressRequired,
        isKyc,
    } = useSelector((state: RootState) => state.kmd);
    const reducerDispatch = useDispatch();

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    const submitForm = () => {
        setLoader(true);
        advService(
            { ...KMD_CREATE_PROPOSAL_POST },
            {
                policyEmployeeMemberDTOS: members.map((member) => {
                    return {
                        ...(member.relationShip === "Employee"
                            ? {}
                            : {
                                  id: member.id,
                              }),
                        relationShip: member.relationShip,
                        gender: member.gender,
                        name: member.name,
                        dob: changeDateFormat(
                            "javascriptDateObject",
                            "yyyy-mm-dd",
                            new Date(member.dob)
                        ),
                        // sumInsured: sumInsured,
                        // deductible: 0,
                        uuid: member.uuid,
                        active: member.active,
                        memberId: member.memberId,
                    };
                }),
                nominations: [
                    {
                        name: memberInfo.nomineeName,
                        relationship: memberInfo.nomineeRelation,
                        active: true,
                        ...(policyType === "GMC" && {
                            dob: changeDateFormat(
                                "javascriptDateObject",
                                "yyyy-mm-dd",
                                new Date(memberInfo.nomineeDob)
                            ),
                        }),
                    },
                ],
                proposerDTO: {
                    addressDTO: {
                        ...(adressRequired && {
                            addressLine1: memberInfo.addressLine1,
                            addressLine2: memberInfo.addressLine2,
                            city: memberInfo.city,
                            state: null,
                            pincode: memberInfo.pincode,
                        }),
                        uuid: memberInfo.addressUuid,
                        active: true,
                    },
                    name: memberInfo.name,
                    dob: changeDateFormat(
                        "javascriptDateObject",
                        "yyyy-mm-dd",
                        new Date(
                            members.find(
                                (member) =>
                                    member.relationShip === "Employee" ||
                                    member.relationShip === "Self"
                            )?.dob || ""
                        )
                    ),
                    gender: members.find(
                        (member) =>
                            member.relationShip === "Employee" ||
                            member.relationShip === "Self"
                    )?.gender,
                    relationship: members.find(
                        (member) =>
                            member.relationShip === "Employee" ||
                            member.relationShip === "Self"
                    )?.relationShip,
                    clientUuid: clientUuid,
                    phone: memberInfo.phone,
                    email: "",
                },
                masterPolicyUuid: masterUuid,
                masterPolicyNodeApiDTO: {
                    sumInsured: sumInsured,
                    premium: Math.ceil(premium * 1.18),
                    deductible: null,
                    planName: plan,
                    // planCode: "",
                    salary: 0,
                    // startDate: "",
                    // endDate: "",
                    // policyUuid: "",
                },
                nomination: 1,
                isAddressReadOnly: false,
                // insurer: "FUTURE",
                // transactionId: "cd18ad2f-9325-4c45-bccc-d5db4085903f",
                // logId: "someuuidId",
                // paymentReturnUrl: "https://app.imt.care/kmd/status/{policyUuid}",
                params: {
                    ckyc: ckyc,
                },
                logId: logId,
            } as KmdProposalDTO,
            (res: AxiosResponse<JavaResponse<{ link: string }>>) => {
                setLoader(false);
                if (res.data.success) {
                    // window.open(res.data.response.link, "_blank");
                    window.location.href = res.data.response.link;
                }
            }
        );
    };

    return (
        <div className="kmdStep2 kmdStep3">
            <div className="kmdStep2_header mb40">
                <IconButton
                    className="p0I"
                    onClick={() => {
                        if (isKyc) {
                            setCurrentStep(3);
                        } else {
                            setCurrentStep(2);
                        }
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <h1 className="m0I ml20I fontSize_26">Review & Pay</h1>
            </div>
            <div className="kmdStep2_banner">
                <img src={bannerImage} alt="" className="pt25" />
            </div>
            <div className="kmdStep2_dataContainer">
                <div className="kmdStep2_left p40">
                    <div className="kmdStep3_review">
                        <div className="kmdStep3_memberDetails mb40">
                            <div className="kmdStep3_header">
                                <h2 className="fontSize_20">Member Details</h2>
                                <IconButton
                                    onClick={() => {
                                        setCurrentStep(1);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </div>
                            {members.map((member, index) => (
                                <div
                                    key={index}
                                    className="kmdStep3_member mb20"
                                >
                                    <div className="kmdStep3_memberInfo">
                                        <h3 className="fontSize_16 mb5I">
                                            {member.name}
                                        </h3>
                                    </div>
                                    <div className="kmdStep3_memberInfo">
                                        <p className="fontSize_16 mb0I">
                                            {/* @ts-ignore */}
                                            {changeDateFormat(
                                                "javascriptDateObject",
                                                "dd-mmm-yyyy",
                                                new Date(member.dob)
                                            )}
                                        </p>{" "}
                                        •
                                        <p className="fontSize_16 mb0I">
                                            {member.relationShip === "Employee"
                                                ? "Self"
                                                : member.relationShip}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="kmdStep3_personalDetails">
                            <div className="kmdStep3_header">
                                <h2 className="fontSize_20">
                                    Personal Details
                                </h2>
                                <IconButton
                                    onClick={() => {
                                        setCurrentStep(2);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </div>
                            <div className="kmdStep3_pesonalDetail">
                                <p className="fontSize_16 mb5I">Phone No.</p>
                                <h3 className="fontSize_16 mb20">
                                    {memberInfo.phone}
                                </h3>
                            </div>
                            <div className="kmdStep3_pesonalDetail">
                                <p className="fontSize_16 mb5I">
                                    Date of Birth
                                </p>
                                <h3 className="fontSize_16 mb20">
                                    {changeDateFormat(
                                        "yyyy-mm-dd",
                                        "dd-mmm-yyyy",
                                        memberInfo.dob
                                    ).toString()}
                                </h3>
                            </div>
                            <div className="kmdStep3_pesonalDetail">
                                <p className="fontSize_16 mb5I">Address</p>
                                <h3 className="fontSize_16 mb20">
                                    {memberInfo.addressLine1 +
                                        " " +
                                        memberInfo.addressLine2}
                                </h3>
                            </div>
                        </div>
                        <button
                            className="buttonPrimary mobileOnly mt15 W100"
                            onClick={submitForm}
                        >
                            Next
                        </button>
                    </div>
                </div>
                <div className="kmdStep2_right">
                    <KmdInvoice
                        submitForm={submitForm}
                        key="121321"
                        loader={loader}
                    />
                </div>
            </div>
        </div>
    );
};

export default KmdStep4;
