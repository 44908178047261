import React, { useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { MAGIC_LOGIN_POST, LOGIN_POST, GOOGLE_LOGIN } from "../util/newconfig";
import service from "../util/axiosConfig";
import { loadAuth2, loadAuth2WithProps, loadClientAuth2 } from "gapi-script";
import alert from "../util/alerts";
import checkResponseAndSetStep from "../util/function/checkResponse";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../types";
import { LoginResponse } from "../types/DTO/loginDTO";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redirect } from "../util/function/redirect";
import { useStateValue } from "../util/stateProvider";
import { setSignIn } from "../util/function";

// interface GoogleProps {
//     autoLoad?: boolean;
//     disabled?: boolean;
//     style?: React.CSSProperties;
// }

// function Google({ autoLoad, disabled, style }: GoogleProps) {
function Google({ autoLoad, disabled, style }) {
    const reducerDispatch = useDispatch();
    const navigate = useNavigate();
    const [{ subDomainInfo }, dispatch] = useStateValue();

    const clientId = process.env.REACT_APP_GOOGLE_CLIENTID;

    const onSuccess = (res) => {
        console.log("success:", res.tokenId);
        handleSubmit(res.tokenId);
    };
    const onFailure = (err) => {
        console.log("failed:", err);
    };
    const handleSubmit = (id) => {
        console.log("handling submit");
        const payload = {
            authId: id,
        };
        let config = { ...GOOGLE_LOGIN };
        console.log(payload, config);
        service.post(config.url, payload).then(async (res) => {
            if (res.data.success) {
                // let newRes : boolean | AxiosResponse<JavaResponse<LoginResponse>> = await checkResponseAndSetStep(res,reducerDispatch);
                let newRes = checkResponseAndSetStep(res, reducerDispatch);

                if (newRes) {
                    let response = newRes.data.response;
                    setSignIn(
                        newRes.headers.authorization,
                        response?.user || null,
                        response.lead || null,
                        response.page === "leadView"
                            ? null
                            : response.uuid || response.user?.uuid || null,
                        response?.d_verify_phone,
                        dispatch
                    );
                    redirect(newRes, navigate);
                }
            } else {
                alert(res.data.message || "Error", "error", 3000);
            }
        });
    };

    return (
        <GoogleLogin
            clientId={clientId}
            buttonText="Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
            className={`signInGoogle ${disabled ? "disabled" : ""}`}
            autoLoad={autoLoad || false}
            disabled={disabled}
            style={style}
        />
    );
}

export default Google;
