import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface NotFoundProps {
    title?: string;
    message?: string;
    sx?: BoxProps["sx"];
}

const GenericNotFound: React.FC<NotFoundProps> = ({
    title = "Page Not Found",
    message,
    sx,
}) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: "flex",
                minHeight: { sm: "545px", xs: "auto" },
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                width: { xs: "100%", sm: "343px" },
                margin: "auto",
                ...sx,
            }}
        >
            <Box
                component={"img"}
                src="https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/404+Illustration.png"
                alt="404"
                width={"193px"}
                // height={"141px"}
            />
            <Stack flexDirection={"column"}>
                <Typography
                    sx={{
                        color: "text.primary",
                        textAlign: "center",
                    }}
                    variant="h4"
                >
                    {title}
                </Typography>
                {message && (
                    <Typography
                        variant="body2"
                        sx={{
                            color: "text.secondary",
                            textAlign: "center",
                        }}
                    >
                        {message}
                    </Typography>
                )}
            </Stack>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: (theme) => theme.palette?.brand?.main,
                    color: "white",
                    width: { xs: "100%", sm: "343px" },
                }}
                size="small"
                onClick={() => {
                    navigate("/home");
                }}
            >
                <Typography
                    sx={{
                        color: "white",
                        textTransform: "none",
                    }}
                    variant="overline"
                >
                    Back to home
                </Typography>
            </Button>
        </Box>
    );
};

export default GenericNotFound;
