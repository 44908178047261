import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import {CustomerCareDto} from "../../types/DTO/claimsDTO";

interface CustomerCareState {
  customerCareData: CustomerCareDto | null;
}

const initialState: CustomerCareState = {
  customerCareData: null,
};

const customerCareSlice = createSlice({
  name: "customerCare",
  initialState,
  reducers: {
      setCustomerCareData: (state: CustomerCareState, action: PayloadAction<CustomerCareState['customerCareData']>) => {
          state.customerCareData = action.payload;
        },
  },
});

export const { setCustomerCareData } = customerCareSlice.actions;

export default customerCareSlice.reducer;