import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';


const Footer = () => {
    const theme = useTheme()
    return (
        <Box sx={{
            position: 'sticky',
            top: 'calc(100% - 68px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '94px',
            paddingBottom: '48px',
            paddingInline: '16px',
            marginRight: { xs: 0, md: '189px' },
        }}>
            <div className="footer">
                <Typography variant='h2' color={theme.palette.grey[300]}>Made with care & empathy ❤️</Typography>
            </div>
        </Box>
    );
};

export default Footer;