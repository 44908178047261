import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  authStore: {
    [key: string]: any;
  };
  authStep: string;
}

const initialState: AuthState = {
  authStore: {},
  authStep: 'signIn',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuthStep: (state, action: PayloadAction<string>) => {
      state.authStep = action.payload;
    },
    updateAuthStore: (state, action: PayloadAction<Partial<AuthState['authStore']>>) => {
      state.authStore = { ...state.authStore, ...action.payload };
    },
  },
});

export const { updateAuthStep, updateAuthStore } = authSlice.actions;

export default authSlice.reducer;
