import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Family, Member, MemberWithState } from "../../types";
import { MemberValidation } from "../../types/DTO/enrollmentGetDTO";
import { KmdKycDocumentDto, KmdKycInfoDto } from "../../types/DTO/kmdDto";

type KmdState = {
    isKyc: boolean;
    adressRequired: boolean;
    comment: string | null;
    commentList: string[] | null;
    masterUuid: string | null;
    employeeEdit: boolean;
    policyType: "GMC" | "GPA" | "GPI" | null;
    plan: string | null;
    sumInsured: number | null;
    sumInsuredList: number[];
    planList: string[];
    validation: MemberValidation;
    family: Family;
    members: MemberWithState[];
    premium: number;
    memberInfo: {
        // email: string;
        name: string;
        dob: string;
        phone: string;
        addressLine1: string;
        addressLine2: string;
        city: string;
        pincode: string;
        nomineeName: string;
        nomineeRelation: string;
        nomineeDob: string;
        addressUuid: string;
    };
    clientUuid?: string;
    ckyc: string | null;
    gpi: {
        isClaim: boolean;
        natureOfClaim: string;
        loss: number;
        currentStatus: string;
        remedialMeasures: string;

        saleInIndia: number;
        saleInUsCa: number;
        saleInEuAu: number;
        salesInOthers: number;
    };
    limitOfliability: number;
    planDb: {
        name: string;
        sumInsured: number;
        comment: string;
    }[];
} & (
    | {
          kycType: "info";
          kyc: KmdKycInfoDto;
      }
    | {
          kycType: "document";
          kyc: KmdKycDocumentDto;
      }
);

const initialState: KmdState = {
    isKyc: false,
    adressRequired: true,
    comment: "",
    commentList: null,
    masterUuid: "",
    employeeEdit: false,
    policyType: null,
    plan: null,
    sumInsured: null,
    sumInsuredList: [],
    planList: [],
    validation: {},
    family: {
        numberOfChild: 0,
        numberOfParent: 0,
        spouse: false,
        employee: false,
        liveInPartner: false,
        parent: 0,
    },
    members: [],
    premium: 0,
    memberInfo: {
        name: "",
        dob: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        pincode: "",
        nomineeName: "",
        nomineeRelation: "BROTHER",
        nomineeDob: "",
        addressUuid: "",
        city: "",
    },
    kyc: {
        certificateType: "AADHAAR",
        certificateNumber: "",
        dob: "",
        name: "",
        gender: "Male",
        logId: "",
    },
    kycType: "info",
    clientUuid: "",
    ckyc: null,
    gpi: {
        isClaim: false,
        natureOfClaim: "",
        loss: 0,
        currentStatus: "",
        remedialMeasures: "",

        saleInIndia: 0,
        saleInUsCa: 0,
        saleInEuAu: 0,
        salesInOthers: 0,
    },
    limitOfliability: 0,
    planDb: [],
};

// create slice
const kmdSlice = createSlice({
    name: "home",
    initialState: initialState as KmdState,
    reducers: {
        setKmdIsKyc: (
            state: KmdState,
            action: PayloadAction<KmdState["isKyc"]>
        ) => {
            state.isKyc = action.payload;
        },

        setKmdAdressRequired: (
            state: KmdState,
            action: PayloadAction<KmdState["adressRequired"]>
        ) => {
            state.adressRequired = action.payload;
        },
        setKmdComment: (
            state: KmdState,
            action: PayloadAction<KmdState["comment"]>
        ) => {
            state.comment = action.payload;
        },
        setKmdCommentList: (
            state: KmdState,
            action: PayloadAction<KmdState["commentList"]>
        ) => {
            state.commentList = action.payload;
        },
        setKmdPlanDb: (
            state: KmdState,
            action: PayloadAction<KmdState["planDb"]>
        ) => {
            state.planDb = action.payload;
        },
        setMasterUuid: (
            state: KmdState,
            action: PayloadAction<KmdState["masterUuid"]>
        ) => {
            state.masterUuid = action.payload;
        },
        setPolicyType: (
            state: KmdState,
            action: PayloadAction<KmdState["policyType"]>
        ) => {
            state.policyType = action.payload;
        },
        setSumInsured: (
            state: KmdState,
            action: PayloadAction<KmdState["sumInsured"]>
        ) => {
            state.sumInsured = action.payload;
        },

        setPlan: (state: KmdState, action: PayloadAction<KmdState["plan"]>) => {
            state.plan = action.payload;
        },

        setSumInsuredList: (
            state: KmdState,
            action: PayloadAction<KmdState["sumInsuredList"]>
        ) => {
            state.sumInsuredList = action.payload;
        },

        setPlanList: (
            state: KmdState,
            action: PayloadAction<KmdState["planList"]>
        ) => {
            state.planList = action.payload;
        },

        setValidation: (
            state: KmdState,
            action: PayloadAction<KmdState["validation"]>
        ) => {
            state.validation = action.payload;
        },

        setFamily: (
            state: KmdState,
            action: PayloadAction<KmdState["family"]>
        ) => {
            state.family = action.payload;
        },

        setMembers: (
            state: KmdState,
            action: PayloadAction<KmdState["members"]>
        ) => {
            state.members = action.payload;
        },

        setPremium: (
            state: KmdState,
            action: PayloadAction<KmdState["premium"]>
        ) => {
            state.premium = action.payload;
        },

        setMemberInfo: (
            state: KmdState,
            action: PayloadAction<KmdState["memberInfo"]>
        ) => {
            state.memberInfo = action.payload;
        },

        setKyc: (state: KmdState, action: PayloadAction<KmdState["kyc"]>) => {
            state.kyc = action.payload;
        },

        setKycType: (
            state: KmdState,
            action: PayloadAction<KmdState["kycType"]>
        ) => {
            state.kycType = action.payload;
        },

        setEmployeeEdit: (
            state: KmdState,
            action: PayloadAction<KmdState["employeeEdit"]>
        ) => {
            state.employeeEdit = action.payload;
        },

        setClientUuid: (
            state: KmdState,
            action: PayloadAction<KmdState["clientUuid"]>
        ) => {
            state.clientUuid = action.payload;
        },

        setCkyc: (state: KmdState, action: PayloadAction<KmdState["ckyc"]>) => {
            state.ckyc = action.payload;
        },

        setGpi: (state: KmdState, action: PayloadAction<KmdState["gpi"]>) => {
            debugger;
            state.gpi = action.payload;
        },
        setKmdLimitOfLiability: (
            state: KmdState,
            action: PayloadAction<KmdState["limitOfliability"]>
        ) => {
            state.limitOfliability = action.payload;
        },
    },
});

export const {
    setKmdIsKyc,
    setKmdAdressRequired,
    setKmdComment,
    setKmdCommentList,
    setKmdPlanDb,
    setMasterUuid,
    setMembers,
    setSumInsured,
    setPlan,
    setSumInsuredList,
    setPlanList,
    setValidation,
    setPremium,
    setMemberInfo,
    setFamily,
    setPolicyType,
    setKyc,
    setKycType,
    setEmployeeEdit,
    setClientUuid,
    setCkyc,
    setGpi,
} = kmdSlice.actions;

export default kmdSlice.reducer;
