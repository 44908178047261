import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import service from "../../util/axiosConfig";
import DownloadCenterConfig from "./downloadCenterConfig";
import Table from "../../components/Table/Table2";
import { showAlert } from "../../components/CustomAlert/CustomAlert";
import advService from "../../util/advService";
import {
    DELETE_FILE_DOWNLOAD_CENTER,
    FILE_UPLOAD_DOWNLOAD_CENTER_POST,
    GET_FILE_DOWNLOAD_CENTER,
    EDIT_FILE_DOWNLOAD_CENTER,
} from "../../util/newconfig";
import { useStateValue } from "../../util/stateProvider";
import "./DownloadCenter.scss";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../types";
import { Input } from "antd";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { HOME, HOME_EMPLOYEE } from "../../util/config";
import { useDispatch, useSelector } from "react-redux";
import { setHomeDto } from "../../features/home/homeSlice";
import { FileDataType } from "../../types/DownloadCenter/FileDataType";
import DeleteDocumentModal from "./DeleteDocumentModal";
import EditDocumentModal from "./EditDocumentModal";
import FileUploadModal from "./FileUploadModal";
import { CustomFile } from "../../types/claims";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Iconify from "../../components/iconify";

import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { checkIphone } from "../../util/function/checkIphone";
import openIphoneLink from "../../util/function/openIphoneLink";

const Search =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/search.svg";

const DownloadCenterV2 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery("(max-width: 768px)");

    const uuid = sessionStorage.getItem("uuid");
    const [{ membersPage, leadInfo }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(true);
    const reducerDispatch = useDispatch();
    const { homeDto } = useSelector((state: any) => state.home);
    const [tableLoader, setTableLoader] = useState(true);
    const [changeTabToAll, setChangeTabToAll] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [opeEditModal, setOpenEditModal] = useState(false);
    const [fileDataList, setfileDataList] = useState<FileDataType[]>([]);
    const [nextPage, setNextPage] = useState([]);
    const [prevPage, setPrevPage] = useState([]);
    const [images, setImages] = useState<File[] | Blob[] | CustomFile[]>([]);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [searchInput, setsearchInput] = useState("");
    const [selectedRow, setselectedRow] = useState<FileDataType | null>(null);
    const [page, setpage] = useState(0);
    const navigate = useNavigate();
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    const [functions, setFunctions] = useState({
        downloadFile: (e: FileDataType) => {
            console.log("ee", e);
            handleDownload(e);
        },
        editFile: (row: FileDataType) => {
            setOpenEditModal(true);
            setselectedRow(row);
        },
        deleteFile: (row: FileDataType) => {
            setConfirmationModal(true);
            setselectedRow(row);
        },
    });

    const newConfig = DownloadCenterConfig;
    if (newConfig.search) {
        newConfig.search.run = (query, column) => {
            setsearchInput(query.trim());
        };
    }
    if (newConfig.searchEmptyCall) {
        newConfig.searchEmptyCall.run = () => {
            setsearchInput("");
        };
    }
    useEffect(() => {
        if (
            user.userType === "ADMIN" ||
            user.userType === "AGENT" ||
            user.userType === "EMPLOYER"
        ) {
            newConfig.upload = {
                title: "Upload File",
                run: () => setOpenUploadModal(true),
            };
        }
    }, [user.userType]);

    const fetchFiles = useCallback(() => {
        let config = { ...GET_FILE_DOWNLOAD_CENTER };
        config.url = config.url
            .replace("{uuid}", sessionStorage.getItem("uuid") || "")
            .replace(
                "{employerORemployee}",
                user.userType === "ADMIN" ||
                    user.userType === "AGENT" ||
                    user.userType === "EMPLOYER"
                    ? "employer"
                    : "employee"
            );
        advService(
            config,
            {},
            ({ data }: AxiosResponse<JavaResponse<FileDataType[]>>) => {
                if (data?.success) {
                    setfileDataList(data.response);
                    setTableLoader(false);
                    setLoading(false);
                }
            }
        );
    }, []);

    useEffect(() => {
        if (homeDto === null) {
            const url =
                user.userType === "EMPLOYEE" || user.userType === "USER"
                    ? `${HOME_EMPLOYEE}${
                          user.actualUserType !== "EMPLOYEE" &&
                          user.actualUserType !== "USER"
                              ? sessionStorage.getItem("uuid")
                              : ""
                      }`
                    : HOME + uuid;
            service.get(url).then((res) => {
                if (res?.data.success) {
                    reducerDispatch(setHomeDto(res.data.response));
                    if (res.data.response?.d_home_download_center) {
                        fetchFiles();
                    } else {
                        navigate("/home");
                    }
                }
            });
        } else {
            if (homeDto?.d_home_download_center) {
                fetchFiles();
            } else {
                navigate("/home");
            }
        }
    }, []);

    const uploadFiles = (nameValues: { [key: string]: string }) => {
        let imagestoUpload = [...images];
        if (imagestoUpload.length > 0) {
            imagestoUpload.forEach((image, index) => {
                let formData = new FormData();
                formData.append("file", image);
                formData.append(
                    "documentEntityMapping",
                    JSON.stringify({
                        fileName:
                            nameValues[
                                // @ts-ignore
                                image.name
                                    .split(".")
                                    .join("_")
                                    .split(" ")
                                    .join("_")
                            ],
                    })
                );
                let config = { ...FILE_UPLOAD_DOWNLOAD_CENTER_POST };
                config.url = config.url.replace(
                    "{uuid}",
                    sessionStorage.getItem("uuid") as string
                );
                service
                    .postUpload(config.url, formData)
                    .then((res: AxiosResponse<JavaResponse<{}>>) => {
                        if (res?.data?.success) {
                            showAlert(
                                dispatch,
                                true,
                                "success",
                                "Files uploaded successfully"
                            );
                            if (index === imagestoUpload.length - 1) {
                                setImages([]);
                                fetchFiles();
                                setOpenUploadModal(false);
                            }
                        } else {
                            setImages([]);
                            showAlert(
                                dispatch,
                                true,
                                "error",
                                "Error uploading files"
                            );
                        }
                    })
                    .catch((err) => {
                        setImages([]);
                    });
            });
        } else {
            showAlert(
                dispatch,
                true,
                "error",
                "Please select a file to upload"
            );
        }
    };

    const handleDeleteConfirmation = () => {
        if (selectedRow) {
            let config = { ...DELETE_FILE_DOWNLOAD_CENTER };
            config.url = config.url.replace(
                "{uuid}",
                sessionStorage.getItem("uuid") || ""
            );
            config.url = config.url.replace("{fileId}", selectedRow.uuid);
            advService(config, {}, (res: AxiosResponse<JavaResponse<{}>>) => {
                if (res.data.success) {
                    const id = selectedRow?.uuid;
                    const updatedFiles = fileDataList?.filter(
                        (file: FileDataType) => file?.uuid !== id
                    );
                    if (res.data.success) {
                        setfileDataList(updatedFiles);
                    } else {
                        // throw Error;
                    }
                    setselectedRow(null);
                    setConfirmationModal(false);
                }
            });
        }
    };

    const handleEdit = (data: { fileName: string }) => {
        let config = { ...EDIT_FILE_DOWNLOAD_CENTER };
        config.url = config.url.replace(
            "{uuid}",
            sessionStorage.getItem("uuid") || ""
        );
        advService(
            config,
            {
                uuid: selectedRow?.uuid,
                fileName: data.fileName,
            },
            (res: AxiosResponse<JavaResponse<{}>>) => {
                if (res?.data?.success) {
                    setOpenEditModal(false);
                    fetchFiles();
                }
            }
        );
    };

    const handleDownload = (row: FileDataType) => {
        // open link in new tab
        // window.open(row.path);
        checkIphone()
        ? openIphoneLink(row.path)
        : window.open(
            row.path,
                "_blank"
            );
    };

    return (
        <Box
            sx={{
                // height: "450px",

                paddingTop: { sm: "33px", xs: "" },
                background: `url(${
                    isMobile ? benifitMobilebg : benifitDesktopbg
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                // backgroundPosition: "center",
                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    {/* <Iconify
                        icon="material-symbols:arrow-left-alt"
                        sx={{
                            // marginTop: "5px",
                            color: theme.palette.grey[800],
                            marginBottom: { xs: "100px", sm: "0" },
                        }}
                        width={"32px"}
                        height={"32px"}
                        onClick={() => navigate("/home")}
                    /> */}

                    <KeyboardBackspaceIcon
                        sx={{
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                            width: "32px",
                            height: "32px",
                        }}
                        onClick={() => navigate("/home")}
                    />

                    <Stack direction="column" gap="4px">
                        <Typography
                            variant="h4"
                            sx={{ color: theme.palette.grey[800] }}
                        >
                            {" "}
                            Download Center{" "}
                        </Typography>
                        <Typography
                            variant="body3"
                            sx={{
                                width: { xs: "196px", sm: "auto" },
                                color: theme.palette.text.primary,
                            }}
                        ></Typography>
                    </Stack>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    position: "relative",

                    flexDirection: { xs: "column", sm: "row" },

                    padding: { sm: "24px", xs: "20px" },
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    gap: "24px",
                    flexWrap: "wrap",
                    borderRadius: { sm: "40px", xs: "24px 24px 0 0" },

                    border: "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "",
                    },
                    margin: "auto",
                }}
            >
                <div style={{ minHeight: "400px", width:'100%' }} className="downloadcenter">
                    <div
                        className={`progressbar-container height-auto ${
                            tableLoader ? "" : "hide"
                        }`}
                    ></div>
                    <>
                        <Table
                            data={fileDataList
                                .filter((file) =>
                                    searchInput.length > 0
                                        ? file.fileName
                                              .toLowerCase()
                                              .includes(
                                                  searchInput.toLowerCase()
                                              )
                                        : true
                                )
                                .slice(page * 10, (page + 1) * 10)}
                            config={newConfig}
                            paginationData={{
                                nextPage,
                                prevPage,
                                setPrevPage,
                            }}
                            loading={loading}
                            functions={functions}
                            setChangeTabToAll={setChangeTabToAll}
                            changeTabToAll={changeTabToAll}
                            demography={1}
                        />
                        {fileDataList.filter((file) =>
                            searchInput.length > 0
                                ? file.fileName
                                      .toLowerCase()
                                      .includes(searchInput.toLowerCase())
                                : true
                        ).length > 10 && (
                            <CustomPagination
                                nextPage={
                                    page <
                                    Math.floor(
                                        fileDataList.filter((file) =>
                                            searchInput.length > 0
                                                ? file.fileName
                                                      .toLowerCase()
                                                      .includes(
                                                          searchInput.toLowerCase()
                                                      )
                                                : true
                                        ).length / 10
                                    )
                                        ? [1, 2]
                                        : 0
                                }
                                prevPage={page > 0 ? [1, 2] : 0}
                                page={page}
                                pageChange={(a: number, b: number) =>
                                    setpage(a)
                                }
                                tab={[1, 2, 3]}
                            />
                        )}
                    </>
                </div>
                <FileUploadModal
                    openUploadModal={openUploadModal}
                    setOpenUploadModal={setOpenUploadModal}
                    files={images}
                    setFiles={setImages}
                    uploadFiles={uploadFiles}
                />
                <EditDocumentModal
                    opeEditModal={opeEditModal}
                    setOpenEditModal={setOpenEditModal}
                    selectedRow={selectedRow}
                    handleEdit={handleEdit}
                />

                <DeleteDocumentModal
                    confirmationModal={confirmationModal}
                    setConfirmationModal={setConfirmationModal}
                    handleDeleteConfirmation={handleDeleteConfirmation}
                    selectedRow={selectedRow}
                />
            </Box>
        </Box>
    );

    return (
        <>
            <div className="tableContainer">
                <div className="top-metadata-container">
                    <h1
                        className="m0 normal noWrap"
                        style={{ marginTop: "auto" }}
                    >
                        Downloads Center
                    </h1>
                    <div className="logo_container">
                        <div
                            className="partner-logo"
                            style={{ height: 65, borderLeft: 0 }}
                        ></div>
                    </div>
                </div>

                <hr className="topSeperator" />
                <div className="employeeDiv mb20"></div>
            </div>
        </>
    );
};

export default DownloadCenterV2;
