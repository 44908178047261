import React, { useEffect } from "react";
import KmdInvoice from "./kmdInvoice";
import "./kmdStep2.scss";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import MyText from "../../../components/Input/MyText";
import Dropdown from "../../../components/Input/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { changeDateFormat } from "../../../util/date";
import alert from "../../../util/alerts";

const bannerImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Super_TopUp/superIllustration.png";

const relationShipList = [
    "SPOUSE",
    "SON",
    "DAUGHTER",
    "FATHER",
    "MOTHER",
    "BROTHER",
    "SISTER",
    "GRAND FATHER",
    "GRAND MOTHER",
    "GRAND SON",
    "GRAND DAUGHTER",
    "FATHER IN LAW",
    "MOTHER IN LAW",
    "SISTER IN LAW",
    "BROTHER IN LAW",
    "SON IN LAW",
    "DAUGHTER IN LAW",
];

interface Props {
    submit: (val: {
        // email: string;
        name: string;
        dob: string;
        phone: string;
        addressLine1: string;
        addressLine2: string;
        pincode: string;
        nomineeName: string;
        nomineeRelation: string;
        addressUuid: string;
        city: string;
        nomineeDob: string;
    }) => void;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const KmdStep2: React.FC<Props> = ({ submit, setCurrentStep }: Props) => {
    const { memberInfo, members, adressRequired, policyType } = useSelector(
        (state: RootState) => state.kmd
    );
    const reducerDispatch = useDispatch();
    const formikRef = React.useRef<
        FormikProps<{
            email: string;
            phone: string;
            nomineeName: string;
            address: string;
            pincode: string;
        }>
    >(null);

    const [relationShip, setRelationShip] = React.useState<string>();

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    useEffect(() => {
        setRelationShip(memberInfo.nomineeRelation);
    }, []);

    const handleSubmit = (values: {
        phone: string;
        nomineeName: string;
        pincode: string;
        nomineeDob: string;
    }) => {
        console.log("submitting");
        if (!relationShip) {
            alert("Select nominee relationship", "error");
            return;
        }
        submit({
            name: memberInfo.name,
            dob: memberInfo.dob,
            phone: values.phone,
            addressLine1: memberInfo.addressLine1,
            addressLine2: memberInfo.addressLine2,
            pincode: values.pincode,
            nomineeName: values.nomineeName,
            nomineeRelation: relationShip,
            addressUuid: memberInfo.addressUuid,
            city: memberInfo.city,
            nomineeDob: values.nomineeDob,
        });
    };
    return (
        <div className="kmdStep2">
            <div className="kmdStep2_header mb40">
                <IconButton
                    className="p0I"
                    onClick={() => {
                        setCurrentStep(1);
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <h1 className="m0I ml20I fontSize_26">Personal Information</h1>
            </div>
            <div className="kmdStep2_banner">
                <img src={bannerImage} alt="" className="pt25" />
            </div>
            <div className="kmdStep2_dataContainer">
                <Formik
                    ref={formikRef}
                    initialValues={{
                        name: members.find(
                            (member) =>
                                member.relationShip === "Employee" ||
                                member.relationShip === "Self"
                        )?.name,
                        dob: changeDateFormat(
                            "yyyy-mm-dd",
                            "dd-mmm-yyyy",
                            members.find(
                                (member) =>
                                    member.relationShip === "Employee" ||
                                    member.relationShip === "Self"
                            )?.dob
                        ),
                        // email: memberInfo.email,
                        phone: memberInfo.phone,
                        nomineeName: memberInfo.nomineeName,
                        address:
                            memberInfo.addressLine1 +
                            " " +
                            memberInfo.addressLine2,
                        pincode: memberInfo.pincode,
                        nomineeDob: memberInfo.nomineeDob,
                    }}
                    validationSchema={Yup.object({
                        // phone: Yup.string()
                        //     .required("This field can't be empty")
                        //     .min(10)
                        //     .max(10)
                        //     .matches(
                        //         /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                        //         "Phone number is not valid"
                        //     )
                        //     .test(
                        //         "phone",
                        //         "Phonenumber is not valid",
                        //         (val) => {
                        //             return (val?.[0] ?? "") > "5";
                        //         }
                        //     ),
                        // email: Yup.string()
                        //     .email("Invalid email address")
                        //     .required("This field can't be empty"),
                        nomineeName: Yup.string()
                            .required("This field can't be empty")
                            .matches(/^[a-zA-Z_ ]*$/, "Invalid Name"),
                        // address: Yup.string()
                        //     .required("Address is required is required")
                        //     .test(
                        //         "len",
                        //         "Address too large",
                        //         (val) => (val?.length || 0) < 100
                        //     ),
                        // pincode: Yup.string()
                        //     .required("This field can't be empty")
                        //     .min(6)
                        //     .max(6),
                        ...(policyType === "GMC" && {
                            nomineeDob: Yup.string()
                                .required("This field can't be empty")
                                .test(
                                    "dob",
                                    "Nominee should be atleast 18 years old",
                                    (val) => {
                                        // check whether the age is above 18 or not
                                        let dob = new Date(val as string);
                                        let today = new Date();
                                        let age =
                                            today.getFullYear() -
                                            dob.getFullYear();
                                        let m =
                                            today.getMonth() - dob.getMonth();
                                        if (
                                            m < 0 ||
                                            (m === 0 &&
                                                today.getDate() < dob.getDate())
                                        ) {
                                            age--;
                                        }

                                        return age >= 18;
                                    }
                                ),
                        }),
                    })}
                    onSubmit={handleSubmit}
                >
                    <>
                        <div className="kmdStep2_left">
                            <Form>
                                <div className="kmdStep2_form  p40">
                                    <h1 className="fontSize_20">
                                        Personal Information
                                        <Tooltip
                                            title="If you want to update your personal
                                            information, please get in touch
                                            with the Council of Architecture"
                                        >
                                            <IconButton>
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </h1>

                                    <MyText
                                        label="Name"
                                        name="name"
                                        type="text"
                                        placeholder="name"
                                        className=""
                                        readOnly={true}
                                        onChange={() => {}}
                                    />
                                    <MyText
                                        label="Date of Birth"
                                        name="dob"
                                        type="text"
                                        placeholder="dob"
                                        className=""
                                        readOnly={true}
                                        onChange={() => {}}
                                    />
                                    <MyText
                                        label="Phone Number"
                                        name="phone"
                                        type="text"
                                        className=""
                                        readOnly={true}
                                        placeholder="Phone Number"
                                        onChange={() => {}}
                                    />
                                    {/* <h2 className="subHeading__Super ml-0">Your Address</h2> */}

                                    {adressRequired && (
                                        <>
                                            <Tooltip
                                                title={
                                                    memberInfo.addressLine1 +
                                                    " " +
                                                    memberInfo.addressLine2
                                                }
                                            >
                                                <Button className="m0 p0I">
                                                    <MyText
                                                        label="Address"
                                                        name="address"
                                                        type="text"
                                                        placeholder="Address"
                                                        className=""
                                                        readOnly={true}
                                                        onChange={() => {}}
                                                    />
                                                </Button>
                                            </Tooltip>

                                            <MyText
                                                label="Pincode"
                                                name="pincode"
                                                type="number"
                                                placeholder="Pincode"
                                                className=""
                                                readOnly={true}
                                                onChange={() => {}}
                                            />
                                        </>
                                    )}
                                    <h1 className="fontSize_20 mt40">
                                        Nominee Details
                                    </h1>

                                    <MyText
                                        label="Nominee Name"
                                        name="nomineeName"
                                        type="text"
                                        placeholder="Name"
                                        className=""
                                        onChange={() => {}}
                                    />
                                    {policyType === "GMC" && (
                                        <MyText
                                            label="Nominee Dob"
                                            name="nomineeDob"
                                            type="date"
                                            placeholder="Name"
                                            className=""
                                            onChange={() => {}}
                                        />
                                    )}
                                    <div className="custom-form-group">
                                        <Dropdown
                                            title="Relationship"
                                            name="nomineeRelationship"
                                            className=" dropDownSuper colorUpdate"
                                            defaultVal={relationShip}
                                            items={relationShipList.map(
                                                (relation) => ({
                                                    id: relation,
                                                    name: relation,
                                                })
                                            )}
                                            change={(val: any) => {
                                                setRelationShip(val);
                                            }}
                                        />
                                    </div>
                                    <button
                                        className="buttonPrimary mobileOnly mt15 W100"
                                        type="submit"
                                    >
                                        Next
                                    </button>
                                </div>
                            </Form>
                        </div>
                        <div className="kmdStep2_right">
                            <FormContainer handleSubmit={handleSubmit} />
                            {/* <KmdInvoice /> */}
                        </div>
                    </>
                </Formik>
            </div>
        </div>
    );
};

export default KmdStep2;

const FormContainer = ({ handleSubmit }: any) => {
    let { submitForm } = useFormikContext();
    return <KmdInvoice submitForm={submitForm} />;
};
