import "./addon.scss";
import {
    Box,
    BoxProps,
    LinearProgress,
    Typography,
    useTheme,
} from "@mui/material";

interface Props {
    config: { [key: string]: any };
    className?: string;
    sx?: BoxProps["sx"];
    from?: string;
    onClick?: () => void;
}

function NewAddon({ config, className, sx, from, onClick }: Props) {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "white",
                    borderRadius: from === "benefits" ? "24px" : "32px",
                    cursor: "pointer",
                    padding: from === "benefits" ? "16px" : "16px 12px",
                    flexDirection: from === "benefits" ? "row" : "column",

                    display: "flex",
                    gap: from === "benefits" ? "24px" : "8px",
                    alignSelf: "stretch",
                    border: `1px solid ${theme.palette.grey[300]}`,
                    height: from === "benefits" ? "auto" : "fit-content",
                    ...sx,
                }}
                className={`${className}`}
                onClick={onClick}
            >
                {/* <LinearProgress /> */}

                <img
                    src={config?.brandImage}
                    alt="Brand"
                    style={{
                        height: from === "benefits" ? "64px" : "48px",
                        width: from === "benefits" ? "64px" : "48px",
                        padding: from === "benefits" ? "8px" : "6px",
                        objectFit: "contain",
                    }}
                />

                <Box
                    sx={{
                        gap: from === "benefits" ? "0px" : "4px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        alignSelf: "stretch",
                    }}
                >
                    <Typography
                        sx={{
                            minHeight: "16px",
                            margin: "0",
                            color:
                                from === "benefits"
                                    ? theme.palette.text.primary
                                    : theme.palette.text.secondary,
                        }}
                        variant={from === "benefits" ? "body2" : "mobileLabel"}
                    >
                        {from === "benefits"
                            ? config?.title
                            : config?.partner
                                  ?.replace(/([A-Z])/g, " $1")
                                  .toUpperCase()}
                    </Typography>

                    <Typography
                        variant={
                            from === "benefits" ? "subtitle2" : "subtitle1"
                        }
                        sx={{
                            minHeight: "24px",
                            margin: "0",
                            color: theme.palette.text.primary,
                        }}
                    >
                        {from === "benefits" ? config?.subtitle : config?.title}
                    </Typography>

                    <Typography
                        variant="body3"
                        className="mb-0"
                        sx={{
                            minHeight: from === "benefits" ? "" : "36px",
                            margin: "0",
                            color:
                                from === "benefits"
                                    ? theme.palette.text.secondary
                                    : theme.palette.text.primary,
                        }}
                    >
                        {from === "benefits"
                            ? config?.partner
                                  ?.replace(/([A-Z])/g, " $1")
                                  .toUpperCase()
                            : config?.subtitle}
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

export default NewAddon;
