import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Chip,
    Divider,
    Drawer,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import Iconify from "../../components/iconify";
import copyFunction from "../../util/function/copyFunction";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { theme } from "antd";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ClaimContactCard = ({
    open,
    handleClaimContactOpen,
}: {
    open: boolean;
    handleClaimContactOpen: () => void;
}) => {
    const isMobile = useMediaQuery("(max-width:600px)");
    const [showEscalate, setShowEscalate] = React.useState(false);
    const customerCareData = useSelector(
        (state: RootState) => state.customerCare.customerCareData
    );

    const lead = JSON.parse(sessionStorage.getItem("lead") || "{}");
    // const escalationData = [
    //     {
    //         name: "Deepak Kumar",
    //         number: "+91 6264849591",
    //         email: "deepak@care.com",
    //         level: "Level 1",
    //     },
    //     {
    //         name: "Rajveer",
    //         number: "+91 6264849591",
    //         email: "rajveer@care.com",
    //         level: "Level 2",
    //     },
    //     {
    //         name: "Rajveer",
    //         number: "+91 6264849591",
    //         email: "rajbeeer@gmail",
    //         level: "Level 3",
    //     },
    // ];
    return (
        <Drawer
            open={open}
            onClose={handleClaimContactOpen}
            anchor={isMobile ? "bottom" : "right"}
            sx={{
                "& .MuiDrawer-paper": {
                    padding: "24px 20px;",
                    display: "flex",
                    width: isMobile ? "100%" : "375px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    flexShrink: 0,
                    borderRadius: {
                        xs: "24px 24px 0px 0px",
                        sm: "24px 0px 0px 24px",
                    },
                    backgroundColor: "#FFF",
                    gap: "24px",
                },
            }}
        >
            {!showEscalate ? (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "16px",
                            alignSelf: "stretch",
                            width: "100%",
                        }}
                    >
                        <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            width={"100%"}
                        >
                            <Stack flexDirection={"column"} spacing={"4px"}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.text.primary,
                                    }}
                                    margin={0}
                                >
                                    {customerCareData?.name}
                                </Typography>
                                <Typography
                                    variant="body3"
                                    color={(theme) =>
                                        theme.palette.text.secondary
                                    }
                                >
                                    {`Claim representative at ${lead?.companyName} `}
                                </Typography>
                            </Stack>
                            <Close
                                onClick={handleClaimContactOpen}
                                style={{ cursor: "pointer" }}
                                fontSize="medium"
                            />
                        </Stack>
                        <Stack
                            flexDirection={"column"}
                            gap={"4px"}
                            width={"100%"}
                        >
                            {[
                                customerCareData?.phone,
                                customerCareData?.email,
                            ].map((item) => (
                                <Stack flexDirection={"column"} gap={"4px"}>
                                    {item?.split(",").map((item) => (
                                        <Stack
                                            flexDirection={"row"}
                                            gap={"8px"}
                                            alignItems={"center"}
                                        >
                                            <Box
                                                sx={{
                                                    width: "36px",
                                                    height: "36px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Iconify
                                                    icon={
                                                        "material-symbols:content-copy-outline"
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                        width: "20px",
                                                        height: "20px",
                                                        // padding: "8px",
                                                        color: "#896BFF",
                                                    }}
                                                    onClick={() => {
                                                        copyFunction(
                                                            item as string
                                                        );
                                                    }}
                                                />
                                            </Box>
                                            <Typography
                                                variant="subtitle2"
                                                color={(theme) =>
                                                    theme.palette.text.primary
                                                }
                                            >
                                                {item}
                                            </Typography>
                                        </Stack>
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                    </Box>
                    <Stack
                        flexDirection={"column"}
                        spacing={"16px"}
                        width={"100%"}
                    >
                        {isMobile && (
                            <a
                                href={`tel:${customerCareData?.phone}`}
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    variant="contained"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.brand.main,
                                        color: "#FFF",
                                        height: "42px",
                                    }}
                                    endIcon={
                                        <Iconify
                                            icon={
                                                "material-symbols:arrow-right-alt"
                                            }
                                            style={{ color: "#FFF" }}
                                        />
                                    }
                                >
                                    <Typography
                                        sx={{
                                            textTransform: "none",
                                        }}
                                        variant="overline"
                                        color={"white"}
                                    >
                                        Call now
                                    </Typography>
                                </Button>
                            </a>
                        )}
                        {(customerCareData?.escalationMatrixDTOS?.length ?? 0) >
                            0 && (
                            <Typography
                                variant="overline"
                                sx={{
                                    color: "#DC2B2B",
                                    textAlign: "center",
                                    width: "100%",
                                    cursor: "pointer",
                                    textTransform: "none",
                                }}
                                onClick={() => {
                                    setShowEscalate(true);
                                }}
                            >
                                Escalate issue
                            </Typography>
                        )}
                    </Stack>
                </>
            ) : (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "16px",
                            alignSelf: "stretch",
                            width: "100%",
                        }}
                    >
                        <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            width={"100%"}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    color: (theme) =>
                                        theme.palette.text.primary,
                                }}
                                margin={0}
                            >
                                Claim escalation
                            </Typography>
                            <Close
                                onClick={handleClaimContactOpen}
                                style={{ cursor: "pointer" }}
                                fontSize="medium"
                            />
                        </Stack>
                        <Box
                            sx={{
                                display: "flex",
                                padding: "16px 15px",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "16px",
                                alignSelf: "stretch",
                                flexDirection: "row",
                                width: "100%",
                                borderRadius: "16px",
                                backgroundColor: "#FFF3E4",
                            }}
                        >
                            <Iconify
                                icon={"material-symbols:warning-rounded"}
                                style={{
                                    color: "#D5691B",
                                    width: "32px",
                                    height: "32px",
                                }}
                            />
                            <Typography
                                variant="overline"
                                sx={{
                                    textTransform: "none",
                                }}
                            >
                                Please follow the given hierarchy only.
                            </Typography>
                        </Box>
                        {customerCareData?.escalationMatrixDTOS?.map(
                            (item, index) => (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignSelf: "stretch",
                                            width: "100%",
                                            gap: "8px",
                                        }}
                                    >
                                        <Stack
                                            flexDirection={"row"}
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                            width={"100%"}
                                        >
                                            <Typography variant="subtitle2">
                                                {item.name}
                                            </Typography>
                                            <Chip
                                                label={
                                                    <Typography
                                                        variant="label"
                                                        sx={{
                                                            color:
                                                                item.level ===
                                                                "1"
                                                                    ? (theme) =>
                                                                          theme
                                                                              .palette
                                                                              .error
                                                                              .dark
                                                                    : item.level ===
                                                                      "2"
                                                                    ? (theme) =>
                                                                          theme
                                                                              .palette
                                                                              .warning
                                                                              .dark
                                                                    : (theme) =>
                                                                          theme
                                                                              .palette
                                                                              .success
                                                                              .dark,
                                                        }}
                                                    >
                                                        {`Level ${item.level}`}
                                                    </Typography>
                                                }
                                                sx={{
                                                    height: "18px",
                                                    width: "fit-content",
                                                    backgroundColor:
                                                        item.level === "1"
                                                            ? (theme) =>
                                                                  theme.palette
                                                                      .error
                                                                      .lighter
                                                            : item.level === "2"
                                                            ? (theme) =>
                                                                  theme.palette
                                                                      .warning
                                                                      .lighter
                                                            : (theme) =>
                                                                  theme.palette
                                                                      .success
                                                                      .lighter,
                                                }}
                                            />
                                        </Stack>
                                        {[
                                            ...item.phone.split(","),
                                            ...item.email.split(","),
                                        ].map((item) => (
                                            <Stack
                                                flexDirection={"column"}
                                                spacing={"16px"}
                                                width={"100%"}
                                            >
                                                <Stack
                                                    flexDirection={"row"}
                                                    gap={"8px"}
                                                    alignItems={"center"}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: "36px",
                                                            height: "36px",
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <Iconify
                                                            icon={
                                                                "material-symbols:content-copy-outline"
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "20px",
                                                                height: "20px",
                                                                // padding: "8px",
                                                                color: "#896BFF",
                                                            }}
                                                            onClick={() => {
                                                                copyFunction(
                                                                    item
                                                                );
                                                            }}
                                                        />
                                                    </Box>
                                                    <Typography
                                                        variant="body2"
                                                        color={(theme) =>
                                                            theme.palette.text
                                                                .primary
                                                        }
                                                    >
                                                        {item}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        ))}
                                    </Box>
                                    {index !==
                                        customerCareData?.escalationMatrixDTOS
                                            ?.length -
                                            1 && (
                                        <Divider
                                            variant="dashed"
                                            sx={{
                                                borderColor: "grey.400",
                                                opacity: 0.3,
                                                marginBottom: "8px",
                                            }}
                                        />
                                    )}
                                </>
                            )
                        )}
                    </Box>
                    <Button
                        variant="outlined"
                        size="small"
                        fullWidth
                        startIcon={
                            <KeyboardBackspaceIcon
                                sx={{
                                    color: (theme) => theme.palette.grey[800],
                                }}
                            />
                        }
                        onClick={() => {
                            setShowEscalate(false);
                        }}
                    >
                        <Typography
                            sx={{
                                textTransform: "none",
                            }}
                            variant="overline"
                        >
                            Back
                        </Typography>
                    </Button>
                </>
            )}
        </Drawer>
    );
};

export default ClaimContactCard;
