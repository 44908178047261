import React, { useEffect, useState } from "react";

import { setMembers, setPolicyType } from "../../features/kmd/kmdSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import SuccessImg from "../../assets/images/icons/Check.png";
import ErrorImg from "../../assets/images/icons/Error.png";
import { Button, Divider } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import "./KmdPaymentComplete.scss";
const iciciLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/icici.svg";

const KmdPiComplete: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    return (
        <div className="kmdPaymentComplete pt32 pb32">
            <div className="kmdPaymentComplete_container p16">
                <img
                    className="kmdPaymentComplete_statusImage mb27 pt32"
                    src={SuccessImg}
                    alt=""
                />

                <img
                    className="kmdPaymentComplete_iciciLogo mb8"
                    src={iciciLogo}
                    alt=""
                />
                <p className="kmdPaymentComplete_DetailText mb8 ">
                    Your request has been noted. We will get back to you. For
                    any queries, email us at gurgaon@kmdastur.com
                </p>
                <Divider className="pt16 pb16" />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        navigate("/home");
                    }}
                    size="large"
                >
                    Go to home
                </Button>
            </div>
        </div>
    );
};

export default KmdPiComplete;
