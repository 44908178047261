/**
 * @description Generate content type for the given file name for pdf, image, and xlsx
 * @param {string} filename - The name of the file
 */

const generateContentType = (filename: string) => {
    const ext = filename.split(".").pop()?.toLocaleLowerCase();
    switch (ext) {
        case "pdf":
            return "application/pdf";
        case "jpg":
        case "jpeg":
            return "image/jpeg";
        case "png":
            return "image/png";
        case "xlsx":
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        default:
            return "application/octet-stream";
    }
};

export default generateContentType;
