import { useEffect, useState } from "react";
import {
    Box,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Otp from "../Otp";
import {
    OTP_POST_WELLNESS,
    OTP_VERIFY_POST_WELLNESS,
} from "../../util/newconfig";
import advService from "../../util/advService";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../types";
import { useStateValue } from "../../util/stateProvider";
import { useInterval } from "../../views/UserAuthentication/Otp/CommonOtp";
import Iconify from "../iconify";
import { useDispatch } from "react-redux";
import { setOpenVerificationModal } from "../../features/home/homeSlice";
import { LoadingButton } from "@mui/lab";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import handleButtonClick from "../../util/functions";
import { useNavigate } from "react-router-dom";

const VerificationView = () => {
    const [inputCompleted, setInputCompleted] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [resendOtpAllowed, setResendOtpAllowed] = useState(false);
    const [resendSeconds, setResendSeconds] = useState(0);
    const [phone, setPhone] = useState("");
    const [step, setStep] = useState<string>("1");
    const [timer, setTimer] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [{}, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false);

    const reducerDispatch = useDispatch();
    const navigate = useNavigate();

    const customerAppTemplateId = useSelector(
        (state: RootState) => state.home.customerTemplateId
    );

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (step === "2") {
            setTimer(true);
        }
    }, [step]);

    useInterval(() => {
        if (!timer) return;
        if (resendSeconds === 30) {
            setResendSeconds(0);
            setResendOtpAllowed(true);
            setTimer(false);
        }
        setResendSeconds((i) => i + 1);
    }, 1000);

    const handleClose = () => {
        reducerDispatch(setOpenVerificationModal(false));
        setStep("1");
        setPhone("");
        setOtp("");
        setOtpError(false);
        setPhoneError(false);
        setResendSeconds(0);
        setTimer(false);
        setResendOtpAllowed(false);
    };

    useEffect(() => {
        if (inputCompleted) {
            setLoading(true);
            let callConfig = { ...OTP_VERIFY_POST_WELLNESS };
            let payload = {
                username: phone,
                phone: true,
                otp: Number(otp),
            };
            advService(
                callConfig,
                payload,
                (res: AxiosResponse<JavaResponse<unknown>>) => {
                    if (res.data.success) {
                        dispatch({
                            type: "set-authorization",
                            data: res?.headers?.authorization,
                        });
                        handleButtonClick(
                            customerAppTemplateId,
                            navigate,
                            dispatch,
                            setLoading,
                            () => {
                                handleClose();
                            }
                        );
                    } else {
                        setLoading(false);
                        setOtpError(true);
                        setResendSeconds(0);
                        setTimer(false);
                        setResendOtpAllowed(true);
                    }
                }
            );
        }
        setInputCompleted(false);
    }, [inputCompleted, otp, phone, dispatch]);

    const resendOtp = () => {
        setOtpError(false);
        if (resendOtpAllowed) {
            let config = { ...OTP_POST_WELLNESS };
            let payload = {
                username: phone,
                phone: true,
            };

            advService(config, payload, (_res: unknown) => {
                setTimer(true);
                setResendOtpAllowed(false);
            });
        }
    };

    const handleSendOtp = () => {
        if (phone.length !== 10) {
            setPhoneError(true);
            return;
        }
        setLoading(true);
        let config = {
            ...OTP_POST_WELLNESS,
        };
        let payload = {
            username: phone,
            phone: true,
        };
        advService(
            config,
            payload,
            (res: AxiosResponse<JavaResponse<unknown>>) => {
                if (res?.data?.success) {
                    setStep("2");
                }
                setLoading(false);
            }
        );
    };

    return (
        <>
            {isMobile && (
                <KeyboardBackspaceIcon
                    sx={{
                        color: theme.palette.grey[800],
                        cursor: "pointer",
                        width: "32px",
                        height: "32px",
                        marginBottom: "24px",
                    }}
                    onClick={handleClose}
                />
            )}
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: "40px", sm: "24px" },
                }}
            >
                <Stack
                    flexDirection={"row"}
                    width={"100%"}
                    justifyContent={"space-between"}
                >
                    <Stack
                        flexDirection={"column"}
                        gap={{ xs: "16px", sm: "8px" }}
                        justifyContent={{ xs: "center", sm: "flex-start" }}
                        alignItems={{ xs: "center", sm: "flex-start" }}
                    >
                        {isMobile && (
                            <Box
                                sx={{
                                    display: "flex",
                                    width: "80px",
                                    height: "80px",
                                    padding: "20px",
                                    justifyContent: "center",
                                    alignItems: "center",

                                    borderRadius: "500px",
                                    border: "1px solid rgba(255, 255, 255, 0.00)",
                                    background: "#F8F7FA",
                                }}
                            >
                                <Iconify
                                    icon={"mdi:phone-outline"}
                                    width={"40px"}
                                    height={"40px"}
                                    color={"#0C1230"}
                                />
                            </Box>
                        )}
                        <Typography
                            variant={isMobile ? "h4" : "h5"}
                            color={(theme) => theme.palette.text.primary}
                            textAlign={"center"}
                        >
                            Hey, verify your number to continue
                        </Typography>
                        <Typography
                            variant="body3"
                            color={(theme) => theme.palette.text.primary}
                            textAlign={"center"}
                        >
                            A four digit OTP will be sent to the provided phone
                            number. Please enter OTP to verify
                        </Typography>
                    </Stack>
                    {!isMobile && (
                        <Iconify
                            icon={"material-symbols:close"}
                            width={"24px"}
                            height={"24px"}
                            color={"#0C1230"}
                            sx={{
                                cursor: "pointer",
                            }}
                            onClick={handleClose}
                        />
                    )}
                </Stack>

                {step === "1" ? (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: { xs: "40px", sm: "24px" },
                        }}
                    >
                        <TextField
                            label="Phone no."
                            variant="outlined"
                            error={phoneError}
                            sx={{
                                width: isMobile ? "100%" : "335px",
                                marginBottom: { xs: "0px", sm: "76px" },
                            }}
                            helperText={
                                phoneError
                                    ? "Phone number must be a valid 10-digit number"
                                    : ""
                            }
                            onChange={(e) => {
                                const value = e.target.value;
                                setPhone(value);
                                const isValidPhone = /^\d{10}$/.test(value);
                                setPhoneError(!isValidPhone);
                            }}
                        />
                        <LoadingButton
                            variant="contained"
                            sx={{
                                background: theme.palette.brand.main,
                                width: isMobile ? "100%" : "fit-content",
                            }}
                            onClick={handleSendOtp}
                            loading={loading}
                            size="small"
                            disabled={phoneError || phone.length === 0}
                        >
                            <Typography
                                variant="overline"
                                color={
                                    phoneError || phone.length === 0
                                        ? "text.disabled"
                                        : "#fff"
                                }
                            >
                                Send OTP
                            </Typography>
                        </LoadingButton>
                    </Box>
                ) : (
                    <Box>
                        <Box
                            component="form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (otp.length === 4 && Number(otp)) {
                                    setInputCompleted(true);
                                }
                            }}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: { xs: "40px", sm: "24px" },
                                alignItems: "center",
                            }}
                        >
                            <Stack
                                flexDirection={"column"}
                                gap={{ xs: "40px", sm: "16px" }}
                                width={{ xs: "100%", sm: "335px" }}
                                sx={{
                                    marginBottom: { xs: "0px", sm: "44px" },
                                }}
                            >
                                <Otp
                                    onChange={(otp: string) => {
                                        setOtpError(false);
                                        setOtp(otp);
                                        if (otp.length === 4 && Number(otp))
                                            setInputCompleted(true);
                                    }}
                                    error={otpError}
                                />
                                {resendOtpAllowed ? (
                                    <Typography
                                        onClick={resendOtp}
                                        sx={{
                                            cursor: "pointer",

                                            color: theme.palette.brand.main,
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "18px",
                                            textDecorationLine: "underline",
                                            textAlign: "center",
                                        }}
                                    >
                                        Resend OTP
                                    </Typography>
                                ) : (
                                    <Typography
                                        sx={{
                                            textAlign: "center",
                                            color: theme.palette.brand.main,
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "18px",
                                            textDecorationLine: "underline",
                                        }}
                                    >
                                        Resend OTP in 00:
                                        {(String(30 - resendSeconds).length ===
                                        1
                                            ? "0"
                                            : "") + String(30 - resendSeconds)}
                                    </Typography>
                                )}
                            </Stack>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                size="small"
                                loading={loading}
                                disabled={otp.length !== 4 || !Number(otp)}
                                sx={{
                                    background: theme.palette.brand.main,
                                    width: isMobile ? "100%" : "fit-content",
                                }}
                            >
                                <Typography
                                    variant="overline"
                                    color={
                                        otp.length !== 4 || !Number(otp)
                                            ? "text.disabled"
                                            : "#fff"
                                    }
                                >
                                    Submit OTP
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default VerificationView;
