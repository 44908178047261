import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OperationTeamDTO } from "../../types/DTO/claimsOperationTeam";

interface OperationTeamState {
  operationTeamData: OperationTeamDTO | null;
}

const initialState: OperationTeamState = {
  operationTeamData: null,
};

const operationTeamSlice = createSlice({
  name: "operationTeam",
  initialState,
  reducers: {
    setOperationTeamData: (state: OperationTeamState, action: PayloadAction<OperationTeamState['operationTeamData']>) => {
          state.operationTeamData = action.payload;
        },
  },
});

export const { setOperationTeamData } = operationTeamSlice.actions;

export default operationTeamSlice.reducer;