import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import "./kmdInvoice.scss";
import { useFormikContext } from "formik";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const logo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/icici.svg";

interface InvoiceProps {
    className?: string;
    submitForm?: any;
    loader?: boolean;
    // insideForm?: boolean;
}

const KmdInvoice: React.FC<InvoiceProps> = ({
    className,
    submitForm,
    loader,
    // insideForm = false,
}) => {
    const { sumInsured, premium, plan, planDb } = useSelector(
        (state: RootState) => state.kmd
    );
    // let submitForm: (() => Promise<void>) & (() => Promise<any>);
    // if (insideForm) {
    //     submitForm = useFormikContext().submitForm;
    // }

    return (
        <div className={`KmdInvoice ${className || ""}`}>
            <img
                src={logo}
                alt=""
                className="KmdInvoice_icici pt15 pl15 pr15 pb8"
            />
            {/* <h2 className="fontWeight_600">KMD</h2> */}
            <div className="KmdInvoice_content pl15 pr15 pb4">
                <div className="fontColor_dark fontWeight_500">Sum Insured</div>
                <div className="fontColor_dark">
                    <span className="rupee">₹</span>{" "}
                    {sumInsured?.toLocaleString("en-IN") ||
                        planDb
                            .find((p) => p.name === plan)
                            ?.sumInsured.toLocaleString("en-IN")}
                </div>
            </div>
            <div className="KmdInvoice_content pl15 pr15 pb4 pt4">
                <div className="fontColor_dark fontWeight_500">Net Premium</div>
                <div className="fontColor_dark">
                    <span className="rupee">₹</span>{" "}
                    {premium.toLocaleString("en-IN")}
                </div>
            </div>
            <div className="KmdInvoice_content pl15 pr15 pb4 pt4">
                <div className="fontColor_dark fontWeight_500">
                    G.S.T. (18%)
                </div>
                <div className="fontColor_dark">
                    <span className="rupee">₹</span>{" "}
                    {Math.ceil(premium * 0.18).toLocaleString("en-IN")}
                </div>
            </div>
            <div className="KmdInvoice_content KmdInvoice_total pl15 pr15 pb8 pt8">
                <div className="fontColor_dark fontWeight_500">
                    Total Premium
                </div>
                <div className="fontColor_dark">
                    <span className="rupee">₹</span>{" "}
                    {Math.ceil(premium * 1.18).toLocaleString("en-IN")}
                    &nbsp;
                    <span className="KmdInvoice_mobile fontSize_12">
                        {" "}
                        (inc 18% G.S.T.)
                    </span>
                </div>
            </div>
            <button
                className="KmdInvoice_nextButton buttonPrimary ml15 mr15 mb15 pt4"
                onClick={() => {
                    console.log("yo");
                    submitForm();
                }}
                disabled={!submitForm || loader}
            >
                {submitForm ? (
                    "Next"
                ) : (
                    <>
                        Pay <span className="rupee">₹</span>
                        {Math.ceil(premium * 1.18).toLocaleString("en-IN")}
                    </>
                )}
            </button>
        </div>
    );
};

export default KmdInvoice;
