import {
    Box,
    Stack,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";
import NewAddon from "../Home/NewAddon";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import handleButtonClick from "../../util/functions";
import { useDispatch } from "react-redux";
import GenericNotFound from "../NotFound";
const BenefitsPage = () => {
    const [selectedChip, setSelectedChip] = useState("Active");

    const { homeDtoV2, homeDtoV2Loading } = useSelector(
        (state: RootState) => state.home
    );

    const isMobile = useMediaQuery("(max-width: 768px)");

    const handleChipClick = (label: string) => {
        setSelectedChip(label);
    };

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const theme = useTheme();

    const [scrollPositon, setscrollPositon] = useState(0);
    useEffect(() => {
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        const handleScroll = () => {
            setscrollPositon(mainContent.scrollTop);
        };
        mainContent.addEventListener("scroll", handleScroll);
        return () => {
            mainContent.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        console.log("scroll to top");
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    return (
        <Box
            sx={{
                // height: "450px",
                // paddingTop: { sm: "33px", xs: "" },
                ...(!isMobile
                    ? {
                          background: `url(${benifitDesktopbg})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPositionX: "center",
                      }
                    : {}),
                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },

                ...(isMobile
                    ? {
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: `url(${
                                  isMobile ? benifitMobilebg : benifitDesktopbg
                              })`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPositionX: "center",
                              opacity: 1 - Math.min(scrollPositon, 35) / 35,
                              zIndex: 1,
                          },
                      }
                    : {}),
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },
                    ...(isMobile ? { height: "150px", top: 0 } : {}),
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: {
                        xs: "column",
                        sm: "row",
                    },
                    padding: { xs: "24px 16px", sm: "0" },

                    ...(isMobile
                        ? {
                              position: "absolute",
                              top: 0,
                              backgroundColor: `rgba(255, 255, 255, ${
                                  scrollPositon >= 35 ? 1 : 0
                              })`,
                              height: 150 - Math.min(scrollPositon, 70),
                              // transition: 'height 0.5s, background 0.5s',
                              borderBottom: `1px solid rgba(150, 150, 150, ${
                                  Math.min(scrollPositon, 35) / 35
                              })`,
                              overflow: "hidden",
                              zIndex: 100,
                          }
                        : {}),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    {/* <Iconify
                        icon="material-symbols:arrow-left-alt"
                        sx={{
                            // marginTop: "5px",
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                        }}
                        width={"32px"}
                        height={"32px"}
                        onClick={() => navigate("/home")}
                    /> */}
                    <Stack direction="row" alignItems={"center"} gap="8px">
                        <KeyboardBackspaceIcon
                            sx={{
                                color: theme.palette.grey[800],
                                cursor: "pointer",
                                width: "32px",
                                height: "32px",
                            }}
                            onClick={() => navigate("/home")}
                        />
                        {isMobile && (
                            <Typography
                                variant="h6"
                                sx={{
                                    color: theme.palette.text.dark,
                                    opacity: Math.min(scrollPositon, 70) / 70,
                                }}
                            >
                                Wellness & care
                            </Typography>
                        )}
                    </Stack>
                    <Stack direction="column" gap="4px">
                        <Typography
                            variant="h4"
                            sx={
                                isMobile
                                    ? {
                                          color: theme.palette.text.dark,
                                          opacity:
                                              1 -
                                              Math.min(scrollPositon, 70) / 70,
                                      }
                                    : { color: theme.palette.text.dark }
                            }
                        >
                            Wellness & care
                        </Typography>
                        <Typography
                            variant="body3"
                            sx={
                                isMobile
                                    ? {
                                          color: theme.palette.text.primary,
                                          opacity:
                                              1 -
                                              Math.min(scrollPositon, 70) / 70,
                                      }
                                    : { color: theme.palette.text.primary }
                            }
                        >
                            Because we care for you
                        </Typography>
                    </Stack>
                </Box>
                {/* <Box
                        sx={{
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Chip
                            label="Active"
                            onClick={() => handleChipClick("Active")}
                            sx={{
                                backgroundColor:
                                    selectedChip === "Active"
                                        ? "black"
                                        : "white",
                                color:
                                    selectedChip === "Active"
                                        ? "white"
                                        : "default",
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            }}
                        />
                        <Chip
                            label="Buy Benefits"
                            onClick={() => handleChipClick("Buy Benefits")}
                            sx={{
                                backgroundColor:
                                    selectedChip === "Buy Benefits"
                                        ? "black"
                                        : "white",
                                color:
                                    selectedChip === "Buy Benefits"
                                        ? "white"
                                        : "default",
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            }}
                        />
                    </Box> */}
            </Box>
            {isMobile && (
                <img
                    src="https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Wellness+BG+illustration.png"
                    alt="bgLogo"
                    width="126px"
                    height="137px"
                    style={{
                        position: "absolute",
                        bottom: isMobile ? "" : "198px",
                        top: isMobile ? "50px" : "",
                        right: 0,
                        opacity: 1 - Math.min(scrollPositon, 35) / 35,
                    }}
                />
            )}
            <Box
                sx={{
                    display: "grid",
                    width: { xs: "100%", sm: "770px" },
                    padding: isMobile ? "24px 20px" : "24px",
                    gridTemplateColumns: isMobile ? "" : "1fr 1fr",
                    gridAutoFlow: "row",
                    zIndex: 1,

                    // alignItems: "flex-start",
                    // alignContent: "flex-start",
                    // justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: isMobile ? "20px" : "24px",
                    alignSelf: "stretch",
                    borderRadius: { sm: "40px", xs: "24px 24px 0 0" },

                    border: "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "none",
                    },
                    minHeight: { xs: "100%", sm: "456px" },
                    alignContent: "flex-start",
                    margin: "auto",
                }}
            >
                {!homeDtoV2Loading ? (
                    (homeDtoV2?.healthCare?.length ?? 0) > 0 ? (
                        homeDtoV2?.healthCare?.map((item) => (
                            <NewAddon
                                from="benefits"
                                config={{
                                    partner: item.partner,
                                    title: item.headingText,
                                    subtitle: item.subHeadingText,
                                    brandImage: item.imageUrl,
                                }}
                                onClick={() =>
                                    handleButtonClick(
                                        item?.customerAppTemplateId,
                                        navigate,
                                        dispatch
                                    )
                                }
                            />
                        ))
                    ) : (
                        <GenericNotFound
                            title="No benefits found"
                            sx={{
                                gridColumn: "1 / -1",
                            }}
                        />
                    )
                ) : (
                    [1, 2, 3, 4, 5, 6].map((eachSkeleton) => (
                        <Box
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "24px",
                                cursor: "pointer",
                                padding: "16px",
                                flexDirection: "row",

                                display: "flex",
                                gap: "24px",
                                alignSelf: "stretch",
                                border: `1px solid ${theme.palette.grey[300]}`,
                                height: "auto",
                            }}
                        >
                            {/* <LinearProgress /> */}

                            <Skeleton
                                variant="rectangular"
                                style={{
                                    height: "64px",
                                    borderRadius: "8px",
                                    width: "64px",
                                    padding: "8px",
                                    objectFit: "contain",
                                }}
                            />

                            <Box
                                sx={{
                                    gap: "0px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    alignSelf: "stretch",
                                }}
                            >
                                <Skeleton
                                    sx={{
                                        minHeight: "16px",
                                        margin: "0",
                                        width: "120px",
                                    }}
                                    variant="text"
                                />

                                <Skeleton
                                    variant="text"
                                    sx={{
                                        minHeight: "24px",
                                        margin: "0",
                                        width: "200px",
                                    }}
                                />

                                <Skeleton
                                    variant="text"
                                    className="mb-0"
                                    sx={{
                                        width: "80px",
                                        margin: "0",
                                    }}
                                />
                            </Box>
                        </Box>
                    ))
                )}
            </Box>
        </Box>
    );
};

export default BenefitsPage;
