export interface NewEstimateDTO {
    companyName: string;
    address: string;
    businessType: BusinessType;
    employeeType: number;
    fresh: boolean;
    dueDate?: Date | null;
}

export enum BusinessType {
    Education = "Education",
    Engineering = "Engineering",
    ConsumerDurables = "Consumer Durables",
    ECommerce = "E-Commerce",
    Agriculture = "Agriculture",
    Automobiles = "Automobiles",
    Banking = "Banking",
    FinancialServices = "Financial Services",
    FMCG = "FMCG",
    Jewellery = "Jewellery",
    Infrastructure = "Infrastructure",
    Consulting = "Consulting",
    ManufacturingNonHazardous = "Manufacturing (Non Hazardous)",
    Pharmaceuticals = "Pharmaceuticals",
    Ports = "Ports",
    Power = "Power",
    Railways = "Railways",
    RealEstate = "Real Estate",
    RenewableEnergy = "Renewable Energy",
    Retail = "Retail",
    Roads = "Roads",
    ScienceAndTechnology = "Science and Technology",
    Steel = "Steel",
    Telecommunications = "Telecommunications",
    Textiles = "Textiles",
    TourismAndHospitality = "Tourism and Hospitality",
    Other = "Other",
    Aviation = "Aviation",
    Healthcare = "Healthcare",
    InsuranceCompanies = "Insurance Companies",
    ManufacturingOfHazardousGoods = "Manufacturing of Hazardous Goods",
    MediaAndEntertainment = "Media and Entertainment",
    MetalsAndMining = "Metals and Mining",
    Shipping = "Shipping",
    OilAndGas = "Oil and Gas",
    MultiLevelMarketing = "Multi-Level Marketing",
    ArmedForces = "Armed Forces",
    OtherHazardousActivities = "Other Hazardous Activities",
    NGO = "NGO",
    LawFirm = "Law Firm",
    InformationTechnology = "Information Technology",
}

export enum EmployeeType {
    White = "White",
    Blue = "Blue",
    Grey = "Grey",
}

export const EmployeeTypeMap: Record<EmployeeType, number> = {
    [EmployeeType.White]: 1,
    [EmployeeType.Blue]: 2,
    [EmployeeType.Grey]: 3,
};
