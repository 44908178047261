import React, { useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import "./Loader.scss";
const ErrorImage = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/loaderError.svg";
const SuccessImage = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/loaderSuccess.svg";

interface LoaderProps {
    children: React.ReactNode;
    loader?: {
        state: "loading" | "success" | "error";
        component: React.ReactNode;
    };
}

function Loader({ children, loader }: LoaderProps) {
    useEffect(() => {
        const modal = document.getElementsByClassName("main-modal")[0] as
            | HTMLElement
            | undefined;
        if (modal) {
            modal.scrollTop = 0;
            modal.style.overflowY = loader?.state ? "hidden" : "auto";
        }
    }, [loader]);

    return (
        <div className="loader">
            {children}
            {loader?.state === "loading" && (
                <div className="loader__loading">
                    <LinearProgress />
                </div>
            )}
            {loader?.state === "success" && (
                <SuccessUI component={loader.component} />
            )}
            {loader?.state === "error" && (
                <ErrorUi component={loader.component} />
            )}
        </div>
    );
}

export default Loader;

function SuccessUI({ component }: { component: React.ReactNode }) {
    return (
        <div className="loader__popup">
            <div className="loader__popup__circle bg--green">
                <img
                    className="loader__popup__image"
                    src={SuccessImage}
                    alt=""
                />
            </div>
            <div className="loader__title">Success</div>
            <div className="loader__subtext">{component}</div>
        </div>
    );
}
function ErrorUi({ component }: { component: React.ReactNode }) {
    return (
        <div className="loader__popup">
            <div className="loader__popup__circle bg--red">
                <img className="loader__popup__image" src={ErrorImage} alt="" />
            </div>
            <div className="loader__title">Error</div>
            <div className="loader__subtext">{component}</div>
        </div>
    );
}
