import React from "react";
import { Modal, Box, useMediaQuery, useTheme } from "@mui/material";

interface GenericModalProps {
    open: boolean;
    children: React.ReactNode;
}

const GenericModalV2: React.FC<GenericModalProps> = ({ open, children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Modal
            open={open}
            // onClose={onClose}
            sx={{
                display: "flex",
                alignItems: isMobile ? "flex-start" : "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    width: isMobile ? "100vw" : "717px",
                    height: isMobile ? "100vh" : "auto",
                    bgcolor: "background.paper",
                    outline: "none",
                    padding: "24px 20px",
                    borderRadius: isMobile ? "" : "24px",

                    overflowY: "auto",
                }}
            >
                {children}
            </Box>
        </Modal>
    );
};

export default GenericModalV2;
