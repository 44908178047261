// Create a image compressor takes url and return a promise of compressed image

const imageCompressor = (
    url: string,
    config: { height?: number; width?: number; quality: number }
):Promise<File | Blob> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
            let width = img.width;
            let height = img.height;
            
            
            const elem = document.createElement("canvas");
            elem.width =  width;
            elem.height = height;
            const ctx = elem.getContext("2d");

            ctx!.drawImage(img, 0, 0, elem.width, elem.height);
            ctx!.canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject(new Error("Canvas is empty"));
                        return;
                    }
                    resolve(blob);
                },
                "image/jpeg",
                imageHeightToQuality(height)
            );
        };
        img.onerror = (error) => reject(error);
    });
};


const imageHeightToQuality = (height: number): number => {
    debugger;
    if (height > 2000) {
        return 0.2;
    }
    if (height > 1800) {
        return 0.3;
    }
    if (height > 1600) {
        return 0.5;
    }
    if (height > 1400) {    
        return 0.7;
    }
    if (height > 1200) {
        return 0.8;
    }
    return 0.9;
}


export default imageCompressor;