import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MyText from "../../../components/Input/MyText";
import * as Yup from "yup";
import "./kmdSignIn.scss";

interface NewSignInProps {
    handleSubmit: (registrationNumber: string) => void;
}
function KmdSignIn({ handleSubmit }: NewSignInProps) {
    const reducerDispatch = useDispatch();

    return (
        <div className="kmdSignIn">
            <Formik
                initialValues={{
                    registrationNumber: "",
                }}
                validationSchema={Yup.object({
                    registrationNumber: Yup.string().required(
                        "Architect Association Number is required"
                    ),
                })}
                onSubmit={(values) => {
                    handleSubmit(values.registrationNumber);
                    console.log(values);
                }}
            >
                <Form>
                    <MyText
                        name="registrationNumber"
                        label="Council Registration Number"
                        type="string"
                        placeholder="CA/XXXX/XXXXXX"
                        onChange={() => {}}
                    />
                    <button type="submit" className="buttonPrimary mt20">
                        Next
                    </button>
                </Form>
            </Formik>
        </div>
    );
}

export default KmdSignIn;
