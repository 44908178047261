import {
    Box,
    CircularProgress,
    Divider,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import NewPolicyCard from "../Plans/NewPolicyCard";
import { useQuery } from "react-query";
import { policyService } from "../../services/api/policyServics";
import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import {
    PolicyState,
    setPolicyCardDto,
} from "../../features/policy/policySlice";
import GenericNotFound from "../NotFound";
import { getBarValue } from "../../util/functions";

const NewPolicyPage = () => {
    const [selectedChip, setSelectedChip] = useState("Active");

    const { policyCardDto } = useSelector((state: RootState) => state.policy);

    const isMobile = useMediaQuery("(max-width: 768px)");

    const handleChipClick = (label: string) => {
        setSelectedChip(label);
    };

    const theme = useTheme();

    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { data, isSuccess, isLoading } = useQuery<
        PolicyState["policyCardDto"]
    >("policyCards", () => policyService.getPolicyCards(), {
        staleTime: 1000 * 60 * 5,
        enabled: user.userType === "EMPLOYEE",
    });

    useEffect(() => {
        if (isSuccess) {
            dispatch(setPolicyCardDto(data));
        }
    }, [isSuccess, data, dispatch]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        console.log("scroll to top");
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    const [scrollPositon, setscrollPositon] = useState(0);
    useEffect(() => {
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        const handleScroll = () => {
            setscrollPositon(mainContent.scrollTop);
        };
        mainContent.addEventListener("scroll", handleScroll);
        return () => {
            mainContent.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <Box
                sx={{
                    // height: "450px",

                    ...(!isMobile
                        ? {
                              background: `url(${benifitDesktopbg})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPositionX: "center",
                          }
                        : {}),
                    margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: { sm: "36px", xs: "0px" },

                    ...(isMobile
                        ? {
                              "&::before": {
                                  content: '""',
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  background: `url(${
                                      isMobile
                                          ? benifitMobilebg
                                          : benifitDesktopbg
                                  })`,
                                  // backgroundColor: `rgba(255, 255, 255, ${maxValuereturn(scrollPositon,35)/35})`,
                                  backgroundSize: "contain",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPositionX: "center",
                                  opacity:
                                      1 -
                                      (scrollPositon < 35
                                          ? scrollPositon
                                          : 35) /
                                          35,
                                  zIndex: 1,
                              },
                          }
                        : {}),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: { xs: "100%", sm: "770px" },
                        margin: "auto",
                        flexDirection: { xs: "column", sm: "row" },
                        padding: { xs: "24px 16px", sm: "0" },
                        ...(isMobile ? { height: "150px", top: 0 } : {}),
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: { xs: "100%", sm: "770px" },
                        margin: "auto",
                        flexDirection: { xs: "column", sm: "row" },
                        padding: { xs: "24px 16px", sm: "0" },

                        ...(isMobile
                            ? {
                                  // height: isScrolled ? "70px":'150px',
                                  // backgroundColor:isScrolled ? 'white':'transparent',
                                  position: "absolute",
                                  top: 0,
                                  backgroundColor: `rgba(255, 255, 255, ${
                                      scrollPositon >= 35 ? 1 : 0
                                  })`,
                                  height:
                                      150 -
                                      (scrollPositon < 70 ? scrollPositon : 70),
                                  // transition: 'height 0.5s, background 0.5s',
                                  borderBottom: `1px solid rgba(150, 150, 150, ${
                                      (scrollPositon < 35
                                          ? scrollPositon
                                          : 35) / 35
                                  })`,
                                  overflow: "hidden",
                                  zIndex: 100,
                              }
                            : {}),
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: "10px",
                        }}
                    >
                        {/* <Iconify
                            icon="material-symbols:arrow-left-alt"
                            sx={{
                                // marginTop: "5px",
                                color: theme.palette.grey[800],
                                cursor: "pointer",
                            }}
                            width={"32px"}
                            height={"32px"}
                            onClick={() => navigate("/home")}
                        /> */}
                        <Stack direction="row" alignItems={"center"} gap="8px">
                            {getBarValue("navBar") !== "false" && (
                                <KeyboardBackspaceIcon
                                    sx={{
                                        color: theme.palette.grey[800],
                                        cursor: "pointer",
                                        width: "32px",
                                        height: "32px",
                                    }}
                                    onClick={() => navigate("/home")}
                                />
                            )}
                            {isMobile && (
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: theme.palette.text.dark,
                                        opacity:
                                            (scrollPositon < 70
                                                ? scrollPositon
                                                : 70) / 70,
                                    }}
                                >
                                    My Policies
                                </Typography>
                            )}
                        </Stack>
                        <Stack direction="column" gap="4px">
                            <Typography
                                variant="h4"
                                sx={
                                    isMobile
                                        ? {
                                              color: theme.palette.text.dark,
                                              opacity:
                                                  1 -
                                                  (scrollPositon < 70
                                                      ? scrollPositon
                                                      : 70) /
                                                      70,
                                          }
                                        : { color: theme.palette.text.dark }
                                }
                            >
                                My Policies
                            </Typography>
                            <Typography
                                variant="body3"
                                sx={{
                                    width: { xs: "196px", sm: "auto" },
                                    color: theme.palette.text.primary,
                                    ...(isMobile
                                        ? {
                                              opacity:
                                                  1 -
                                                  (scrollPositon < 70
                                                      ? scrollPositon
                                                      : 70) /
                                                      70,
                                          }
                                        : {}),
                                }}
                            >
                                Find all your policies here.
                            </Typography>
                        </Stack>
                    </Box>
                    {/* <Box
                        sx={{
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Chip
                            label="Active"
                            onClick={() => handleChipClick("Active")}
                            sx={{
                                backgroundColor:
                                    selectedChip === "Active"
                                        ? "black"
                                        : "white",
                                color:
                                    selectedChip === "Active"
                                        ? "white"
                                        : "default",
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            }}
                        />
                        <Chip
                            label="Buy Benefits"
                            onClick={() => handleChipClick("Buy Benefits")}
                            sx={{
                                backgroundColor:
                                    selectedChip === "Buy Benefits"
                                        ? "black"
                                        : "white",
                                color:
                                    selectedChip === "Buy Benefits"
                                        ? "white"
                                        : "default",
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            }}
                        />
                    </Box> */}
                </Box>
                {isMobile && (
                    <img
                        src="https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Policies+BG+illustration.png"
                        alt="bgLogo"
                        width="126px"
                        height="137px"
                        style={{
                            position: "absolute",
                            bottom: isMobile ? "" : "198px",
                            top: isMobile ? "67px" : "",
                            right: 0,
                        }}
                    />
                )}
                <Box
                    sx={{
                        display: "flex",
                        width: { xs: "100%", sm: "770px" },
                        // position: "absolute",
                        // top: { xs: "165px", sm: "135px" },
                        // left: "50%",
                        // transform: "translateX(-50%)",
                        padding: isMobile ? "24px 20px" : "24px",
                        // alignItems: "flex-start",
                        // alignContent: "flex-start",
                        // justifyContent: "space-between",
                        gap: isMobile ? "20px" : "22px",
                        alignSelf: "stretch",
                        flexWrap: "wrap",
                        flexDirection: { xs: "column", sm: "row" },
                        borderRadius: { sm: "40px", xs: "24px 24px 0 0" },

                        border: "1px solid var(--Components-Outline, #ECEFFF)",
                        background: "#FFF",
                        boxShadow: {
                            sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                            xs: "",
                        },
                        minHeight: { xs: "100%", sm: "456px" },
                        margin: "auto",
                        zIndex: 1,
                    }}
                >
                    {!isLoading ? (
                        (policyCardDto?.policyCards?.length ?? 0) > 0 ? (
                            policyCardDto?.policyCards?.map(
                                (item: any, index: number) => (
                                    <NewPolicyCard
                                        key={index}
                                        bgImage={item.bgImage}
                                        categoryLogo={item.categoryLogo}
                                        covers={item?.covers}
                                        insuranceCategory={
                                            item?.insuranceCategory
                                        }
                                        heading={item.heading}
                                        endDate={item?.endDate}
                                        dateAlter={item?.dateAlter}
                                        onClick={() =>
                                            navigate(
                                                `/plans/${item.uuid}/${item.pId}?fromPolicyCard=true`
                                            )
                                        }
                                    />
                                )
                            )
                        ) : (
                            <GenericNotFound title="No Active Policies" />
                        )
                    ) : (
                        [1, 2, 3].map((eachDummy) => (
                            <Box
                                sx={{
                                    width: { sm: "349px", xs: "100%" },
                                    borderRadius: "24px",
                                    cursor: "pointer",
                                    height: { sm: "232px", xs: "fit-content" },
                                    border: `1px solid ${theme.palette.grey[300]}`,
                                    overflow: "hidden",
                                }}
                            >
                                <div style={{ position: "relative" }}>
                                    <Skeleton
                                        // src={bgImage}
                                        // alt="bgImage"
                                        variant="rectangular"
                                        height={"56px"}
                                        width={"100%"}
                                        sx={{
                                            objectFit: "cover",
                                            borderTopLeftRadius: "24px",
                                            borderTopRightRadius: "24px",
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            width: "60px",
                                            height: "60px",
                                            padding: "0px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexShrink: 0,
                                            borderRadius: "500px",
                                            border: "4px solid #FFF",
                                            background: "#F4F7FF",
                                            position: "absolute",
                                            bottom: "-30px",
                                            left: "13%",
                                            transform: "translateX(-50%)",
                                        }}
                                    >
                                        <Skeleton
                                            width={"60px"}
                                            height={"60px"}
                                            sx={{ flexShrink: 0 }}
                                            variant="circular"
                                        />
                                    </Box>
                                </div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "16px",
                                        paddingTop: "38px",
                                        gap: "8px",
                                        alignSelf: "stretch",
                                        background: "#FFF",
                                        borderBottomLeftRadius: "24px",
                                        borderBottomRightRadius: "24px",
                                    }}
                                >
                                    <Skeleton
                                        variant="text"
                                        width={"250px"}
                                        sx={{
                                            minHeight: {
                                                sm: 60,
                                                xs: "fit-content",
                                            },
                                            overflow: "hidden",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                        }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={"350px"}
                                        sx={{
                                            minHeight: 40,
                                        }}
                                    />

                                    <Divider
                                        variant="dashed"
                                        sx={{
                                            borderColor: "grey.400",
                                            opacity: 0.3,
                                        }}
                                    />

                                    <Skeleton
                                        variant="text"
                                        width={"150px"}
                                        sx={{
                                            minHeight: 30,
                                        }}
                                    />
                                </Box>
                            </Box>
                        ))
                    )}
                </Box>
            </Box>
        </>
    );
};

export default NewPolicyPage;
