import alert from "../alerts"

const copyFunction = (text: string, message="Copied to clipboard") => {
    navigator.clipboard.writeText(text).then(() => {
        alert(message,"info")
    }).catch((err) => {
        alert("Failed to copy to clipboard","error")
    })
    
}

export default copyFunction