import React, { useEffect, useState } from "react";
import { getInsurerIcon } from "./../../../../assets/images/insurer_logos";
import "./CustomerCareCard.scss";
import { CLAIM_OPERATIONTEAM } from "./../../../../util/config";
import service from "./../../../../util/axiosConfig";
import copyFunction from "../../../../util/function/copyFunction";
import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerCareData } from "../../../../features/claims/claimSlice";
import { setOperationTeamData } from "../../../../features/claims/claimSliceOperationTeam";
import Box from "@mui/material/Box";
import GenericModalContainer from "../../../../components/Modals/GenericModalContainer";
import { useQuery } from "react-query";
import { claimsService } from "../../../../services/api/claimService";
import { useStateValue } from "../../../../util/stateProvider";
const CallIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/claims_icons/call_icon.svg";
const CustomerCareIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/claims_icons/customerCare.svg";
const CopyIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/general/copy.svg";
const CloseIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Modals_icons/Close.svg";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 1000,
    minWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: 0,
    margin: 0,
};
const CustomerCareCard = (props) => {
    const [{ user }] = useStateValue();
    const reducerDispatch = useDispatch();
    const customerCareData = useSelector((state) =>
        props.type === "OperationTeam"
            ? state.operationTeam.operationTeamData
            : state.customerCare.customerCareData
    );
    // const operationTeamData = useSelector(
    //     (state) => state.operationTeam.operationTeamData
    // );
    const [open, setOpen] = useState(false);

    const {
        data: customerCareCalim,
        isSuccess,
        isLoading,
    } = useQuery(
        ["customerCareClaimContact"],
        () => claimsService.employer.getClaimContact(),
        {
            enabled: !!user && props.type !== "OperationTeam",
            onSuccess: (data) => {
                reducerDispatch(setCustomerCareData(data));
            },
        }
    );

    const { data: customerCareOperation, isSuccess: isOpSuccess } = useQuery(
        ["customerCareOperationsContact"],
        () => claimsService.employer.getOperationContact(),
        {
            enabled:
                !!user &&
                user.userType.toLowerCase() !== "employee" &&
                props.type === "OperationTeam" &&
                !customerCareData,
            onSuccess: (data) => {
                reducerDispatch(setOperationTeamData(data));
            },
        }
    );

    if (!customerCareData?.phone && !customerCareData?.email) {
        return null;
    }

    return (
        <div
            className={`customerCare ${
                props?.classname ? props.classname : ""
            }`}
            style={props?.style ? props.style : {}}
        >
            <div className="customerCare__container">
                <h2 className="heading">
                    {props.type === "OperationTeam"
                        ? "Operations Team"
                        : "Claim Assistance"}
                </h2>

                <div className="text">
                    {customerCareData?.name ? (
                        <h1>{customerCareData?.name}</h1>
                    ) : (
                        <Skeleton variant="text" width="40%" height={32} />
                    )}
                </div>
                <div className="text">
                    <div>
                        <p>Phone no.</p>
                        {customerCareData?.phone ? (
                            <h3>{customerCareData?.phone}</h3>
                        ) : (
                            <Skeleton variant="text" width={80} height={17} />
                        )}
                    </div>
                    <img
                        src={CopyIcon}
                        alt=""
                        className="pointer"
                        onClick={() => {
                            copyFunction(customerCareData?.phone);
                        }}
                    />
                </div>
                <div className="text">
                    <div>
                        <p>Email</p>
                        {customerCareData?.email ? (
                            <h3>{customerCareData?.email}</h3>
                        ) : (
                            <Skeleton variant="text" width={80} height={17} />
                        )}
                    </div>
                    <img
                        src={CopyIcon}
                        alt=""
                        className="pointer"
                        onClick={() => {
                            // setOpen(true);
                            copyFunction(customerCareData?.email);
                        }}
                    />
                </div>
                {!!customerCareData?.escalationMatrixDTOS?.length && (
                    <a
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => setOpen(true)}
                        className="fontSize_12"
                    >
                        Not able to Connect?
                    </a>
                )}
            </div>
            {open && (
                <Modal
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            borderRadius: 3,
                            border: "background.paper",
                            outline: "none",
                            p: 2,
                            width: {
                                xs: "350px",
                                sm: "400px",
                                md: "500px",
                                lg: "500px",
                                xl: "500px",
                            },
                            overflowX: "auto",
                        }}
                    >
                        <div className="d-flex justify-content-between pt8 pb8">
                            <h2 className="title">
                                {props.type === "OperationTeam"
                                    ? "Operations Team matrix"
                                    : "Claim escalation matrix"}
                            </h2>
                            <div
                                className="close"
                                onClick={() => setOpen(false)}
                                style={{ cursor: "pointer" }}
                            >
                                <img
                                    src={CloseIcon}
                                    className="mb8"
                                    alt="close"
                                />
                            </div>
                        </div>
                        <div style={{ overflow: "scroll" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className="pr15I pl15I pt12I pb12I"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            Escalation level
                                        </TableCell>
                                        <TableCell className="pr15I pl15I pt12I pb12I">
                                            Name
                                        </TableCell>
                                        <TableCell className="pr15I pl15I pt12I pb12I">
                                            Phone
                                        </TableCell>
                                        <TableCell className="pr15I pl15I pt12I pb12I">
                                            Email
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {customerCareData?.escalationMatrixDTOS?.map(
                                        (item, index) => (
                                            <TableRow key={item.level}>
                                                <TableCell className="p15I">
                                                    Level {item.level}
                                                </TableCell>
                                                <TableCell
                                                    className="p15I"
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "eliplse",
                                                    }}
                                                >
                                                    {item?.name}
                                                </TableCell>
                                                <TableCell
                                                    className="p15I"
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "eliplse",
                                                    }}
                                                >
                                                    <div
                                                        className="text"
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        {item?.phone}
                                                        <img
                                                            src={CopyIcon}
                                                            alt=""
                                                            className="pointer ml5"
                                                            onClick={() => {
                                                                copyFunction(
                                                                    item?.phone
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className="p15I"
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "eliplse",
                                                    }}
                                                >
                                                    <div
                                                        className="text"
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        {item?.email}
                                                        <img
                                                            src={CopyIcon}
                                                            alt=""
                                                            className="pointer ml5"
                                                            onClick={() => {
                                                                copyFunction(
                                                                    item?.email
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <div className="pt10 fontSize_12">
                            <span style={{ fontWeight: 600, color: "black" }}>
                                Please note:
                            </span>{" "}
                            Please follow the given hierarchy only!
                        </div>
                    </Box>
                </Modal>

                // </Modal>
            )}
        </div>
    );
};

export default CustomerCareCard;
