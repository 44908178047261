import {
    Box,
    Button,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Iconify from "../../components/iconify";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { changeDateFormat } from "../../util/date";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import * as Yup from "yup";
import { EMPLOYEE_EDIT_POST, EMPLOYEE_EDIT_PUT } from "../../util/newconfig";
import advService from "../../util/advService";
import MyText from "../../components/Input/MyText";
import service from "../../util/axiosConfig";
import Dropdown from "../../components/Input/Dropdown";
import { EMPLOYEE_UPLOAD } from "../../util/config";
import alert from "../../util/alerts";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
    PolicyState,
    setPolicyDetailDto,
} from "../../features/policy/policySlice";
import { policyService } from "../../services/api/policyServics";
import { useDispatch } from "react-redux";

type Member = {
    sumInsured?: string;
    dob?: string;
    name: string;
    claimable?: number;
    relation: string;
    relationDp: string;
    healthCardId?: string;
    uid?: string;
    relation_ship?: string;
    gender?: string;
    employeeId?: string;
    dom?: string;
    policyType?: string;
};

interface QueryParams {
    member: Member | null;
    pid: string | null;
    uuid: string | null;
}

const useQueryParams = (): QueryParams => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    return {
        member: queryParams.get("member")
            ? (JSON.parse(
                  decodeURIComponent(queryParams.get("member") as string)
              ) as Member)
            : null,
        pid: queryParams.get("pid")
            ? decodeURIComponent(queryParams.get("pid") as string)
            : null,
        uuid: queryParams.get("uuid")
            ? decodeURIComponent(queryParams.get("uuid") as string)
            : null,
    };
};

const NewEditMember = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [formDirty, setFormDirty] = useState(false);

    const policyDetailDto = useSelector(
        (state: RootState) => state.policy.policyDetailDto
    );

    const theme = useTheme();

    const spousePresent = policyDetailDto?.detailedInfo?.members?.some(
        (member) => member?.relation_ship === "Spouse"
    );

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { member, pid, uuid } = useQueryParams();

    console.log("member", member);

    const { isLoading, isSuccess, data } = useQuery<
        PolicyState["policyDetailDto"]
    >(
        ["policyDetails", uuid, pid],
        () => policyService.getPolicyDetails(pid as string, uuid as string),
        {
            enabled: !!uuid && !!pid,
            staleTime: 1000 * 60 * 5,
        }
    );

    useEffect(() => {
        if (isSuccess) {
            dispatch(setPolicyDetailDto(data));
        }
    }, [isSuccess, data, dispatch]);

    const [store, setStore] = useState({
        gender: member?.gender || "Male",
        relationShip: member?.relation_ship
            ? member?.relation_ship
            : spousePresent
            ? "Child"
            : "Spouse",
        dob: member?.dob
            ? changeDateFormat("dd-mmm-yyyy", "yyyy-mm-dd", member?.dob)
            : "",
    });

    console.log("store", store);

    let relationShipItems: any = [];
    if (member?.relation_ship === "Employee") {
        relationShipItems = [{ id: "Employee", name: "Employee" }];
    } else if (policyDetailDto?.detailedInfo?.d_demography === 2) {
        if (spousePresent && !member?.uid) {
            relationShipItems = [{ id: "Child", name: "Child" }];
        } else {
            relationShipItems = [
                { id: "Spouse", name: "Spouse" },
                { id: "Child", name: "Child" },
            ];
        }
    } else {
        if (spousePresent && !member?.uid) {
            relationShipItems = [
                { id: "Child", name: "Child" },
                { id: "Parent", name: "Parent" },
            ];
        } else {
            relationShipItems = [
                { id: "Spouse", name: "Spouse" },
                { id: "Child", name: "Child" },
                { id: "Parent", name: "Parent" },
            ];
        }
    }

    const handleSubmit = (values: {
        name: string;
        relationShip: string;
        dob: string | Date;
        dom?: string | Date;
        file?: File | null;
    }) => {
        let payload = {
            healthId:
                member?.healthCardId && member?.healthCardId !== "-"
                    ? member?.healthCardId
                    : undefined,
            uid: member?.uid,
            employeeId: member?.employeeId,
            name: values.name,
            relationShip: store?.relationShip,
            gender: store?.gender,
            dobText: changeDateFormat(
                "javascriptDateObject",
                "dd-mmm-yyyy",
                values.dob
            ),
            ...(values.dom && {
                dom: changeDateFormat(
                    "javascriptDateObject",
                    "dd-mmm-yyyy",
                    values.dom
                ),
            }),
            policyType: member?.policyType || "GMC",
        };

        if (store.relationShip !== "Spouse") {
            delete payload.dom;
        }
        if (member?.uid) {
            let url = `${EMPLOYEE_UPLOAD}/${member.uid}`;
            const data = new FormData();
            let file = values?.file;

            let name = values?.file?.name;
            if (file) {
                data.append("file", file);
            }
            service.postUpload(url, data).then((res) => {
                console.log(res.data);
                let config = { ...EMPLOYEE_EDIT_PUT };
                advService(
                    config,
                    {
                        ...payload,
                    },
                    (res: any) => {
                        if (res?.data?.success) {
                            queryClient.invalidateQueries([
                                "policyDetails",
                                uuid,
                                pid,
                            ]);
                            navigate(-1);
                        }
                    }
                );
            });
        } else {
            let config = { ...EMPLOYEE_EDIT_POST };
            advService(
                config,
                {
                    ...payload,
                },
                (res: any) => {
                    if (res?.data?.success) {
                        queryClient.invalidateQueries([
                            "policyDetails",
                            uuid,
                            pid,
                        ]);
                        navigate(-1);
                    }
                }
            );
        }
    };

    return (
        <Box
            sx={{
                paddingTop: { sm: "33px", xs: "" },
                background: `url(${
                    isMobile ? benifitMobilebg : benifitDesktopbg
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",

                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    {/* <Iconify
                        icon="material-symbols:arrow-left-alt"
                        sx={{
                            // marginTop: "5px",
                            color: "black",
                            cursor: "pointer",
                        }}
                        width={"32px"}
                        height={"32px"}
                        onClick={() => navigate(-1)}
                    /> */}

                    <KeyboardBackspaceIcon
                        sx={{
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                            width: "32px",
                            height: "32px",
                        }}
                        onClick={() => navigate(-1)}
                    />

                    <Typography
                        variant="h4"
                        sx={{ color: theme.palette.text.dark }}
                    >
                        {member?.uid ? "Edit" : "Add"} Member
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    padding: { sm: "24px", xs: "32px 20px" },
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    gap: { xs: "40px", sm: "24px 20px" },
                    flexWrap: "wrap",
                    borderRadius: { sm: "40px", xs: "24px 24px 0 0" },
                    border: "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    minHeight: { xs: "100%", sm: "456px" },
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "",
                    },
                    margin: "auto",
                }}
            >
                {member?.uid && (
                    <Box
                        sx={{
                            display: "flex",
                            padding: "16px 15px",
                            alignItems: "center",
                            gap: "8px",
                            alignSelf: "stretch",
                            width: "100%",
                            msFlexDirection: "row",
                            borderRadius: "16px",
                            background: "var(--Warning-Lighter, #FFF3E4)",
                        }}
                    >
                        <Iconify
                            icon={"material-symbols:warning-rounded"}
                            width={"32px"}
                            height={"32px"}
                            color={"#D5691B"}
                        />
                        <Typography
                            variant="overline"
                            sx={{
                                textTransform: "none",
                            }}
                        >
                            Details once changed cannot be restored as the
                            changes will reflect on insurance.
                        </Typography>
                    </Box>
                )}
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: member?.name || "",
                        relationShip: member?.relation_ship || "",
                        dob: member?.dob
                            ? changeDateFormat(
                                  "dd-mmm-yyyy",
                                  "javascriptDateObject",
                                  member?.dob
                              )
                            : "",
                        dom: member?.dom
                            ? changeDateFormat(
                                  "dd-mmm-yyyy",
                                  "javascriptDateObject",
                                  member?.dom
                              )
                            : "",
                        file: null as File | null,
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required("Name is required"),
                        dob: Yup.string()
                            .required("Date of birth is required")
                            .test(
                                "dobCheck",
                                "Invalid age for the selected relationship",
                                function (value) {
                                    if (!value) return false;

                                    console.log("value", value);

                                    const age =
                                        (new Date().getTime() -
                                            new Date(value).getTime()) /
                                        (1000 * 60 * 60 * 24 * 365);
                                    const relation = store.relationShip;
                                    console.log(relation);
                                    const ageValidation =
                                        policyDetailDto?.detailedInfo
                                            ?.policyValidation?.ageValidation[
                                            relation as keyof typeof policyDetailDto.detailedInfo.policyValidation.ageValidation
                                        ];

                                    if (!ageValidation) {
                                        return true;
                                    }

                                    const { minAge, maxAge } = ageValidation;

                                    return age >= minAge && age <= maxAge;
                                }
                            ),
                        dom:
                            store.relationShip === "Spouse"
                                ? Yup.string().required(
                                      "Date of Marriage is required"
                                  )
                                : Yup.string(),
                        file: Yup.mixed().test(
                            "fileRequired",
                            "File upload is required",
                            function () {
                                if (
                                    formDirty &&
                                    member?.uid &&
                                    !this.parent?.file
                                ) {
                                    return false;
                                }
                                return true;
                            }
                        ),
                    })}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                    validateOnBlur={true}
                    validateOnChange={true}
                >
                    {({ dirty, errors, values, setFieldValue }) => {
                        if (dirty) {
                            setFormDirty(true);
                        }

                        return (
                            <Form
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: isMobile ? "24px" : "40px",
                                    width: "100%",
                                    minHeight: isMobile ? "100%" : "456px",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        alignContent: "center",
                                        gap: "24px 38px",
                                        alignSelf: "stretch",
                                        flexWrap: "wrap",
                                        width: "100%",
                                    }}
                                >
                                    <Box
                                        width={{
                                            xs: "100%",
                                            sm: "calc(50% - 24px)",
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        <MyText
                                            label="Name"
                                            name="name"
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </Box>
                                    <Box
                                        width={{
                                            xs: "100%",
                                            sm: "calc(50% - 24px)",
                                        }}
                                    >
                                        {/* @ts-ignore */}

                                        <MyText
                                            label="Date of birth"
                                            name="dob"
                                            type="date"
                                            placeholder="Date of Birth"
                                        />
                                    </Box>
                                    <Box
                                        width={{
                                            xs: "100%",
                                            sm: "calc(50% - 24px)",
                                        }}
                                    >
                                        <Dropdown
                                            title="Gender"
                                            defaultVal={store.gender}
                                            items={[
                                                { id: "Male", name: "Male" },
                                                {
                                                    id: "Female",
                                                    name: "Female",
                                                },
                                            ]}
                                            change={(val: string) =>
                                                setStore({
                                                    ...store,
                                                    gender: val,
                                                })
                                            }
                                        />
                                    </Box>
                                    {!member?.uid && (
                                        <Box
                                            width={{
                                                xs: "100%",
                                                sm: "calc(50% - 24px)",
                                            }}
                                        >
                                            <Dropdown
                                                title="Relationship"
                                                defaultVal={store.relationShip}
                                                items={relationShipItems}
                                                change={(val: string) =>
                                                    setStore({
                                                        ...store,
                                                        relationShip: val,
                                                    })
                                                }
                                            />
                                        </Box>
                                    )}
                                    {store.relationShip === "Spouse" && (
                                        <Box
                                            width={{
                                                xs: "100%",
                                                sm: "calc(50% - 24px)",
                                            }}
                                        >
                                            {/* @ts-ignore */}
                                            <MyText
                                                label="Date of Marriage"
                                                name="dom"
                                                type="date"
                                                placeholder="Date of Marriage"
                                            />
                                        </Box>
                                    )}

                                    {member?.uid && (
                                        <Stack
                                            flexDirection={"column"}
                                            width={"100%"}
                                            spacing={"24px"}
                                        >
                                            <Typography variant="subtitle2">
                                                Identity card required for
                                                correction
                                            </Typography>
                                            {!values.file ? (
                                                <label
                                                    htmlFor="file-upload"
                                                    style={{
                                                        display: "flex",
                                                        padding:
                                                            "var(--spacing-25, 20px)",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                        gap: "var(--spacing-05, 4px)",
                                                        alignSelf: "stretch",
                                                        borderRadius: "8px",
                                                        border: "1px dashed var(--Components-Outline, #ECEFFF)",
                                                        background:
                                                            "var(--Grey-200, #F4F7FF)",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Iconify
                                                        icon={
                                                            "material-symbols:upload-file-outline"
                                                        }
                                                        width={"32px"}
                                                        height={"32px"}
                                                        color={"#000"}
                                                    />
                                                    <Typography
                                                        variant="body3"
                                                        sx={{
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        Upload file
                                                    </Typography>
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        id="file-upload"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                        onChange={(event) => {
                                                            if (
                                                                event.target
                                                                    .files &&
                                                                event.target
                                                                    .files
                                                                    .length > 0
                                                            ) {
                                                                setFieldValue(
                                                                    "file",
                                                                    event.target
                                                                        .files[0]
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        padding:
                                                            "var(--Spacing-2, 16px)",
                                                        flexDirection: "row",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                        alignSelf: "stretch",
                                                        borderRadius:
                                                            "var(--Radius-3, 24px)",
                                                        border: "1px solid var(--Components-Outline, #ECEFFF)",
                                                        background: "#FFF",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body3"
                                                        sx={{
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        {values?.file?.name}
                                                    </Typography>
                                                    <Iconify
                                                        icon={
                                                            "material-symbols:close"
                                                        }
                                                        width={"24px"}
                                                        height={"24px"}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        color={"#000"}
                                                        onClick={() => {
                                                            setFieldValue(
                                                                "file",
                                                                null
                                                            );
                                                            if (
                                                                fileInputRef.current
                                                            ) {
                                                                fileInputRef.current.value =
                                                                    "";
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                            {errors.file && (
                                                <Typography
                                                    variant="caption"
                                                    color="error"
                                                >
                                                    {errors.file}
                                                </Typography>
                                            )}
                                        </Stack>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        width: "100%",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        disabled={!dirty}
                                        type="submit"
                                        sx={{
                                            backgroundColor:
                                                theme.palette.brand.main,
                                            width: { xs: "100%", sm: "auto" },
                                            height: "42px",
                                        }}
                                    >
                                        <Typography
                                            variant="overline"
                                            sx={{
                                                textTransform: "none",
                                                color: "white",
                                            }}
                                        >
                                            Save Details
                                        </Typography>
                                    </Button>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};

export default NewEditMember;
