import { Family, Gender, MemberWithState } from "../../types";
import { MemberValidation } from "../../types/DTO/enrollmentGetDTO";
import { changeDateFormat } from "../date";

export const ageValidation = (
    members: MemberWithState[],
    validations: MemberValidation
): MemberWithState[] => {
    const validMembers: MemberWithState[] = [];
    let errors: string[] = [];

    let employeeJsDob = changeDateFormat(
        "yyyy-mm-dd",
        "javascriptDateObject",
        members.find((member) => member.relationShip === "Employee")?.dob || ""
    ) as Date;

    for (const member of members) {
        const jsDob: Date = changeDateFormat(
            "yyyy-mm-dd",
            "javascriptDateObject",
            member.dob
        ) as Date;

        if (member.state !== "remove") {
            if (jsDob > new Date()) {
                errors.push("Date of birth can't be in future");
            }
            if (
                member.relationShip === "Spouse" &&
                validations.Spouse &&
                (new Date().getFullYear() - jsDob.getFullYear() <
                    validations.Spouse?.minAge ||
                    new Date().getFullYear() - jsDob.getFullYear() >
                        validations.Spouse?.maxAge)
            ) {
                errors.push(
                    `Spouse's age must be between ${validations.Spouse?.minAge} and ${validations.Spouse?.maxAge}`
                );
            }
            if (
                member.relationShip === "Child" &&
                validations.Child &&
                (new Date().getFullYear() - jsDob.getFullYear() <
                    validations.Child?.minAge ||
                    new Date().getFullYear() - jsDob.getFullYear() >
                        validations.Child?.maxAge)
            ) {
                errors.push(
                    `Child's age must be between ${validations.Child?.minAge} and ${validations.Child?.maxAge}`
                );
            }
            if (
                member.relationShip === "Child" &&
                validations.EmployeeChild &&
                (jsDob.getFullYear() - employeeJsDob.getFullYear() >
                    validations.EmployeeChild?.maxAge ||
                    jsDob.getFullYear() - employeeJsDob.getFullYear() <
                        validations.EmployeeChild?.minAge)
            ) {
                errors.push(
                    `Child's age must be between ${validations.EmployeeChild?.minAge} and ${validations.EmployeeChild?.maxAge} of employee`
                );
            }
            if (
                member.relationShip === "Parent" &&
                validations.Parent &&
                (new Date().getFullYear() - jsDob.getFullYear() <
                    validations.Parent?.minAge ||
                    new Date().getFullYear() - jsDob.getFullYear() >
                        validations.Parent?.maxAge)
            ) {
                errors.push(
                    `Parent's age must be between ${validations.Parent?.minAge} and ${validations.Parent?.maxAge}`
                );
            }
            if (
                (member.relationShip === "Parent" ||
                    member.relationShip === "ParentInLaw") &&
                validations.EmployeeParent &&
                (employeeJsDob.getFullYear() - jsDob.getFullYear() >
                    validations.EmployeeParent?.maxAge ||
                    employeeJsDob.getFullYear() - jsDob.getFullYear() <
                        validations.EmployeeParent?.minAge)
            ) {
                errors.push(
                    `Parent's age must be between ${validations.EmployeeParent?.minAge} and ${validations.EmployeeParent?.maxAge} of employee`
                );
            }
            if (errors.length === 0) {
                validMembers.push(member);
            }
        }
    }

    return validMembers;
};

export const checkFamilyValidation = (
    family: Family | null,
    members: MemberWithState[]
) => {
    if (!family) return [];

    let errors: string[] = [];

    let numberOfSpouse = 0;
    let numberOfChildren = 0;
    let numberOfParents = 0;
    let numberOfParentsInLaw = 0;
    let singleDependents: { gender: Gender; relationShip: string }[] = [];

    members
        .filter((member) => member.state !== "remove")
        .forEach((member) => {
            if (member.relationShip === "Child") numberOfChildren++;
            else {
                // check if singleDependents have this member
                // if yes then add error
                // else add this member to singleDependents

                singleDependents.filter(
                    (singleDependent) =>
                        singleDependent.gender === member.gender &&
                        singleDependent.relationShip === member.relationShip
                ).length > 0
                    ? errors.push(
                          `${member.displayRelationship} is already added`
                      )
                    : singleDependents.push({
                          gender: member.gender as Gender,
                          relationShip: member.relationShip,
                      });
            }
            if (member.relationShip === "Parent") numberOfParents++;

            if (member.relationShip === "ParentInLaw") numberOfParentsInLaw++;

            if (member.relationShip === "Spouse") numberOfSpouse++;
        });

    if (numberOfChildren > family.numberOfChild) {
        errors.push(`You can't add more than ${family.numberOfChild} children`);
    }
    if (numberOfParents + numberOfParentsInLaw > family.numberOfParent) {
        errors.push(`You can't add more than ${family.numberOfParent} parents`);
    }
    if (family.parent === 1 && numberOfParentsInLaw > 0) {
        errors.push(`You can't add parent in law`);
    }
    if (family.parent === 2 && numberOfParents > 0) {
        errors.push(`You can't add parent`);
    }
    if (
        family.parent === 4 &&
        numberOfParentsInLaw > 0 &&
        numberOfParents > 0
    ) {
        errors.push(`You can only add parent or parent in law`);
    }
    if (family.spouse && numberOfSpouse > 1) {
        errors.push(`You can't add more than one spouse`);
    }
    if (!family.spouse && numberOfSpouse > 0) {
        errors.push(`You can't add spouse`);
    }
    return errors;
};

export const validDemographyMembers = (
    members: MemberWithState[],
    family: Family | null
) => {
    if (!family) return members;
    let activeMmebers = members.filter((member) => member.state !== "remove");
    let validMembers: MemberWithState[] = [];

    activeMmebers.forEach((member) => {
        if (family.employee && member.relationShip === "Employee")
            validMembers.push(member);
        else if (family.spouse && member.relationShip === "Spouse")
            validMembers.push(member);
        else if (family.numberOfChild && member.relationShip === "Child")
            validMembers.push(member);
        else if (
            family.parent &&
            (member.relationShip === "Parent" ||
                member.relationShip === "ParentInLaw")
        )
            validMembers.push(member);
    });

    return validMembers;
};
