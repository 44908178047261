import {
    Box,
    Divider,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Iconify from "../../components/iconify";
import React, { useEffect } from "react";
import CoverageCard from "./CoverageCard";
import { PolicyFeatures } from "../../services/api/policyServics";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import {
    getCoverageIcon,
    getFeatureIcon,
} from "../../assets/images/policy_features_icons";

const WhatsNotCovered = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const navigate = useNavigate();

    const [searchText, setSearchText] = React.useState("");
    const location = useLocation();

    const excluded: PolicyFeatures[] = location?.state?.excluded || [];

    const filteredData = excluded?.filter((item) =>
        item?.heading?.toLowerCase().includes(searchText.toLowerCase())
    );

    const theme = useTheme();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        console.log("scroll to top");
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    return (
        <Box
            sx={{
                // height: isMobile ? "" : "450px",

                paddingTop: { sm: "33px", xs: "" },
                background: `url(${isMobile ? "" : benifitDesktopbg})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                gap: { sm: "48px", xs: "0px" },

                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    {/* <Iconify
                        icon="material-symbols:arrow-left-alt"
                        sx={{
                            // marginTop: "5px",
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                        }}
                        width={"32px"}
                        height={"32px"}
                        onClick={() => navigate(-1)}
                    /> */}
                    <KeyboardBackspaceIcon
                        sx={{
                            color: (theme) => theme.palette.grey[800],
                            cursor: "pointer",
                            width: "32px",
                            height: "32px",
                        }}
                        onClick={() => navigate(-1)}
                    />
                    <Typography variant="h4" sx={{ color: "black" }}>
                        What's not covered?
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    padding: { sm: "40px 32px", xs: "0px 20px 24px 20px" },
                    justifyContent: "center",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    gap: { sm: "24px 20px", xs: "40px" },
                    flexWrap: "wrap",
                    minHeight: { xs: "100%", sm: "456px" },
                    background: { xs: "", sm: "#fff" },
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "",
                    },
                    borderRadius: { sm: "40px", xs: "" },

                    border: {
                        sm: "1px solid var(--Components-Outline, #ECEFFF)",
                        xs: "none",
                    },

                    margin: "auto",
                }}
            >
                <>
                    {/* <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            height: "54px",
                            padding: "0px 20px",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: "8px",
                            alignSelf: "stretch",
                            borderRadius: "46px",
                            border: "1px solid #7F85A3",
                            "&:focus-within": {
                                border: `1px solid ${theme.palette.brand.main}`,
                            },
                        }}
                    >
                        <Iconify
                            icon={"material-symbols:search"}
                            width={"24px"}
                            height={"24px"}
                            color={"#464D6A"}
                        />
                        <TextField
                            variant="outlined"
                            placeholder="Search coverages"
                            fullWidth
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            sx={{
                                flex: 1,
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: "none",
                                    },
                                },
                            }}
                        />
                        <Iconify
                            icon={"material-symbols:cancel-outline"}
                            width={"24px"}
                            height={"24px"}
                            color={"#464D6A"}
                            onClick={() => setSearchText("")}
                            sx={{
                                cursor: "pointer",
                            }}
                        />
                    </Box> */}
                    <Box
                        sx={{
                            width: "100%",
                            display: "grid",
                            gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                            gap: "16px",
                            padding: { sm: "0px", xs: "24px 0px" },
                            // flexWrap: "wrap",
                            // alignItems: "flex-start",
                            // alignContent: "flex-start",
                        }}
                    >
                        {filteredData?.map((coverage, index) => {
                            if (coverage.label === "diseaseWiseLimitMap") {
                                return null;
                            }

                            let dialogBoxContent = null;
                            if (coverage.label === "diseaseWiseLimit") {
                                const diseaseWiseLimitMapObj =
                                    filteredData.find(
                                        (item) =>
                                            item.label === "diseaseWiseLimitMap"
                                    );
                                if (diseaseWiseLimitMapObj) {
                                    dialogBoxContent = JSON.parse(
                                        diseaseWiseLimitMapObj.text
                                    );
                                }
                            }

                            return (
                                <Stack
                                    flexDirection={"column"}
                                    spacing={{ sm: "24px", xs: "16px" }}
                                >
                                    <CoverageCard
                                        coverageName={coverage.heading}
                                        coverageDescription={coverage.text}
                                        icon={getCoverageIcon(
                                            coverage.label,
                                            "notCovered"
                                        )}
                                        color={"error"}
                                        key={index}
                                        DialogBoxContent={dialogBoxContent}
                                        richText={coverage.richText}
                                        label={coverage.label}
                                    />
                                    {((isMobile &&
                                        index < filteredData.length - 1) ||
                                        (!isMobile &&
                                            index <
                                                filteredData.length - 2)) && (
                                        <Divider
                                            variant="dashed"
                                            sx={{
                                                borderColor: "grey.400",
                                                opacity: 0.3,
                                                marginBottom: "8px",
                                            }}
                                        />
                                    )}
                                </Stack>
                            );
                        })}
                    </Box>
                </>
            </Box>
        </Box>
    );
};

export default WhatsNotCovered;
