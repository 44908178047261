import React, { useState } from "react";
import FileInput from "../../components/Input/FileInput";
import imageCompressor from "../../util/function/imageCompressor";

function TestView() {
    const [files, setFiles] = useState<File[] | Blob[]>([]);

    const handleSetFiles = async (files: File[] | Blob[]) => {
        let compressedFiles = await Promise.all(
            files.map(async (file) => {
                // @ts-ignore
                let name = file.name;
                let url = URL.createObjectURL(file);
                let compressedFile = await imageCompressor(url, {
                    width: 10,
                    height: 10,
                    quality: 1,
                });
                // set name property of compressed file
                Object.defineProperty(compressedFile, "name", {
                    writable: true,
                    value: name,
                });
                return compressedFile;
            })
        );

        // set file
        setFiles(compressedFiles);
    };

    return (
        <div>
            <FileInput
                multiple
                files={files}
                setFiles={handleSetFiles}
                types={"image, pdf"}
                wrongInputFunction={() => console.log("wrong input")}
            />
        </div>
    );
}

export default TestView;
