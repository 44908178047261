import {
    Box,
    Button,
    IconButton,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Divider } from "antd";
import { useState } from "react";
import Iconify from "../../components/iconify/iconify";
import service from "../../util/axiosConfig";
import NewMembers from "./NewMembers";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../util/config";
import { handleResponseAlert } from "../../util/functions";

const EachPolicyDetails = () => {
    const Section = styled(Box)`
        display: flex;
        min-width: 92px;
        max-width: 158px;
        min-height: 46px;
        flex-direction: column;
        align-items: flex-start;

        @media (min-width: 600px) {
            max-width: 100%;
        }
    `;
    const theme = useTheme();
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const { pid, uuid } = useParams<{ pid: string; uuid: string }>();
    const policyDetailDto = useSelector(
        (state: RootState) => state.policy.policyDetailDto
    );
    const isMobile = useMediaQuery("(max-width:600px)");
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    padding: { sm: "24px", xs: "0px 16px" },
                    paddingTop: { xs: "24px", sm: "" },
                    flex: 1,

                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "24px",
                    border: { xs: "1px solid #ECEFFF", sm: "0px" },
                    borderRadius: { sm: "32px", xs: "24px" },
                    borderBottomRightRadius: { xs: "0px", sm: "" },
                    borderBottomLeftRadius: { xs: "0px", sm: "" },
                    borderBottom: { xs: "0px", sm: "0px" },
                    background: { xs: "", sm: "#F4F7FF" },
                    minHeight: { xs: "100%", sm: "456px" },
                    width: { xs: "100%", sm: "337px" },
                }}
            >
                <Stack
                    direction={"column"}
                    spacing={"24px"}
                    width={"100%"}
                    flexGrow={1}
                >
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={"100%"}
                    >
                        <Typography
                            variant="h6"
                            color={(theme) => theme.palette.text.dark}
                        >
                            {policyDetailDto?.topInfo?.insurer}
                        </Typography>
                        <img
                            src={policyDetailDto?.topInfo?.companyLogo}
                            alt="logo"
                            style={{
                                height: "40px",
                                // padding: "11px 0px",
                                width: "80px",
                                objectFit: "contain",
                            }}
                        />
                    </Stack>
                    {!isMobile && <Divider variant="dashed" />}
                    <Stack
                        direction={"column"}
                        gap={"24px"}
                        width={"100%"}
                        flexGrow={1}
                        justifyContent={"space-between"}
                    >
                        <Stack
                            gap={"16px"}
                            display={"grid"}
                            gridTemplateColumns={isMobile ? "1fr 1fr" : "1fr"}
                            width={"100%"}
                            position={"relative"}
                        >
                            {policyDetailDto?.topInfo?.endDate && (
                                <Section>
                                    <Typography
                                        variant="body3"
                                        color={theme.palette.text.secondary}
                                    >
                                        Valid Till
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={theme.palette.text.primary}
                                    >
                                        {policyDetailDto?.topInfo?.endDate}
                                    </Typography>
                                </Section>
                            )}
                            {/* <Divider
                            variant="dashed"
                            type="vertical"
                            style={{ height: "auto" }}
                        /> */}
                            {policyDetailDto?.insuranceCategory === "Motor" ? (
                                <Section>
                                    <Typography
                                        variant="body3"
                                        color={theme.palette.text.secondary}
                                    >
                                        IDV
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={theme.palette.text.primary}
                                    >
                                        {policyDetailDto?.detailedInfo?.idv ||
                                            "-"}
                                    </Typography>
                                </Section>
                            ) : (
                                <Section>
                                    {policyDetailDto?.detailedInfo
                                        ?.sumInsuredTotal ? (
                                        <>
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                Sum Insured
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color={
                                                    theme.palette.text.primary
                                                }
                                            >
                                                {
                                                    policyDetailDto
                                                        ?.detailedInfo
                                                        ?.sumInsuredTotal
                                                }
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                Policy No.
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color={
                                                    theme.palette.text.primary
                                                }
                                            >
                                                {
                                                    policyDetailDto?.topInfo
                                                        ?.policyNumber
                                                }
                                            </Typography>
                                        </>
                                    )}
                                </Section>
                            )}

                            {(showMoreInfo || !isMobile) && (
                                <>
                                    {policyDetailDto?.topInfo?.startDate &&
                                        (policyDetailDto?.insuranceCategory ===
                                            "Motor" ||
                                            policyDetailDto?.insuranceCategory ===
                                                "Property") && (
                                            <Section>
                                                <Typography
                                                    variant="body3"
                                                    color={
                                                        theme.palette.text
                                                            .secondary
                                                    }
                                                >
                                                    Start date
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color={
                                                        theme.palette.text
                                                            .primary
                                                    }
                                                >
                                                    {
                                                        policyDetailDto?.topInfo
                                                            ?.startDate
                                                    }
                                                </Typography>
                                            </Section>
                                        )}

                                    {/* <Divider
                                    variant="dashed"
                                    type="vertical"
                                    style={{ height: "auto" }}
                                /> */}

                                    {policyDetailDto?.detailedInfo
                                        ?.claimableTotal && (
                                        <Section>
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                Claimable
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color={
                                                    theme.palette.text.primary
                                                }
                                            >
                                                {
                                                    policyDetailDto
                                                        ?.detailedInfo
                                                        ?.claimableTotal
                                                }
                                            </Typography>
                                        </Section>
                                    )}
                                    {policyDetailDto?.detailedInfo
                                        ?.deductibleTotal != null && (
                                        <Section>
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                Deductible
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color={
                                                    theme.palette.text.primary
                                                }
                                            >
                                                {
                                                    policyDetailDto
                                                        ?.detailedInfo
                                                        ?.deductibleTotal
                                                }
                                            </Typography>
                                        </Section>
                                    )}
                                    {policyDetailDto?.topInfo?.policyNumber &&
                                        (policyDetailDto?.detailedInfo
                                            ?.sumInsuredTotal ||
                                            policyDetailDto?.insuranceCategory ===
                                                "Motor") && (
                                            <Section>
                                                <Typography
                                                    variant="body3"
                                                    color={
                                                        theme.palette.text
                                                            .secondary
                                                    }
                                                >
                                                    Policy No
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color={
                                                        theme.palette.text
                                                            .primary
                                                    }
                                                >
                                                    {
                                                        policyDetailDto?.topInfo
                                                            ?.policyNumber
                                                    }
                                                </Typography>
                                            </Section>
                                        )}

                                    {/* {policyDetailDto?.topInfo?.isFloater !==
                                    null && (
                                    <Section>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Floater
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {policyDetailDto?.topInfo?.isFloater
                                                ? "Yes"
                                                : "No"}
                                        </Typography>
                                    </Section>
                                )} */}
                                    {policyDetailDto?.topInfo?.tpa && (
                                        <Section>
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                TPA
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color={
                                                    theme.palette.text.primary
                                                }
                                            >
                                                {policyDetailDto?.topInfo?.tpa}
                                            </Typography>
                                        </Section>
                                    )}
                                    {policyDetailDto?.detailedInfo
                                        ?.vehicleType && (
                                        <Section>
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                Type
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color={
                                                    theme.palette.text.primary
                                                }
                                            >
                                                {
                                                    policyDetailDto
                                                        ?.detailedInfo
                                                        ?.vehicleType
                                                }
                                            </Typography>
                                        </Section>
                                    )}
                                </>
                            )}
                            {isMobile && (
                                <IconButton
                                    sx={{
                                        padding: "6px",
                                        width: "28px",
                                        height: "28px",
                                        border: "1px solid  #ECEFFF",
                                        position: "absolute",
                                        right: "0px",
                                    }}
                                    onClick={() =>
                                        setShowMoreInfo(!showMoreInfo)
                                    }
                                >
                                    <Iconify
                                        icon={
                                            showMoreInfo
                                                ? "material-symbols:keyboard-arrow-up"
                                                : "material-symbols:keyboard-arrow-down"
                                        }
                                        color={"#0C1230"}
                                    />
                                </IconButton>
                            )}
                        </Stack>
                        {(showMoreInfo || !isMobile) &&
                            policyDetailDto?.topInfo?.isPolicyDownload && (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "#fff",
                                        color: "#0C1230",
                                    }}
                                    onClick={() => {
                                        service.getFile(
                                            `${BASE_URL}v1/policy/employee/download/${uuid}/${pid}`,
                                            null,
                                            null,
                                            handleResponseAlert
                                        );
                                    }}
                                    endIcon={
                                        <Iconify
                                            icon="material-symbols:download"
                                            width={"16px"}
                                            height={"16px"}
                                        />
                                    }
                                >
                                    <Typography
                                        variant="overline"
                                        sx={{
                                            textTransform: "none",
                                        }}
                                    >
                                        Download Policy
                                    </Typography>
                                </Button>
                            )}
                    </Stack>
                </Stack>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "stretch",
                    alignItems: "flex-start",
                    gap: "40px",
                    paddingTop: { xs: "0", sm: "24px" },
                    width: { xs: "100%", sm: "337px" },
                }}
            >
                <NewMembers />
            </Box>
        </>
    );
};

export default EachPolicyDetails;
