import { Member } from "../../../types";
import advService from "../../../util/advService";
import { JavaResponse } from "../../../types";
import { AxiosResponse } from "axios";
import service from "../../../util/axiosConfig";
import { GET_ALL_HEALTH_PACKAGES, GET_TESTS } from "../../../util/config";
import {
    FREEZE_HEALTHIANS_TIME_SLOT_POST,
    GET_BOOKING_DETAILS,
    GET_BOOKING_LIST,
    GET_HEALTHIANS_TIME_SLOT_POST,
    HEALTHIANS_CONFIRM_BOOKING_POST,
    HEALTHIANS_SERVICE_AVAILABILITY_POST,
    SAVE_BOOKING_ADDRESS_POST,
    SAVE_HEALTH_CHECKUP_MEMBER_GET,
    SAVE_HEALTH_CHECKUP_MEMBER_POST,
} from "../../../util/newconfig";
import { HomeDtoV2 } from "../../../types/DTO/homeDTO";

export const HealthCheckupCompanyLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Healthians.png";

export type GoolgeAddress = {
    name: string;
    city: string;
    state: string;
    pincode: number;
    lat: string;
    long: string;
    zoneId?: number;
};

export type TimeSlot = {
    slot_id: string;
    slot_time: string; // e.g., "06:00:00"
    end_time: string; // e.g., "07:00:00"
    slot_date: string; // e.g., "2024-11-05"
    state_id: string;
    city_id: string;
    city_name: string;
};

export type BookingAddress = {
    uuid: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    pincode: number;
    houseNumber: string;
    appartmentName: string;
    landmark: string;
    type: "Home" | "Office" | "Other";
    zoneId?: number;
    lat?: string;
    long?: string;
};

export type HealthCheckup = {
    packages: HealthCheckupPackage[];
    booking: booking;
    members: Member[];
    savedAddressList: BookingAddress[];
    bookingList: HomeDtoV2["bookingList"];
    OrderDetails: OrderDetails | null;
};

export type HealthCheckupPackage = {
    uuid: string;
    package_name: string;
    subHeading: number;
    manufacturedPrice: number;
    sellingPrice: number;
    discount: number;
    provider: string;
};

export type Test = {
    heading: string;
    subTest: string[];
    total: number;
};

export type TestGroup = {
    total: number;
    tests: Test[];
} | null;

export type booking = {
    packages: {
        [packageId: string]: {
            package: HealthCheckupPackage;
            memberCount: number;
            member: (Member | null)[];
        };
    };
    address: BookingAddress;
    schedule: {
        date: string;
        time: TimeSlot;
    };
    contact: {
        name: string;
        email: string;
        phone: string;
    };
};

type NewMemberAddType = {
    name: string;
    dob: string;
    relationShip: string;
};

// {
//         "members": [
//             {
//                 "relationShip": null,
//                 "displayRelationship": "Wife",
//                 "name": "Latha Ramakrishna",
//                 "gender": "Female",
//                 "dob": "1980-03-20",
//                 "id": "fsodnvodnfbifniwpq45",
//                 "partnerPackageId": null,
//                 "packageId": "FVgjhrfjsdfk",
//                 "amount": 2499,
//                 "reportUrl": null,
//                 "status": null
//             },
//             {
//                 "relationShip": null,
//                 "displayRelationship": "Other",
//                 "name": "value",
//                 "gender": "Female",
//                 "dob": "2011-10-05",
//                 "id": "MTEyODYxNzMwMjg2ODkyNjk1",
//                 "partnerPackageId": null,
//                 "packageId": "Fhrufjo14uf",
//                 "amount": 699,
//                 "reportUrl": null,
//                 "status": null
//             }
//         ],
//         "billingMobile": "1231231234",
//         "billingName": "RV",
//         "billingEmail": "rv@gmail.com",
//         "billingGender": "Male",
//         "address": {
//             "uuid": "NTU3MjYxNzMwNzk4MTU2Mzg5",
//             "addressLine1": "123 Main St",
//             "addressLine2": "Apt 101",
//             "city": "Metropolis",
//             "state": "NY",
//             "pincode": "12345",
//             "lat": "40.7128",
//             "lang": "-74.0060",
//             "label": "Home",
//             "stateId": null,
//             "cityId": null,
//             "active": true
//         },
//         "slotId": "slot-123",
//         "flow": "Client",
//         "uuid": "OTA1NjUxNzMwNzk5MzEzMjEz",
//         "slotDate": "2024-11-05",
//         "slotStartTime": 900,
//         "slotEndTime": 1700
//     }

export interface OrderDetails {
    members: {
        relationShip: string;
        displayRelationship: string;
        name: string;
        gender: string;
        dob: string;
        id?: string;
        partnerPackageId?: string;
        packageId: string;
        amount: number;
        reportUrl?: string;
        status?: string;
    }[];
    billingMobile: string;
    billingName: string;
    billingEmail: string;
    billingGender: string;
    address: {
        uuid: string;
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        pincode: string;
        lat: string;
        lang: string;
        label: string;
        stateId: string;
        cityId: string;
        active: boolean;
    };
    slotId: string;

    packageDetails: {
        value: number;
        label: string;
        tag: string;
    }[];
    flow: string;
    uuid: string;
    status: string;
    bookingId: string;
    totalAmount: number;
    slotDate: string;
    slotStartTime: number;
    slotEndTime: number;
}

export const formatTimeSlot = (slot: TimeSlot): string => {
    return `${formatTime(slot.slot_time)} - ${formatTime(slot.end_time)}`;
};

const formatTime = (time: string): string => {
    const [hour, minute] = time.substring(0, 5).split(":");
    const date = new Date();
    date.setHours(parseInt(hour, 10), parseInt(minute, 10));
    return date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });
};

function timeStringToMinutes(timeStr: string): number {
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes;
}

export const healthCheckupService = {
    getAllPackages: async (): Promise<HealthCheckupPackage[]> => {
        return new Promise((resolve, reject) => {
            service.get(GET_ALL_HEALTH_PACKAGES).then((res) => {
                if (res?.data.success) {
                    resolve(res.data.response);
                    return;
                }
                reject(res.data.message);
            });
        });
    },
    getTests: async (packageId: string): Promise<TestGroup> => {
        return new Promise((resolve, reject) => {
            service
                .post(GET_TESTS, {
                    uuid: packageId,
                })
                .then((res) => {
                    if (res?.data.success) {
                        resolve(res.data.response);
                        return;
                    }
                    reject(res.data.message);
                });
        });
    },
    booking: {
        list: async (): Promise<HomeDtoV2["bookingList"]> => {
            return new Promise((resolve, reject) => {
                let config = {
                    ...GET_BOOKING_LIST,
                };

                advService(
                    config,
                    {},
                    (
                        res: AxiosResponse<
                            JavaResponse<HomeDtoV2["bookingList"]>
                        >
                    ) => {
                        if (res?.data.success) {
                            resolve(res.data.response);
                        } else {
                            reject(new Error("Failed to get bookings"));
                        }
                    }
                );
            });
        },
        details: async (bookingId: string): Promise<OrderDetails> => {
            return new Promise((resolve, reject) => {
                let config = {
                    ...GET_BOOKING_DETAILS,
                    url: GET_BOOKING_DETAILS.url.replace("{uuid}", bookingId),
                };

                advService(
                    config,
                    {},
                    (res: AxiosResponse<JavaResponse<OrderDetails>>) => {
                        if (res?.data.success) {
                            resolve(res.data.response);
                        } else {
                            reject(new Error("Failed to get booking details"));
                        }
                    }
                );
            });
        },
        // @ts-ignore
        buy: async (booking: booking): void => {},

        confirm: async (
            booking: booking
        ): Promise<AxiosResponse<JavaResponse<any>>> => {
            return new Promise((resolve, reject) => {
                // resolve(booking);
                const members = booking.packages[
                    Object.keys(booking.packages)[0]
                ].member.map((m) => ({
                    relationShip: m?.relationShip,
                    displayRelationship: m?.displayRelationship,
                    name: m?.name,
                    gender: m?.gender,
                    dob: m?.dob,
                    id: m?.id,
                    partnerPackageId: Object.keys(booking.packages)[0],
                }));

                const uuid = sessionStorage.getItem("uuid");
                const data = {
                    members: members,
                    billingMobile: booking.contact.phone,
                    billingName: booking.contact.name,
                    billingEmail: booking.contact.email,
                    billingGender: "Male",
                    address: booking.address,
                    slotId: booking.schedule.time.slot_id,
                    zoneId: booking.address.zoneId,
                    flow: "User",
                    slotDate: booking.schedule.date,
                    slotStartTime: timeStringToMinutes(
                        booking.schedule.time.slot_time
                    ),
                    slotEndTime: timeStringToMinutes(
                        booking.schedule.time.end_time
                    ),
                    uuid: uuid,
                    agentUuid: "NjE2MzkxNzI5ODUwMTgyODAw", // for test only rohan will remove that later
                };
                console.log("---------------------------->", data);
                debugger;
                advService(
                    HEALTHIANS_CONFIRM_BOOKING_POST,
                    data,
                    (res: AxiosResponse<JavaResponse<booking>>) => {
                        if (res?.data.success) {
                            resolve(res);
                        } else {
                            reject(
                                new Error("Health checkup confirmation failed")
                            );
                        }
                    }
                );
            });
        },

        checkServiceAvailability: async (
            lat: string,
            long: string
        ): Promise<AxiosResponse<JavaResponse<any>>> => {
            return new Promise(async (resolve, reject) => {
                const payload = {
                    lat: lat,
                    long: long,
                };

                let res = await service.post(
                    HEALTHIANS_SERVICE_AVAILABILITY_POST.url,
                    payload
                );

                resolve(res);
            });
        },

        saveMember: async (
            name: string,
            dob: string,
            relationShip: string,
            displayRelationShip: string,
            gender: string
        ): Promise<AxiosResponse<JavaResponse<any>>> => {
            return new Promise((resolve, reject) => {
                const data = {
                    name: name,
                    dob: dob,
                    relationShip: relationShip,
                    displayRelationship: displayRelationShip,
                    gender: gender,
                };

                const user = JSON.parse(sessionStorage.getItem("user") || "{}");

                let config = {
                    ...SAVE_HEALTH_CHECKUP_MEMBER_POST,
                    url: SAVE_HEALTH_CHECKUP_MEMBER_POST.url.replace(
                        "{flow}",
                        "User"
                    ),
                };

                advService(
                    config,
                    data,
                    (res: AxiosResponse<JavaResponse<booking>>) => {
                        if (res?.data.success) {
                            resolve(res);
                        } else {
                            reject(new Error("Failed to freeze slot"));
                        }
                    }
                );
            });
        },

        saveAddress: async (
            uuid: string,
            addressLine1: string,
            addressLine2: string,
            city: string,
            state: string,
            pincode: number,
            lat: string,
            long: string,
            label: string
        ): Promise<any> => {
            return new Promise((resolve, reject) => {
                const data = {
                    uuid: uuid,
                    addressLine1: addressLine1,
                    addressLine2: addressLine2,
                    city: city,
                    state: state,
                    pincode: pincode,
                    lat: lat,
                    lang: long,
                    label: label,
                };

                const user = JSON.parse(sessionStorage.getItem("user") || "{}");

                let config = {
                    ...SAVE_BOOKING_ADDRESS_POST,
                    url: SAVE_BOOKING_ADDRESS_POST.url.replace(
                        "{flow}",
                        "User"
                    ),
                };

                advService(
                    config,
                    data,
                    (res: AxiosResponse<JavaResponse<booking>>) => {
                        if (res?.data.success) {
                            resolve(res.data.response);
                        } else {
                            reject(new Error("Failed to freeze slot"));
                        }
                    }
                );
            });
        },

        getScheduleSlots: async (
            zoneId: number,
            scheduleDate: string,
            lat: string,
            long: string
        ): Promise<any> => {
            return new Promise((resolve, reject) => {
                const config = GET_HEALTHIANS_TIME_SLOT_POST;

                const data = {
                    date: scheduleDate,
                    zoneId: zoneId,
                    lat: lat,
                    long: long,
                };

                advService(
                    config,
                    data,
                    (res: AxiosResponse<JavaResponse<booking>>) => {
                        if (res?.data.success) {
                            resolve(res.data.response);
                        } else {
                            reject(new Error("Failed to get schedule slots"));
                        }
                    }
                );
            });
        },

        freezeSlot: async (
            slotId: string,
            billingUserId: string,
            members: string[]
        ): Promise<any> => {
            return new Promise((resolve, reject) => {
                const data = {
                    slotId: slotId,
                    billingUserId: billingUserId.toString(),
                    members: members,
                };

                advService(
                    FREEZE_HEALTHIANS_TIME_SLOT_POST,
                    data,
                    (res: AxiosResponse<JavaResponse<booking>>) => {
                        if (res?.data.success) {
                            resolve(res.data.response);
                        } else {
                            reject(new Error("Failed to freeze slot"));
                        }
                    }
                );
            });
        },
    },
    members: {
        // @ts-ignore
        list: async (): Promise<AxiosResponse<JavaResponse<any>>> => {
            return new Promise((resolve, reject) => {
                let config = {
                    ...SAVE_HEALTH_CHECKUP_MEMBER_GET,
                    url: SAVE_HEALTH_CHECKUP_MEMBER_GET.url.replace(
                        "{flow}",
                        "User"
                    ),
                };

                advService(
                    config,
                    {},
                    (res: AxiosResponse<JavaResponse<booking>>) => {
                        if (res?.data.success) {
                            resolve(res);
                        } else {
                            reject(new Error("Failed to get MEMBERS"));
                        }
                    }
                );
            });
        },
        // @ts-ignore
        add: async (member: NewMemberAddType): Member => {
            let createres = {
                active: true,
                displayRelationship: "Self",
                dobText: "03-Oct-1985",
                employeeId: "CI-01-584",
                gender: "Male",
                healthId: "IN596306000",
                policyType: "GMC",
                premium: 0,
                sumInsured: 400000,
                uid: "p9881_mci-01-58",
                ...member,
            };
            return createres;
        },
    },
    address: {
        // @ts-ignore
        getAll: async (): Promise<any> => {
            return new Promise((resolve, reject) => {
                resolve({
                    addressLine1: "Address Line 1",
                    addressLine2: "Address Line 2",
                    city: "City",
                    state: "State",
                    pincode: 123456,
                    houseNumber: "House Number",
                    appartmentName: "Appartment Name",
                    landmark: "Landmark",
                    type: "Home",
                });
            });
        },
    },
};
