import { HealthCheckup } from "../../services/api/healthCheckup/healthCheckup";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: HealthCheckup = {
    packages: [],
    booking: {
        packages: {},
        address: {
            uuid: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            pincode: 0,
            houseNumber: "",
            appartmentName: "",
            landmark: "",
            type: "Home",
            zoneId: 0,
        },
        schedule: {
            date: "",
            time: {
                slot_id: "",
                slot_time: "",
                end_time: "",
                slot_date: "",
                state_id: "",
                city_id: "",
                city_name: "",
            },
        },
        contact: {
            name: "",
            email: "",
            phone: "",
        },
    },
    members: [],
    savedAddressList: [],
    bookingList: [],
    OrderDetails: null,
};

const healthCheckupSlice = createSlice({
    name: "healthCheckup",
    initialState,
    reducers: {
        setHealthCheckupPackages: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["packages"]>
        ) => {
            state.packages = action.payload;
        },
        setHealthCheckupBooking: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["booking"]>
        ) => {
            state.booking = action.payload;
        },
        setHealthCheckupMembers: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["members"]>
        ) => {
            state.members = action.payload;
        },
        setHealthCheckupBookingContact: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["booking"]["contact"]>
        ) => {
            state.booking.contact = action.payload;
        },
        setHealthCheckupBookingSchedule: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["booking"]["schedule"]>
        ) => {
            state.booking.schedule = action.payload;
        },
        setHealthCheckupSavedAddressList: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["savedAddressList"]>
        ) => {
            state.savedAddressList = action.payload;
        },
        setHealthCheckupBookingList: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["bookingList"]>
        ) => {
            state.bookingList = action.payload;
        },
        setHealthCheckupOrderDetails: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["OrderDetails"]>
        ) => {
            state.OrderDetails = action.payload;
        },
    },
});

// export const { setCustomerCareData } = customerCareSlice.actions;
export const {
    setHealthCheckupPackages,
    setHealthCheckupBooking,
    setHealthCheckupMembers,
    setHealthCheckupBookingContact,
    setHealthCheckupBookingSchedule,
    setHealthCheckupSavedAddressList,
    setHealthCheckupBookingList,
    setHealthCheckupOrderDetails,
} = healthCheckupSlice.actions;

export default healthCheckupSlice.reducer;
